import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ApCommonModule } from "../ap-common/ap-common.module";

import { MaterialComponentsModule } from "../material-components.module";

import { FormsModule } from "@angular/forms";

import { SharedModule } from "../shared/shared.module";

import { RvCalculatorComponent } from "../shared/calculator/rv-calculator/rv-calculator.component";
import { RvValuesComponent } from "../shared/calculator/rv-values/rv-values.component";
import { LinkVehicleComponent } from "../shared/link-vehicle/link-vehicle.component";
import { AddRvValuesComponent } from "../shared/add-rv-values/add-rv-values.component";
import { ManageRecommendationsComponent } from "../shared/manage-recommendations/manage-recommendations.component";
import { VehicleLinkageComponent } from "../shared/vehicle-linkage/vehicle-linkage.component";
import { ChangeLinkingComponent } from "../shared/change-linking/change-linking.component";
import { ConfirmationPopupComponent } from "../shared/confirmation-popup/confirmation-popup.component";

import { CompaniesListComponent } from "./companies-list/companies-list.component";
import { CompanyRowDetailsComponent } from "./company-row-details/company-row-details.component";
import { AddCompanyComponent } from "./add-company/add-company.component";
import { TextMaskModule } from "angular2-text-mask";
import { UpdateCompanyComponent } from "./update-company/update-company.component";
import { AddProductComponent } from "./portal/add-product/add-product.component";
import { AddCompanyV2Component } from "./portal/add-company-v2/add-company-v2.component";
import { UpdateCompanyV2Component } from "./portal/update-company-v2/update-company-v2.component";
import { DynamicFormComponent } from "./portal/dynamic-form/dynamic-form.component";
import { ProductComponent } from "./product/product.component";
import { EditProductComponent } from "./edit-product/edit-product.component";
import { UsageComponent } from "./usage/usage.component";
import { InstaApiKeyComponent } from "./insta-api-key/insta-api-key.component";

import { ClipboardModule } from "ngx-clipboard";
import { ProductOfferComponent } from "./product-offer/product-offer.component";
import { OfferListingComponent } from "./portal/offer-listing/offer-listing.component";
import { AddProductV2Component } from "./portal/add-product-v2/add-product-v2.component";
import { SubscriptionListComponent } from "./subscription/subscription-list/subscription-list.component";
import { RenewSubscriptionComponent } from "./subscription/renew-subscription/renew-subscription.component";
import { SubscriptionService } from "../services/subscription.service";
import { AddOfferComponent } from "./subscription/add-offer/add-offer.component";
@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    TextMaskModule,
    SharedModule,
    ApCommonModule,
    ClipboardModule,
    RouterModule
  ],
  declarations: [
    CompaniesListComponent,
    CompanyRowDetailsComponent,
    AddCompanyComponent,
    UpdateCompanyComponent,
    AddProductComponent,
    AddCompanyV2Component,
    UpdateCompanyV2Component,
    DynamicFormComponent,
    ProductComponent,
    EditProductComponent,
    UsageComponent,
    InstaApiKeyComponent,
    ProductOfferComponent,
    OfferListingComponent,
    AddProductV2Component,
    SubscriptionListComponent,
    RenewSubscriptionComponent,
    AddOfferComponent
  ],
  exports: [],
  entryComponents: [
    RvCalculatorComponent,
    RvValuesComponent,
    LinkVehicleComponent,
    AddRvValuesComponent,
    ManageRecommendationsComponent,
    VehicleLinkageComponent,
    ChangeLinkingComponent,
    ConfirmationPopupComponent,
    AddCompanyComponent,
    UpdateCompanyComponent,
    AddCompanyV2Component,
    UpdateCompanyV2Component,
    EditProductComponent,
    InstaApiKeyComponent,
    AddOfferComponent,
    RenewSubscriptionComponent
  ],
  providers: [DatePipe, SubscriptionService]
})
export class CompaniesModule { }
