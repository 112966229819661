/*
* @ngdoc Services
* @name app.services.RvCalculatorService
* @description
* The service is used for handling the RV calculator calculations
* */

import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { CommonHttpService } from './common-http.service';
import { ApiUrls } from "../configs/api-urls";

@Injectable({
  providedIn: 'root'
})
export class RvCalculatorService {

  constructor(private commonService: CommonService, private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  getRvCalBaseData(datiumAssetKey, age = null, km = null) {
    let query = { datiumAssetKey: datiumAssetKey };
    if (age && km) {
      query['age'] = age;
      query['km'] = km;
    }

    return this.commonHttpService.get(this.apiUrls.vehicle.rv_calculator, query)
  }

  /*
  * @name calRVAdjRRP
  * @param term,rv,datiumModelValue,ageCoefficient
  * @description calculate adjustments RRP
  * @return number 
  */
  calRVAdjRRP(term: number, rv: number, datiumModelValue: number, ageCoefficient: number) {
    //rv / (datiumModelValue * Math.exp(ageCoefficient * term))
    let formulaOut = rv * (datiumModelValue * Math.exp(ageCoefficient * term))
    return this.commonService.roundToXDigits(formulaOut, 10)
  }

  /*
  * @name onScroll
  * @param adjRRP1,adjRRP2
  * @description calculate slope
  * @return number
  */
  calRVSlope(adjRRP1: number, adjRRP2: number) {
    //rv / (datiumModelValue * Math.exp(ageCoefficient * term))
    let formulaOut = (adjRRP2 - adjRRP1) / 12
    return this.commonService.roundToXDigits(formulaOut, 10)
  }

  /*
  * @name calRVIntercept
  * @param term,adjRRP1,adjRRP2
  * @description calculate Intercept
  * @return number
  */
  calRVIntercept(term: number, adjRRP1: number, adjRRP2: number) {
    //rv / (datiumModelValue * Math.exp(ageCoefficient * term))
    let formulaOut = adjRRP2 - ((adjRRP2 - adjRRP1) / 12) * term
    return this.commonService.roundToXDigits(formulaOut, 10)
  }
}
