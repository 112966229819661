/*
* @ngdoc Config
* @name app.configs.FilterName
* @description
* The service is used to define all the filter names
* */

export class FilterName {
    filter = {
        VFactsGroups: '',
        Make: '',
        Family: '',
        Year_Group: '',
        Body_Type: '',
        Fuel_Type: '',
        Transmission: '',
        Cylinders: '',
        Vehicle_Level: ''
    };
    filterList = [
        { 'title': 'VFactsGroups', 'name': 'VFactsGroups' },
        { 'title': 'Make', 'name': 'Make' },
        { 'title': 'Family', 'name': 'Family' },
        { 'title': 'Year Group', 'name': 'Year_Group' },
        { 'title': 'Body Type', 'name': 'Body_Type' },
        { 'title': 'Fuel Type', 'name': 'Fuel_Type' },
        { 'title': 'Transmission', 'name': 'Transmission' },
        { 'title': 'Cylinders', 'name': 'Cylinders' },
        { 'title': 'Vehicle Level', 'name': 'Vehicle_Level' },
        { 'title': 'AP Model Version', 'name': 'Ind_NewVehicle' }
    ];
    filterNewList = [
        { 'title': 'VFactsGroups', 'name': 'VFactsGroups' },
        { 'title': 'Make', 'name': 'Make' },
        { 'title': 'Family', 'name': 'Family' },
        { 'title': 'Year Group', 'name': 'Year_Group' },
        { 'title': 'Body Type', 'name': 'Body_Type' },
        { 'title': 'Fuel Type', 'name': 'Fuel_Type' },
        { 'title': 'Transmission', 'name': 'Transmission' },
        { 'title': 'Cylinders', 'name': 'Cylinders' },
        { 'title': 'Vehicle Level', 'name': 'Vehicle_Level' },
        { 'title': 'Recently Added', 'name': 'recentlyAdded' },
        { 'title': 'AP Model Version', 'name': 'Ind_NewVehicle' }
    ];
    filterNewVehicleList = [
        { 'title': 'VFactsGroups', 'name': 'VFactsGroups' },
        { 'title': 'Make', 'name': 'Make' },
        { 'title': 'Family', 'name': 'Family' },
        { 'title': 'Year Group', 'name': 'Year_Group' },
        { 'title': 'Body Type', 'name': 'Body_Type' },
        { 'title': 'Fuel Type', 'name': 'Fuel_Type' },
        { 'title': 'Transmission', 'name': 'Transmission' },
        { 'title': 'Cylinders', 'name': 'Cylinders' },
        { 'title': 'Vehicle Level', 'name': 'Vehicle_Level' },
        { 'title': 'Datium Values', 'name': 'Datium_Values' }
    ];

}
