/*
* @ngdoc Component
* @name app.vehicle.SearchVehicleComponent
* @description
* This component is used to handle the vehicle search screen
* */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { CommonService } from 'src/app/services/common.service';
import { AppHeadings } from "../../configs/app-headings";
import { DataStoreService } from 'src/app/services/data-store.service';
import { FilterName } from "../../configs/filter-names";
import { Subscription } from 'rxjs';
@Component({
  selector: 'search-vehicle',
  templateUrl: './search-vehicle.component.html',
  styleUrls: ['./search-vehicle.component.scss']
})
export class SearchVehicleComponent implements OnInit, OnDestroy {

  @Input() routeName: string
  searchForm: FormGroup;
  getFilterSelectSignal: Subscription
  searchFormValueChanges: Subscription
  pageHeading = '';
  formError = {};
  filterOrder = ['VFactsGroups', 'Make', 'Family', 'Body_Type', 'Fuel_Type', 'Transmission', 'Cylinders', 'Vehicle_Level']
  constructor(private fb: FormBuilder, private commonService: CommonService, private appHeading: AppHeadings, private dataStore: DataStoreService, private filterName: FilterName) {

  }

  ngOnInit() {
    this.searchForm = this.fb.group(
      {
        VFactsGroups: new FormControl(''),
        Make: new FormControl(''),
        Family: new FormControl(''),
        Body_Type: new FormControl(''),
        Fuel_Type: new FormControl(''),
        Transmission: new FormControl(''),
        Cylinders: new FormControl(''),
        Vehicle_Level: new FormControl('')
      }
    )
    this.searchFormValueChanges = this.searchForm.valueChanges.subscribe(newVal => {
      this.dataStore.setSelectedFilters(newVal)
    })

    let heading: string = ''
    if (this.routeName == '/vehicle-list-used') {
      heading = this.appHeading.vehicle.heading.used_vehicle
    } else if (this.routeName == '/vehicle-list-new') {
      heading = this.appHeading.vehicle.heading.new_vehicle
    }
    this.pageHeading = heading
    this.commonService.setTitle(this.appHeading.vehicle.p_title.search)

    this.getFilterSelectSignal = this.dataStore.getFilterSelectSignal().subscribe(data => {
      if (data.filter != null) {
        this.clearSelectedDependentFilters(data.filter)
      }
    })
  }

  /*
  * @name clearSelectedDependentFilters
  * @param filter_key:String
  * @description Reset the dependent filter that was already selected
  * @return none
  */
  clearSelectedDependentFilters(filter_key) {
    let index = this.filterOrder.indexOf(filter_key)
    // index return -1 for index that does not exists
    if (index != -1) {
      let newArry = this.filterOrder;
      newArry = newArry.slice(index + 1)
      if (newArry.length > 0) {
        console.log(newArry)
        let resetInput = {}
        newArry.forEach(element => {
          resetInput[element] = "";
        });
        this.searchForm.patchValue(resetInput)
        console.log(this.searchForm.value)
      }
    }
  }

  /*
  * @name resetFilters
  * @param none
  * @description reset the vehicle filter form
  * @return none
  */
  resetFilters() {
    this.searchForm.setValue(this.filterName.filter)
    this.formError = {}
    this.dataStore.setSelectedFilters(this.filterName.filter)
  }

  /*
  * @name handleSearchForm
  * @param searchFormObj:FormGroup
  * @description handle the search form
  * @return none
  */
  handleSearchForm(searchFormObj: FormGroup) {
    if (searchFormObj.valid) {
      let VFactsGroups = searchFormObj.value.VFactsGroups
      let Make = searchFormObj.value.Make
      if (!VFactsGroups && !Make) {
        if (!VFactsGroups) {
          return this.handleServerFormError({
            'VFactsGroups': 'Please choose VFacts Groups.'
          })
        }
        if (!Make) {
          return this.handleServerFormError({
            'Make': 'Please choose Make.'
          })
        }
      }
      this.commonService.sendUserTo(this.routeName, searchFormObj.value)
    } else {
      this.formValidate(searchFormObj);
    }
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        console.log(key, this.searchForm.contains(key))
        if (this.searchForm.contains(key)) {
          let control = this.searchForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
          console.log(this.formError, control)
        }
      });
    }
  }


  ngOnDestroy() {
    if (this.searchFormValueChanges) {
      this.searchFormValueChanges.unsubscribe()
    }
    if (this.getFilterSelectSignal) {
      this.getFilterSelectSignal.unsubscribe()
    }
  }

}
