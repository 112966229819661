import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-vehicle-filter',
  templateUrl: './vehicle-filter.component.html',
  styleUrls: ['./vehicle-filter.component.scss']
})
export class VehicleFilterComponent implements OnInit, OnChanges {
  @Input() field_type: string;
  @Input() field_class: string;
  @Input() controlPlaceholder: string;
  @Input() formControl: any;
  @Input() controlName: string;
  @Input() frmControl: FormControl;

  @Input() isMultiple: boolean = false;
  @Input() toggleLoader: boolean = false;
  @Input('form') parentForm: FormGroup;
  @Input() formError: any = {}
  @Input() filterList: Array<any> = []
  @Input() allFilterList: Array<any> = [];
  @Output() sendFilterSelect = new EventEmitter();
  recentAddedPlace = 'Recent added on'
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    //console.log(this.frmControl)
  }

  ngOnChanges(changes) {
    //console.log(this.controlName, '--------------filter list-----------')
    //console.log(this.parentForm)
    if (changes['filterList'] && this.isMultiple == true) {
      this.filterList = changes.filterList.currentValue
    }
    if (changes['formError']) {
      this.formError = changes.formError.currentValue
    }
    //console.log(this.frmControl)
    try {
      if (changes.filterList && changes.allFilterList) {
        if (changes.filterList.firstChange == true && changes.allFilterList.firstChange == true) {
          return false;
        }
        let patchValue = false;
        let allListCurValue = changes.allFilterList.currentValue
        let filterCurVal = changes.filterList.currentValue

        let diff = this.commonService.diffCheck(this.parentForm.value[this.controlName], allListCurValue[this.controlName])
        patchValue = (filterCurVal && diff.length == filterCurVal.length)
        ///patchValue = (filterCurVal && allListCurValue[this.controlName].length && filterCurVal.length === allListCurValue[this.controlName].length);
        //patchValue = (filterCurVal.length == allListCurValue[this.controlName].length);
        this.parentForm.controls[this.controlName + '_check'].patchValue(patchValue)
        //console.log('patching value', this.controlName + '_check', patchValue, diff, filterCurVal)
      }
    } catch (e) {
      //console.log(e)
    }
  }


  /*
  * @name selectionChange
  * @param  Array(Selected values)
  * @description Get selected values                    
  * @return none
  */
  selectionChange(value) {
    //console.log(this.controlName, ' change', value)
    let patchValue = false;
    let currentFilter = this.allFilterList[this.controlName]
    if (Array.isArray(value)) {
      patchValue = (currentFilter && value.length && currentFilter.length === value.length);
    } else {
      patchValue = value
    }
    //console.log(patchValue, '-----print the patch value here')
    if (Array.isArray(value)) {
      this.parentForm.controls[this.controlName + '_check'].patchValue(patchValue)
    } else {
      patchValue = value
    }
    //this.parentForm.controls[this.controlName + '_check'].patchValue(patchValue)
    this.sendFilterSelect.emit({ filter: this.controlName, filterVal: value, 'all': false, toggle: false });
  }

  /*
  * @name checkAll
  * @param  Array(response)
  * @description Handle when user select All options in a filter
  * @return none
  */
  checkAll(response) {
    //console.log('------------check all', response)
    //this.parentForm.controls[this.controlName + '_check'].patchValue(response.toggle)
    this.sendFilterSelect.emit({ filter: this.controlName, filterVal: response.data, 'all': true, toggle: response.toggle });
    //  console.log('here in the check all function')
  }

  isChecked(): boolean {
    try {
      return this.parentForm.value && this.filterList.length
        && this.parentForm.value.length === this.filterList.length;
    } catch (e) {

    }
  }

  isIndeterminate(): boolean {
    try {
      return this.parentForm.value && this.filterList.length && this.parentForm.value.length
        && this.parentForm.value.length < this.filterList.length;
    } catch (e) {

    }
  }

  toggleSelection(change: MatCheckboxChange): void {
    try {
      if (change.checked) {
        let filter = []
        this.filterList.forEach(element => {
          filter.push(element.filter)
        });
        this.frmControl.patchValue(filter)
        this.checkAll({ toggle: true, data: filter })
      } else {
        this.frmControl.patchValue([])
        this.checkAll({ toggle: false, data: [] })
      }
    } catch (e) {

    }
  }

}
