import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 've-loader',
  templateUrl: './ve-loader.component.html',
  styleUrls: ['./ve-loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VeLoaderComponent implements OnInit {

  @Input() text: string = 'Loading...'
  @Input() ShowLoader: Boolean = false
  constructor() { }

  ngOnInit() {
  }

}
