import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard-wrapper/dashboard.component';
import { AutopredictNewComponent } from './vehicle/autopredict-new/autopredict-new.component';
import { AutopredictUsedComponent } from './vehicle/autopredict-used/autopredict-used.component';
import { RiskAdjustmentsComponent } from './vehicle/risk-adjustments/risk-adjustments.component';
import { LinkingTreeComponent } from './vehicle/linking-tree/linking-tree/linking-tree.component';
import { UikitComponent } from './uikit/uikit/uikit.component';
import { NewVehicleListComponent } from './vehicle/new-vehicles/new-vehicle-list/new-vehicle-list.component';
import { SystemConfigurationsComponent } from './my-account/system-configurations/system-configurations.component';
import { HelpComponent } from './my-account/help/help.component';
import { MasterComponent } from './core/master/master.component';
import { HasTokenResolver, AppRoutingAccess, AppCheckUserAlreadyLogin } from './services/resolver.service';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { BiFilterSearchComponent } from './vehicle/bi-filter-search/bi-filter-search.component';
import { BiFilterWrapperComponent } from './vehicle/bi-filter-wrapper/bi-filter-wrapper.component';
import { PermissionsGuard } from './guards/permissions.guard';
import { VehicleReportsComponent } from './my-account/vehicle-reports/vehicle-reports.component';
import { SingleSignInComponent } from './dashboard/single-sign-in/single-sign-in.component';
import { HelpSupportComponent } from './help/help-support/help-support.component';
import { PriceTrackerWrapperComponent } from './vehicle/price-tracker-wrapper/price-tracker-wrapper.component';
import { BasketWrapperComponent } from './vehicle/basket-wrapper/basket-wrapper.component';
import { QuickSearchComponent } from './vehicle/quick-search/quick-search.component';

const routes: Routes = [
  { path: 'single-sign', component: SingleSignInComponent },
  {
    path: '', component: MasterComponent, children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'vehicle-search-new', component: AutopredictNewComponent, canActivate: [PermissionsGuard], data: { permissions: 'new' } },
      { path: 'vehicle-search-bi', component: BiFilterSearchComponent },
      { path: 'vehicle-list-bi', component: BiFilterWrapperComponent },
      { path: 'vehicle-search-used', component: AutopredictUsedComponent, canActivate: [PermissionsGuard], data: { permissions: 'used' } },
      { path: 'risk-adjustments', component: RiskAdjustmentsComponent, canActivate: [PermissionsGuard], data: { risk: true } },
      { path: 'linking-tree', component: LinkingTreeComponent, canActivate: [PermissionsGuard], data: { permissions: ['new', 'used'] } },
      //{ path: 'vehicle-list', component: VehicleListWrapperComponent },
      //{ path: 'vehicle-list-new', component: VehicleListWrapperComponent },
      //{ path: 'vehicle-list-used', component: VehicleListWrapperComponent },
      { path: 'vehicle-list-new', component: BiFilterWrapperComponent, canActivate: [PermissionsGuard], data: { permissions: 'new' } },
      { path: 'vehicle-list-used', component: BiFilterWrapperComponent, canActivate: [PermissionsGuard], data: { permissions: 'used' } },
      { path: 'uikit', component: UikitComponent },
      { path: 'new-vehicles', component: NewVehicleListComponent, canActivate: [PermissionsGuard], data: { auto_accept: false } },
      //{ path: 'companies', component: CompaniesListComponent, canActivate: [SuperAdminGuard] },
      //{ path: 'companies/subscriptions', component: SubscriptionListComponent, canActivate: [SuperAdminGuard] },
      //{ path: 'my-account/manage-users', component: ManageUsersComponent, canActivate: [CompanyAdminGuard] },
      { path: 'my-account/system-configurations', component: SystemConfigurationsComponent },
      //{ path: 'my-account/subscriptions', component: SubscriptionsComponent },
      { path: 'my-account/help', component: HelpComponent },
      { path: 'my-account/reports', component: VehicleReportsComponent, canActivate: [PermissionsGuard], data: { permissions: ['new', 'used'] } },
      //{ path: 'products', component: ProductComponent, canActivate: [SuperAdminGuard] },
      //{ path: 'product-offers', component: ProductOfferComponent, canActivate: [SuperAdminGuard] },
      //{ path: 'usage', component: UsageComponent, canActivate: [SuperAdminGuard] },
      //{ path: 'users', component: AdminUsersComponent, canActivate: [SuperAdminGuard] },
      { path: 'price-tracker', component: PriceTrackerWrapperComponent, canActivate: [PermissionsGuard], data: { permissions: 'price_tracker' } },
      { path: 'basket', component: BasketWrapperComponent, data: { permissions: 'basket' } },
      { path: 'basket/:id', component: BasketWrapperComponent, data: { permissions: 'basket' } },
      { path: 'quick-search', component: QuickSearchComponent, canActivate: [PermissionsGuard], data: { permissions: 'quick-search' } },
      { path: 'help-support', component: HelpSupportComponent },
      { path: 'quick-search', component: QuickSearchComponent }

    ], resolve: { access: AppRoutingAccess }
  },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppRoutingAccess, HasTokenResolver, AppCheckUserAlreadyLogin]
})

export class RoutingModule { }
