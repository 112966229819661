import { Injectable } from '@angular/core';

import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  getPermissionsList() {
    return this.commonHttpService.get(this.apiUrls.permissions.list);
  }

  getProductList() {
    return this.commonHttpService.get(this.apiUrls.products.list);
  }
  getProductAttributesList() {
    return this.commonHttpService.get(`${this.apiUrls.products.list}/${this.apiUrls.products.attributes}`);
  }
  updateProduct(params) {
    return this.commonHttpService.post(`${this.apiUrls.products.list}/${this.apiUrls.products.updateProduct}`, params);
  }


  getProductOfferList() {
    return this.commonHttpService.get(this.apiUrls.p_offer.list);
  }

  getCompanyProductOfferList(params) {
    return this.commonHttpService.post(this.apiUrls.p_offer.company, params);
  }

  updateProductOffer(params) {
    return this.commonHttpService.post(`${this.apiUrls.p_offer.list}/${this.apiUrls.p_offer.update}`, params);
  }


  getOfferDetails(Plan_Id) {
    return this.commonHttpService.get(`${this.apiUrls.p_offer.list}/${this.apiUrls.p_offer.details}`, { Plan_Id: Plan_Id });
  }

}
