import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

  title: string;
  nrEmail: any
  loader: boolean = false
  constructor(public dialogRef: MatDialogRef<ConfirmationPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationPopupComponent) {
    this.title = this.data.title ? this.data.title : 'Are you sure you want to perform this action?';
    this.nrEmail = this.data.data ? this.data.data : '';

  }

  ngOnInit() {
  }

  triggerAction(action = 'No') {
    this.dialogRef.close(action);
  }
}
