import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BasketService } from 'src/app/services/basket.service';
import { CommonService } from 'src/app/services/common.service';
import { AddBasketComponent } from 'src/app/shared/add-basket/add-basket.component';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-basket-wrapper',
  templateUrl: './basket-wrapper.component.html',
  styleUrls: ['./basket-wrapper.component.scss']
})
export class BasketWrapperComponent implements OnInit {

  pageTitle: string = 'Manage basket';
  baskets = [];
  types = []
  basketVehicleList = [];
  basketLoader: boolean = false;
  basketListLoader: boolean = false;
  basketId: number = null;
  type: string = 'all';
  selectedBasket = null;
  constructor(
    public commonService: CommonService,
    private basketService: BasketService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.basketListing();
  }

  @HostListener('deleteBasketVehicle', ['$event'])
  onUnSelect(el) {
    try {
      if (el.detail) {
        // console.log(el.detail, 'el');
        this.removeElement(el.detail)
      }
    } catch (error) {

    }
  }

  removeElement(id) {
    var index = this.basketVehicleList.findIndex(el => el.BasketVId == id);
    // console.log(index, id);
    if (index > -1) {
      this.basketVehicleList.splice(index, 1);
      this.basketVehicleList = [...this.basketVehicleList];
    }
  }

  basketSelection(id) {
    this.selectedBasket = this.baskets.find(el => el.BasketId == id);
    this.basketVehicles();
  }


  basketListing() {
    this.basketLoader = true;
    this.basketService.getBasketListing().subscribe(
      (result) => {
        this.basketLoader = false
        try {
          let response = result.json();
          this.baskets = response.data.baskets;
          this.types = response.data.types;
          if (!this.basketId) {
            let filledBaskets = this.baskets.filter(el => el.TotalVehicles > 0);
            if (filledBaskets.length > 0) {
              this.basketId = filledBaskets[0]['BasketId'];
              this.selectedBasket = filledBaskets[0];
            } else {
              this.basketId = this.baskets[0]['BasketId'];
              this.selectedBasket = this.baskets[0];
            }
            this.basketVehicles();
          }
        } catch (e) {
        }
      },
      (error) => {
        this.basketLoader = false
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );
  }

  addNewBasket() {
    const dialogRef = this.dialog.open(AddBasketComponent, {
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result, 'result');
      if (result) {
        this.baskets.push(result);
      }
    });
  }

  updateBasket() {
    const dialogRef = this.dialog.open(AddBasketComponent, {
      data: this.selectedBasket
    });
    dialogRef.afterClosed().subscribe(result => {
      let index = this.baskets.findIndex(el => {
        return el.BasketId == this.selectedBasket.BasketId
      });
      this.baskets[index] = result;
      this.selectedBasket = result;
    });
  }

  basketVehicles() {
    this.basketListLoader = true;
    let params = {
      id: this.basketId//, type: this.type
    };
    //console.log(params);
    this.basketService.getBasketVehicleListing(params).subscribe(
      (result) => {
        this.basketListLoader = false
        try {
          let response = result.json();
          this.basketVehicleList = response.data;
        } catch (e) {

        }
      },
      (error) => {
        this.basketListLoader = false
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );
  }

  deleteBasket() {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Delete Basket'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.delete();
      }
    });
  }

  delete() {
    this.basketLoader = true;
    this.basketService.deleteBasket({ id: this.selectedBasket.BasketId }).subscribe(
      (result) => {
        this.basketLoader = false
        try {
          //let response = result.json();
          this.selectedBasket = null;
          this.basketId = null;
          this.basketListing();
        } catch (e) {

        }
      },
      (error) => {
        this.basketLoader = false
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );
  }

}
