import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {

  public userInvite: FormGroup;
  public userRoles: any = [];
  public isProcessing: boolean = false;
  public isFetchingFrom: boolean = false;
  public formError = {};
  public onInviteSubmit: EventEmitter<any> = new EventEmitter();
  public onInviteUpdate: EventEmitter<any> = new EventEmitter();
  public isEdit: boolean = false;
  public user: any = {};
  public productListing: any = [];
  public productSelected = []
  public assigned = []
  public oldProducts = []

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InviteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (this.data && this.data.isEdit) {
      this.isEdit = this.data.isEdit;
      this.user = this.data.user;
      if (this.user.Product_Ids && this.user.Product_Ids.length > 0) {
        this.assigned = this.user.Product_Ids.split(',')
        this.oldProducts = this.assigned.map(Number)
      }
      //console.log(this.user);
    }
  }

  ngOnInit() {
    this.initForm();
    this.getUserPermissionList();

  }

  getUserPermissionList() {
    this.userService.getUserPermissionList().subscribe(res => {
      try {
        const response = res.json();
        this.userRoles = response.data.Permissions;
        this.getProducts();
      }
      catch (e) {

      }

    }, (err) => {
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  getProducts() {
    this.isFetchingFrom = true;
    this.userService.getProductListing().subscribe(res => {
      this.isFetchingFrom = false;
      try {
        const response = res.json();
        this.productListing = response.data;
      }
      catch (e) {

      }

    }, (err) => {
      this.isFetchingFrom = false;
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }

    })
  }

  initForm() {
    if (this.isEdit) {
      this.userInvite = new FormGroup({
        'name': new FormControl(this.user.Name, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'userId': new FormControl(this.user.User_Id),
        'email': new FormControl(this.user.Email, [
          Validators.required, Validators.email, Validators.maxLength(40)
        ]),
        'permission_id': new FormControl(this.user.Permission_Id.toString())
      });
    }
    else {
      this.userInvite = new FormGroup({
        'name': new FormControl(null, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'email': new FormControl(null, [
          Validators.required, Validators.email, Validators.maxLength(40)
        ]),
        'permission_id': new FormControl(null)
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  sendInvite(form) {
    if (form.valid) {
      this.isProcessing = true;
      this.formError = {}
      form.value.products = this.productSelected
      this.userService.addUserToCompany(form.value).subscribe(res => {
        try {
          const response = res.json();
          this.onInviteSubmit.emit();
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.userInvite.contains(key)) {
          let control = this.userInvite.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  updateUser(form) {
    if (form.valid) {
      this.isProcessing = true;
      form.value.products = this.oldProducts
      this.userService.updateCompanyUser(form.value).subscribe(res => {
        try {
          const response = res.json();
          this.onInviteUpdate.emit();
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        // this.closeModal();
      }, () => {
        this.isProcessing = false;
        //this.closeModal();
      })
    }
    else {
      this.formValidate(form);
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  productsCheck(event: MatCheckboxChange, products) {
    if (this.isEdit) {
      if (event.checked) {
        this.oldProducts.push(products.Product_Id);
      }
      else {
        const index = this.oldProducts.indexOf(products.Product_Id);
        if (index != -1) {
          this.oldProducts.splice(index, 1);
        }
      }
    } else {
      if (event.checked) {
        this.productSelected.push(products.Product_Id);
      }
      else {
        const index = this.productSelected.indexOf(products.Product_Id);
        if (index != -1) {
          this.productSelected.splice(index, 1);
        }
      }
    }

  }

}
