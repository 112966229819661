import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { JwtService } from './services/jwt.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  showHead: boolean = false;
  constructor(
    private router: Router,
    private jwtService: JwtService
  ) {
    // _router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event['url'].includes('login') || event['url'].includes('forgot - password') || event['url'].includes('create-password')) {
    //       this.showHead = false;
    //     } else {
    //       this.showHead = true;
    //     }
    //   }
    // });
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects.includes('login') || val.urlAfterRedirects.includes('forgot-password') || val.urlAfterRedirects.includes('create-password')) {
          this.showHead = false;
        }
        else if (this.jwtService.IsAuthUser()) {
          this.showHead = true;
        }
        else {
          this.showHead = false;
        }
      }
    });
  }

  ngOnInit() {
  }

}
