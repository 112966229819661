/*
* @ngdoc Component
* @name app.shared.filters.CylindersFilterComponent
* @description
* This component is used to manage the Cylinders filter list
* */

import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Subscription } from 'rxjs';
import { FilterName } from '../../../configs/filter-names';

@Component({
  selector: 'vehicle-cylinders-filter',
  templateUrl: './cylinders-filter.component.html',
  styleUrls: ['./cylinders-filter.component.scss']
})
export class CylindersFilterComponent implements OnInit, OnDestroy {

  @Input() field_type: string;
  @Input() field_class: string;
  @Input() isMultiple: boolean = false;
  @Input('form') parentForm: FormGroup
  @Input() formError: any
  @Input() filterList: Array<any> = []
  isLoading: boolean = false;
  vSubscription: Subscription
  getSelectedFilters: Subscription
  getFilterSelectSignal: Subscription
  checkResetVehicleFilterList: Subscription
  checkInitResetVehicleFilterList: Subscription
  FamilyForm = new FormControl();
  cylindersList = []
  selectedCylinders = "";
  selectedFilter: any = {};
  @Output() sendFilterSelect = new EventEmitter();
  constructor(private vehicleService: VehicleService, private commonService: CommonService, private dataStore: DataStoreService, private filterName: FilterName) {
    this.selectedFilter = filterName.filter
    this.initSub();

  }

  ngOnInit() {
    //console.log('parentForm', this.parentForm.value)
  }

  initSub() {
    this.getSelectedFilters = this.dataStore.getSelectedFilters().subscribe(data => {
      this.selectedFilter = data.filters;
    })
    this.getFilterSelectSignal = this.dataStore.getFilterSelectSignal().subscribe(data => {
      if (data.filter == 'Transmission') {
        this.getCylindersList()
      }
    })
    this.checkResetVehicleFilterList = this.dataStore.checkResetVehicleFilterList().subscribe(data => {
      this.cylindersList = [];
    })
    if (this.isMultiple == false) {
      this.checkInitResetVehicleFilterList = this.dataStore.getInitFilterSelectSignal().subscribe(data => {
        if (data.filter == 'Transmission') {
          this.cylindersList = [];
          this.getCylindersList()
        }
      })
    }
  }

  ngOnChanges(changes) {
    //console.log(changes.)
    if (changes['filterList']) {
      this.cylindersList = changes.filterList.currentValue
    }
    //console.log(this.vFactGroups, 'print the Vfacegroups here')
  }

  /*
  * @name getCylindersList
  * @param  
  * @description Get selected filter list                    
  * @return none
  */
  getCylindersList() {
    this.isLoading = true
    let apiParams = {
      filter: 'Cylinders'
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    delete apiParams['Cylinders'];
    this.vSubscription = this.vehicleService.getVehicleFilters(apiParams).subscribe(
      (result) => {
        try {
          let response = result.json();
          //console.log(response, 'response')
          this.cylindersList = response.data;
          //this.commonService.showSnakeBar(response.message);
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error.json();
        this.isLoading = false;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isLoading = false;
      }
    )
  }


  /*
  * @name selectionChange
  * @param  Array(Selected values)
  * @description Get selected values                    
  * @return none
  */
  selectionChange(value) {
    //console.log('Make change', value)
    if (this.isMultiple) {
      this.sendFilterSelect.emit({ filter: 'Cylinders', filterVal: value, 'all': false, toggle: false });
    } else {
      this.dataStore.sendFilterSelectSignal('Cylinders', value)
    }
  }

  /*
  * @name checkAll
  * @param  Array(response)
  * @description Hangle when user select All options in a filter
  * @return none
  */
  checkAll(response) {
    this.sendFilterSelect.emit({ filter: 'Cylinders', filterVal: response.data, 'all': true, toggle: response.toggle });
  }


  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.getSelectedFilters) {
      this.getSelectedFilters.unsubscribe()
    }
    if (this.getFilterSelectSignal) {
      this.getFilterSelectSignal.unsubscribe()
    }
    if (this.checkResetVehicleFilterList) {
      this.checkResetVehicleFilterList.unsubscribe()
    }
    if (this.checkInitResetVehicleFilterList) {
      this.checkInitResetVehicleFilterList.unsubscribe()
    }
  }

}