/*
* @ngdoc Config
* @name app.configs.ga-configs
* @description
* The service is used to define gtm events configurations
* */

/*
Event configuration
'event':'EventName',
'category': category,
'action': action,
'label': label,
'value': value,
'userData': userData
*/

export class GaConfig {
    public events = {
        rvDashboardHistory: 'userRvDashboardHistoryLoadMore',
        diDashboardHistory: 'userDiDashboardHistoryLoadMore',
        userLogin: 'userLogin',
        userSignup: 'userSignup',
        userLogout: 'userLogout',
        userSesExpire: 'userSessionExpire',
        compareVehicle: 'compareVehicle',
        linkVehicle: 'linkVehicle',
        addRvValues: 'addRvValues',
        valueCalculator: 'valueCalculatorClick',
        rvCalculator: 'rvCalculatorClick',
        vChartRegion: 'vehicleChartRegion',
        vChartDateClick: 'vehicleChartDateClick',
        linkingTreeDetails: 'linkingTreeDetails'
    }
}