export enum httpStatusCodes {
    Success = 200,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    RequestTimeout = 408,
    BadGateway = 502,
    FormError = 422,
    LimitExceeds = 429
  }