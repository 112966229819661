/*
  *
  * @ngdoc Services
  * @name app.services.UserService
  * @description
  * The UserService Service is used for handling the request related to User
  * 
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }

  /*
  * @name getCompanyListing
  * @param  
  * @description get Company User Listing
  * @return Observable
  */
  getCompanyUserListing(filter) {
    return this.commonHttpService.get(this.apiUrls.user.userListing, filter);
  }

  /*
  * @name getCompanyListing
  * @param  
  * @description get User Permission List
  * @return Observable
  */
  getUserPermissionList() {
    return this.commonHttpService.get(this.apiUrls.user.permissionListing);
  }

  /*
  * @name getCompanyListing
  * @param  
  * @description get User Permission List
  * @return Observable
  */
  addUserToCompany(data) {
    return this.commonHttpService.post(this.apiUrls.user.addUserToCompany, data);
  }    

  /*
  * @name editUserDetails
  * @param  
  * @description editUserDetails
  * @return Observable
  */
  updateCompanyUser(data) {
    return this.commonHttpService.post(this.apiUrls.user.editUserDetails, data);
  }

  /*
  * @name deleteUser
  * @param  
  * @description deleteUser
  * @return Observable
  */
  deleteUser(data) {
    return this.commonHttpService.post(this.apiUrls.user.deleteUser, data);
  }

  /*
   * @name getProductListing
   * @param  
   * @description get all product listing
   * @return Observable
   */
  getProductListing() {
    return this.commonHttpService.get(this.apiUrls.products.productListing);
  }

  /*
  * @name singleSignIn
  * @param  
  * @description singleSignIn
  * @return Observable
  */
  singleSignIn(data) {
    return this.commonHttpService.post(this.apiUrls.user.singleSignIn, data);
  }

  /*
  * @name logout
  * @param  
  * @description logout
  * @return Observable
  */
  logout() {
    return this.commonHttpService.post(this.apiUrls.user.logout, {});
  }

  /*
  * @name sendContactUsRequest
  * @param
  * @description Send contact us request
  * @return Observable
  */
  sendContactUsRequest(formData) {
    return this.commonHttpService.post(this.apiUrls.user.contactUs, formData);
  }

  /*
  * @name userProductsAccess
  * @param  
  * @description get user Products Access permission
  * @return Observable
  */
  userProductsAccess(params) {
      return this.commonHttpService.get(this.apiUrls.user.userProductsAccess, params);
  }
}
