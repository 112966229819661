/*
* @ngdoc Component
* @name app.vehicle.new-vehicles.NewVehicleListWrapperComponent
* @description
* This component is used to manage the new vehicle list
* */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'new-vehicle-list-wrapper',
  templateUrl: './new-vehicle-list-wrapper.component.html',
  styleUrls: ['./new-vehicle-list-wrapper.component.scss']
})
export class NewVehicleListWrapperComponent implements OnInit, OnChanges {

  @Output() resetSelection: EventEmitter<any> = new EventEmitter();
  @Output() resetTotalSelection: EventEmitter<any> = new EventEmitter();
  @Output() listLoaded: EventEmitter<any> = new EventEmitter();
  @Input() filterState: any;

  notFoundHeading = 'Hum.. Looks like not record(s) found for this filter'
  notFoundDescription = 'Please update your filter above.'
  isLoadingList: boolean = true;
  isPaging: boolean = false;
  vehicleList = [];
  vSubscription: Subscription
  acceptVehicleSub: Subscription
  rejectVehicleSub: Subscription
  getAcceptedVehicle: Subscription
  getSelectedVehicleSignal: Subscription
  total = 0
  page: any = 1
  limit = 10;
  selectedFilter = {}
  sort = 'asc'
  sortBy = 'new_price'
  throttle = 300;
  scrollDistance = 0.5;
  scrollUpDistance = 1;
  direction = 'down';
  disableScroll = true;
  noMoreRec = false;

  loaderDat = null;
  currentTotal = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private vehicleService: VehicleService,
    private dataStore: DataStoreService
  ) {

    this.getAcceptedVehicle = this.dataStore.checkAcceptVehicle().subscribe(data => {
      //console.log(data, 'data')
      if (data['accept'] == 1) {
        this.acceptVehicle(data['DatiumAssetKey'])
      } else if (data['accept'] == 2) {
        this.rejectVehicle(data['DatiumAssetKey'])
      }
    })

    this.getSelectedVehicleSignal = this.dataStore.getSelectedNewVehicles().subscribe(data => {
      let vList = data['data'];
      let flag = data['flag'];
      if (vList.length > 0) {
        // this.vehicleList.forEach(obj => {
        //   const index = vList.findIndex(DatiumAssetKey => obj.DatiumAssetKey == DatiumAssetKey);
        //   if (index != -1) {
        //     this.vehicleList[index].accepted = flag;
        //   }
        // })
        vList.forEach(DatiumAssetKey => {
          let index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey)
          //console.log('DatiumAssetKey', DatiumAssetKey)
          //console.log('index', index)
          if (index != -1) {
            //console.log('vehicle', this.vehicleList[index])
            this.vehicleList[index].accepted = (flag == 1) ? 1 : 2;
          }
        });
      }
    })
  }

  ngOnInit() {
    //this.getVehicleList()
  }

  ngOnChanges(changes) {
    // Reset vehicle listing array because user click on search button
    console.log('changes', changes)
    if (changes.filterState) {
      let filterStateChanges = changes.filterState;
      //console.log(filterStateChanges, 'filterStateChanges')
      if (filterStateChanges.firstChange == false) {
        let filterVal = filterStateChanges.currentValue
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
        }
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please correct the errors.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
        }
      } else {
        let filterVal = filterStateChanges.currentValue
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.isLoadingList = false;
        }
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
      }
    }
  }

  /*
  * @name resetPaginationRecords
  * @param None
  * @description reset pagination, records and selected filter
  * @return none
  */
  resetPaginationRecords() {
    this.page = 1
    this.limit = 10
    this.total = 0
    this.currentTotal = 0
    this.selectedFilter = {}
    this.vehicleList = [];
    this.sort = 'asc'
    this.sortBy = 'new_price'
    //this.disableScroll = true;
    this.noMoreRec = false;
    //reset loaders
    this.isLoadingList = false;
    this.isPaging = false;
  }


  /*
  * @name acceptVehicle
  * @param None
  * @description accepting the vehicle
  * @return none
  */
  acceptVehicle(DatiumAssetKey) {
    if (this.loaderDat) {
      this.commonService.showSnakeBar('Please wait.. a request is already in progress');
      return false
    }
    this.loaderDat = DatiumAssetKey
    this.acceptVehicleSub = this.vehicleService.acceptVehicle(DatiumAssetKey).subscribe(
      (result) => {
        try {
          let response = result.json();
          //console.log(response, 'response')
          const index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey);
          if (index != -1) {
            this.vehicleList[index].accepted = 1;
          }
          this.commonService.showSnakeBar(response.message);
          this.resetTotalSelection.emit(DatiumAssetKey)
        } catch (e) {

        }
        this.loaderDat = null
      },
      (error) => {
        //console.log(error)
        try {
          let response = error.json();
          //this.isLoading = false;
          if (response.message) {
            this.handleServerFormError(response.errors)
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.loaderDat = null
      },
      () => {
        //this.isLoading = false;
      }
    )
  }

  /*
  * @name rejectVehicle
  * @param None
  * @description rejecting the vehicle
  * @return none
  */
  rejectVehicle(DatiumAssetKey) {
    if (this.loaderDat) {
      this.commonService.showSnakeBar('Please wait.. a request is already in progress');
      return false
    }
    this.loaderDat = DatiumAssetKey
    this.rejectVehicleSub = this.vehicleService.rejectVehicle(DatiumAssetKey).subscribe(
      (result) => {
        try {
          let response = result.json();
          //console.log(response, 'response')
          const index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey);
          if (index != -1) {
            this.vehicleList[index].accepted = 2;
          }
          this.commonService.showSnakeBar(response.message);
          this.resetTotalSelection.emit(DatiumAssetKey)
        } catch (e) {

        }
        this.loaderDat = null
      },
      (error) => {
        //console.log(error)
        try {
          let response = error.json();
          //this.isLoading = false;
          if (response.message) {
            this.handleServerFormError(response.errors)
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.loaderDat = null
      },
      () => {
        //this.isLoading = false;
      }
    )
  }


  /*
  * @name toggleLoader
  * @param None
  * @description Toggle loader
  * @return none
  */
  toggleLoader(show = false, paging = false) {
    if (paging == false) {
      this.isLoadingList = show
    } else {
      this.isPaging = show;
    }
  }

  /*
  * @name getVehicleList
  * @param None
  * @description Api call for fetching the vehicle list
  * @return none
  */
  getVehicleList(paging = false) {
    //this.disableScroll = false;

    let apiParams = {
      page: this.page,
      limit: this.limit,
      sort: this.sort,
      sortBy: this.sortBy,
      list_type: 'new_vehicle'
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    console.log('new vehicle listing params', apiParams)
    /*setTimeout(() => {
      this.router.navigate([location.pathname], { relativeTo: this.route, queryParams: apiParams, replaceUrl: true })
    }, 1)*/
    //this.commonService.sendUserTo('vehicle-list', apiParams)
    // cancel previous request
    if (this.vSubscription != null) {
      //console.log('unsubscribe')
      this.vSubscription.unsubscribe();
    }
    this.toggleLoader(true, paging)
    this.vSubscription = this.vehicleService.getNewVehicleListing(apiParams).subscribe(
      (result) => {
        try {
          let response = result.json();
          let vehicleData = response.data;
          //this.total = vehicleData.total
          this.limit = vehicleData.limit
          this.page = vehicleData.page
          if (this.page == 1) {
            this.total = vehicleData.total
            if (vehicleData.records.length == 0) {
              this.notFoundHeading = "No record found."
              this.notFoundDescription = ''
            }
            this.listLoaded.emit(vehicleData.total)
          }
          this.currentTotal = vehicleData.total;
          //console.log(this.vehicleList.length)
          if (vehicleData.records.length == 0 && paging === true) {
            this.noMoreRec = true;
          } else {
            this.noMoreRec = false;
          }
          if (vehicleData.records.length > 0) {
            this.appendVehicleListing(vehicleData.records)
            //this.vehicleList = vehicleData.records
          }
          // let's know the wrapper parent component, that list is loaded successfully
          //this.listLoaded.emit(true)
          ///console.log(this.vehicleList.length)
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error.json();
        this.toggleLoader(false, paging)
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        //this.listLoaded.emit(false)
      },
      () => {
        this.toggleLoader(false, paging)
      }
    )
  }

  /*
 * @name appendVehicleListing
 * @param newList:Array
 * @description paginate over vehicle listing                   
 * @return none
 */
  appendVehicleListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.vehicleList.length > 0) {
        newState = this.vehicleList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.vehicleList = newState
    }

  }

  /*
  * @name onScroll
  * @param newList:None
  * @description paginate on scroll
  * @return none
  */
  onScroll() {
    /*if (this.disableScroll === true) {
      return false;
    }*/
    if (this.isLoadingList === true || this.isPaging === true) {
      return false;
    }
    //console.log(typeof (this.page), 'this.page')
    let page = parseInt(this.page);
    this.page = page + 1;
    //console.log(typeof (this.page), 'this.page')
    if (this.currentTotal > 10) {
      ///console.log('onScroll request')
      this.getVehicleList(true)
    }
  }

  /*
  * @name orderBy
  * @param None
  * @description handle order by records                   
  * @return none
  */
  orderBy(sortBy = 'new_price', sort = 'asc') {
    this.resetPaginationRecords()
    if (sortBy != 'new_price' && sortBy != 'units') {
      this.sortBy = 'new_price'
    } else {
      this.sortBy = sortBy
    }
    if (sort != 'asc' && sort != 'desc') {
      this.sort = 'asc'
    } else {
      this.sort = sort
    }
    //this.page = 1
    //this.limit = 10;
    //this.vehicleList = []
    this.resetSelection.emit()
    //console.log('loading list initially')
    this.getVehicleList();
  }


  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      //this.sendError.emit(errors)
    } else {
      //this.sendError.emit({})
    }
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }

    if (this.acceptVehicleSub) {
      this.acceptVehicleSub.unsubscribe()
    }
    if (this.rejectVehicleSub) {
      this.rejectVehicleSub.unsubscribe()
    }
    if (this.getSelectedVehicleSignal) {
      this.getSelectedVehicleSignal.unsubscribe()
    }
    if (this.getAcceptedVehicle) {
      this.getAcceptedVehicle.unsubscribe()
    }
  }
}
