/*
* @ngdoc Module
* @name app.vehicle
* @description
* This module is used to handle all the features related to vehicle.
* */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import { MaterialComponentsModule } from './../material-components.module';

import { ApCommonModule } from '../ap-common/ap-common.module';

// Vehicle components
import { AutopredictNewComponent } from './autopredict-new/autopredict-new.component';
import { AutopredictUsedComponent } from './autopredict-used/autopredict-used.component';
import { RiskAdjustmentsComponent } from './risk-adjustments/risk-adjustments.component';
import { LinkingTreeComponent } from './linking-tree/linking-tree/linking-tree.component';
import { RvMatrixComponent } from './rv-matrix/rv-matrix.component';
import { SearchVehicleComponent } from './search-vehicle/search-vehicle.component';
import { CommonService } from '../services/common.service';
import { VehicleListWrapperComponent } from './vehicle-list/vehicle-list-wrapper/vehicle-list-wrapper.component';
import { VehicleListFilterComponent } from './vehicle-list/vehicle-list-filter/vehicle-list-filter.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list/vehicle-list.component';
import { VehicleDetailWrapperComponent } from './vehicle-list/vehicle-detail-wrapper/vehicle-detail-wrapper.component';
import { VehicleDetailComponent } from './vehicle-list/vehicle-detail/vehicle-detail.component';
import { VehicleRvValuesComponent } from './vehicle-list/vehicle-rv-values/vehicle-rv-values.component';
import { VehicleLinkWrapperComponent } from './vehicle-list/vehicle-link-wrapper/vehicle-link-wrapper.component';
import { RouterModule } from '@angular/router';
import { VehicleShortDetailComponent } from './vehicle-list/vehicle-short-detail/vehicle-short-detail.component';
import { VehicleRvHistoryComponent } from './vehicle-list/vehicle-rv-history/vehicle-rv-history.component';
import { VehicleRvWrapperComponent } from './vehicle-list/vehicle-rv-wrapper/vehicle-rv-wrapper.component';
import { CompareVehiclesComponent } from './vehicle-list/compare-vehicles/compare-vehicles.component';
import { VehicleComponent } from './linking-tree/vehicle/vehicle.component';
import { VehicleLinkedComponent } from './linking-tree/vehicle-linked/vehicle-linked.component';
import { LinkingWrapperComponent } from './linking-tree/linking-wrapper/linking-wrapper.component';
import { LinkVehicleSuperAdminComponent } from './vehicle-list/link-vehicle-super-admin/link-vehicle-super-admin.component';
import { NewVehicleListComponent } from './new-vehicles/new-vehicle-list/new-vehicle-list.component';
import { NewVehicleListWrapperComponent } from './new-vehicles/new-vehicle-list-wrapper/new-vehicle-list-wrapper.component';
import { NewVehicleDetailComponent } from './new-vehicles/new-vehicle-detail/new-vehicle-detail.component';
import { NewVehicleDetailWrapperComponent } from './new-vehicles/new-vehicle-detail-wrapper/new-vehicle-detail-wrapper.component';
import { AddRiskAdjustmentComponent } from './add-risk-adjustment/add-risk-adjustment.component';
import { SelCompareVehicleComponent } from './vehicle-list/sel-compare-vehicle/sel-compare-vehicle.component';
import { BiFilterSearchComponent } from './bi-filter-search/bi-filter-search.component';
import { BiVehicleListComponent } from './bi-vehicle-list/bi-vehicle-list.component';
import { BiFilterWrapperComponent } from './bi-filter-wrapper/bi-filter-wrapper.component';
import { BiSearchVehicleComponent } from './bi-search-vehicle/bi-search-vehicle.component';
import { NewAdminLinkComponent } from './new-admin-link/new-admin-link.component';
import { PriceTrackerWrapperComponent } from './price-tracker-wrapper/price-tracker-wrapper.component';
import { BasketWrapperComponent } from './basket-wrapper/basket-wrapper.component';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    SharedModule,
    RouterModule,
    ApCommonModule,
    ClipboardModule
  ],
  declarations: [
    AutopredictNewComponent,
    AutopredictUsedComponent,
    RiskAdjustmentsComponent,
    LinkingTreeComponent,
    RvMatrixComponent,
    SearchVehicleComponent,
    VehicleListWrapperComponent,
    VehicleListFilterComponent,
    VehicleListComponent,
    VehicleDetailWrapperComponent,
    VehicleDetailComponent,
    VehicleRvValuesComponent,
    VehicleLinkWrapperComponent,
    VehicleShortDetailComponent,
    VehicleRvHistoryComponent,
    VehicleRvWrapperComponent,
    CompareVehiclesComponent,
    CompareVehiclesComponent,
    VehicleComponent,
    VehicleLinkedComponent,
    LinkingWrapperComponent,
    LinkVehicleSuperAdminComponent,
    NewVehicleListComponent,
    NewVehicleListWrapperComponent,
    NewVehicleDetailWrapperComponent,
    NewVehicleDetailComponent,
    AddRiskAdjustmentComponent,
    SelCompareVehicleComponent,
    BiFilterSearchComponent,
    BiVehicleListComponent,
    BiFilterWrapperComponent,
    BiSearchVehicleComponent,
    NewAdminLinkComponent,
    PriceTrackerWrapperComponent,
    BasketWrapperComponent,
    QuickSearchComponent
  ],
  entryComponents: [
    CompareVehiclesComponent,
    AddRiskAdjustmentComponent,
    NewAdminLinkComponent
  ],
  providers: [
    CommonService
  ]
})
export class VehicleModule { }
