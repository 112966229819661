import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from './../../services/vehicle.service';
import { CommonService } from './../../services/common.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

export interface ReportData {
  NewPrice: number;
  UserNewPrice: number;
  adj_12: number;
  adj_24: number;
  adj_36: number;
  adj_48: number;
  adj_60: number;
  age_12: number;
  age_24: number;
  age_36: number;
  age_48: number;
  age_60: number;
  created_at: string;
  DatiumAssetKey: string;
  km_12: number;
  km_24: number;
  km_36: number;
  km_48: number;
  km_60: number;
  modified_at: string;
  rv_dollar_12: number;
  rv_dollar_24: number;
  rv_dollar_36: number;
  rv_dollar_48: number;
  rv_dollar_60: number;
  rv_per_12: number;
  rv_per_24: number;
  rv_per_36: number;
  rv_per_48: number;
  rv_per_60: number;
  statusId: number;
  type: string;
  checked: boolean;
}

@Component({
  selector: 'app-vehicle-reports',
  templateUrl: './vehicle-reports.component.html',
  styleUrls: ['./vehicle-reports.component.scss']
})
export class VehicleReportsComponent implements OnInit {

  public reportList = new MatTableDataSource<ReportData>([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isLoading: boolean = false
  public displayedColumns: string[] = [
    'selectBox',
    'DatiumAssetKey',
    'Description',
    'NewPrice',
    'age_12',
    'km_12',
    'rv_per_12',
    'type',
    'formatCreatedDate'
  ];
  public selectedItems: Array<any> = [];
  public isExporting: boolean = false;
  public isAllSelected: boolean = false;
  public pageSize: number = 20;
  public pageIndex: number = 0;
  constructor(
    private commonService: CommonService,
    private vehicleService: VehicleService,
    private roleCheckerSer: RoleCheckerService
  ) {
    if (this.roleCheckerSer.isSuperAdmin()) {
      this.commonService.navigate('/dashboard');
    }
  }

  ngOnInit() {
    this.reportList.sort = this.sort;
    this.reportList.paginator = this.paginator;
    this.getReports();
  }

  applyFilter(filterValue: string) {
    this.reportList.filter = filterValue.trim().toLowerCase();
  }

  getReports() {
    this.isLoading = true;
    const isDemo = location.search.includes('?demo');
    this.vehicleService.vehicleReport({ 'isDemo': isDemo }).subscribe(
      (result) => {
        this.isLoading = false;
        try {
          const response = result.json();
          let data: ReportData[] = response.data;
          for (let i = 0; i < data.length; i++) {
            data[i].checked = false;
          }
          this.reportList = new MatTableDataSource<ReportData>(data);
          this.reportList.data = this.reportList.data.slice();
          this.reportList.sort = this.sort;
          this.reportList.paginator = this.paginator;
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
      },
      (error) => {
        const response = error.json();
        this.isLoading = false;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }
    )
  }

  onChange(event, element) {
    if (event.checked) {
      this.selectedItems.push(element);
      const totalPages = Math.ceil(this.reportList.data.length / this.pageSize);
      let listedItems = 0;
      if ((this.pageIndex + 1) < totalPages) {
        listedItems = this.pageSize;
      } else {
        listedItems = this.reportList.data.length - this.pageIndex * this.pageSize;
      }
      if (this.selectedItems.length === listedItems) {
        this.isAllSelected = true;
      }
    } else {
      const index = this.selectedItems.findIndex(item => item.DatiumAssetKey === element.DatiumAssetKey);
      this.selectedItems.splice(index, 1);
      this.isAllSelected = false;
    }
  }

  toggleSelectAll() {
    this.isAllSelected = !this.isAllSelected;
    this.selectedItems = [];
    const startIndex = this.pageIndex > 0 ? (this.pageIndex * this.pageSize) : 0;
    const endIndex = this.pageIndex > 0 ? (startIndex + this.pageSize) : this.pageSize;
    for (let i = startIndex; i < endIndex; i++) {
      if (this.reportList.data[i]) {
        this.reportList.data[i].checked = this.isAllSelected;
        if (this.isAllSelected) {
          this.selectedItems.push(this.reportList.data[i]);
        }
      }
    }
  }

  getPaginatorOptions(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.isAllSelected = false;
    this.selectedItems = [];
    for (let i = 0; i < this.reportList.data.length; i++) {
      this.reportList.data[i].checked = false;
    }
  }

  exportingFinishEvent(event) {
    if (event === 'done') {
      this.isAllSelected = false;
      this.selectedItems = [];
      for (let i = 0; i < this.reportList.data.length; i++) {
        this.reportList.data[i].checked = false;
      }
    }
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
