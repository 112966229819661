export const environment = {
  production: true,
  //apiUrl: 'https://aea-autopredappbe-webapp-dev-1.azurewebsites.net/api/',
  apiUrl: 'https://autopredict.api-dev.env.datiuminsights.com.au/autp/api/',
  frontEndUrl: "https://autopredict-dev.env.datiuminsights.com.au",
  script: false,
  name: 'dev',
  portalUrl: 'https://portal-dev.env.datiuminsights.com.au/',
  truckvalUrl: 'https://truckval-dev.env.datiuminsights.com.au/',
  bikevalUrl: 'https://bikeval-dev.env.datiuminsights.com.au/',
  salvagevalUrl: 'https://salvageval-dev.env.datiuminsights.com.au/',
  instantvalUrl: 'https://instantval-dev.env.datiuminsights.com.au/',
  pricemytruckUrl: 'https://pricemytruck-dev.env.datiuminsights.com.au/',
  autopredictUrl: 'https://autopredict-dev.env.datiuminsights.com.au/'
};
