/*
* @ngdoc Services
* @name app.services.RoleCheckerService
* @description
* The service is used for handling and checking the user roles
* */

import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { userRoles } from 'src/app/models/user-roles.model';

@Injectable({
  providedIn: 'root'
})
export class RoleCheckerService {

  constructor(
    private jwtService: JwtService
  ) {

  }

  public isCompanyUser() {
    return (this.jwtService.getUserRole() == userRoles.companyUser) ? true : false;
  }

  public isCompanyOwner() {
    return (this.jwtService.getUserRole() == userRoles.companyOwner) ? true : false;
  }

  public isCompanyAdmin() {
    return (this.jwtService.getUserRole() == userRoles.companyAdmin) ? true : false;
  }

  public isSuperAdmin() {
    return (this.jwtService.getUserRole() == userRoles.superAdmin || this.isLegalese()) ? true : false;
  }

  public isLegalese() {
    return (this.jwtService.getUserRole() == userRoles.legalese) ? true : false;
  }

  public isWriteAccess() {
    return this.jwtService.hasWriteAccess();
  }

  // This function is used to check the permissions and skip the check for super admin user
  public checkUserPermission(per: string = "") {
    let permissions = this.jwtService.getUserPermissions()
    //console.log(permissions, per, permissions.indexOf(per))
    if (this.isSuperAdmin() || this.isLegalese()) {
      return true
    } else {
      if (permissions.indexOf(per) != -1) {
        return true
      }
    }
    return false;
  }

  public checkAutoAccept(per: string = ""): boolean {
    let isAutoAccept = this.jwtService.getAutoAccept()
    //console.log(permissions, per, permissions.indexOf(per))
    if (this.isSuperAdmin() || this.isLegalese()) {
      return false
    } else {
      if (isAutoAccept == 1) {
        return true
      }
    }
    return false;
  }
}
