import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
@Component({
  selector: 'app-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.scss']
})
export class SelectCheckAllComponent implements OnInit, OnChanges {


  @Input('form') parentForm: FormControl;
  @Input() controlObj: FormControl;
  @Input() controlName: string;
  @Input() currentControlName: string;
  @Input() values = [];
  @Input() allFilterList: Array<any> = [];
  @Input() text = 'All';

  @Output() checkAll = new EventEmitter();

  constructor() { }

  ngOnInit() {
    //console.log(this.currentControlName)
  }

  ngOnChanges(changes) {
    if (changes) {
      // selected value and all filter list values
      if (changes.values && changes.allFilterList) {
        if (changes.values.currentValue && changes.allFilterList.currentValue) {
          this.values = changes.values.currentValue;
          //console.log(this.parentForm.value.length, this.values.length)
          //console.log(changes.allFilterList[this.currentControlName])
          if (changes.allFilterList[this.currentControlName]) {
            this.isChecked(changes.allFilterList[this.currentControlName])
          }
        }
      }
    }
  }

  isChecked(currentFilter: Array<any> = []): boolean {
    try {
      if (currentFilter) {
        return currentFilter && this.values.length
          && currentFilter.length === this.values.length;
      } else {
        return this.parentForm.value && this.values.length
          && this.parentForm.value.length === this.values.length;
      }
    } catch (e) {

    }
  }

  isIndeterminate(): boolean {
    try {
      return this.parentForm.value && this.values.length && this.parentForm.value.length
        && this.parentForm.value.length < this.values.length;
    } catch (e) {

    }
  }

  toggleSelection(change: MatCheckboxChange): void {
    try {
      if (change.checked) {
        let filter = []
        this.values.forEach(element => {
          filter.push(element.filter)
        });
        this.parentForm.patchValue(filter)
        this.checkAll.emit({ toggle: true, data: filter })
      } else {
        this.parentForm.patchValue([])
        this.checkAll.emit({ toggle: false, data: [] })
      }
    } catch (e) {

    }
  }

}
