import { Component, OnInit, Input, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddRvValuesComponent } from '../../../shared/add-rv-values/add-rv-values.component';
import { LinkVehicleComponent } from '../../../shared/link-vehicle/link-vehicle.component';
import { DataStoreService } from '../../../services/data-store.service';
//import { Subscription } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { ConfirmationPopupComponent } from '../../../shared/confirmation-popup/confirmation-popup.component';
import { RvValuesComponent } from '../../../shared/calculator/rv-values/rv-values.component';
import { GaEventsService } from '../../../services/ga-events.service';
import { BasketService } from 'src/app/services/basket.service';
import { AddBasketVehicleComponent } from 'src/app/shared/add-basket-vehicle/add-basket-vehicle.component';


@Component({
  selector: 'vehicle-short-detail',
  templateUrl: './vehicle-short-detail.component.html',
  styleUrls: ['./vehicle-short-detail.component.scss']
})
export class VehicleShortDetailComponent implements OnInit, OnDestroy {

  @Input() isRvValues;
  @Input() linkedVehicle;
  @Input() vehicleId;
  @Input() vehicle;
  @Output() vehicleActionEvent = new EventEmitter();
  //getSelectedCarDataSignal: Subscription;
  //selectCarForComapare: Array<any> = [];
  selectedVehicles: number = 0
  checked = false;
  isBasket: boolean = false;
  isNewVehicle: boolean = false;
  public isComponentLoaded: boolean = false;
  constructor(
    public dialog: MatDialog,
    public dataStoreService: DataStoreService,
    public commonService: CommonService,
    public roleCheckService: RoleCheckerService,
    private gaEvent: GaEventsService,
    private basketService: BasketService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    /*this.getSelectedCarDataSignal = this.dataStoreService.getSelectedCarData().subscribe(data => {
      this.selectCarForComapare = data;
    });*/
    //console.log(this.vehicle, 'vehicle')
    if (location.href.includes('basket')) {
      this.isBasket = true;
    }
    if (location.href.includes('new-vehicles')) {
      this.isNewVehicle = true;
    }
  }

  ngOnChanges() {
    this.checked = this.vehicle.checked;
  }


  openRVCal(vehicle): void {
    let gaData = {
      'event': 'valueCalculator',
      'value': this.vehicleId
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    const dialogRef = this.dialog.open(RvValuesComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        vehicle: vehicle,
        back: false
      }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  openLinkedVehicle(): void {
    let gaData = {
      'event': 'linkVehicle',
      'value': this.vehicleId
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    const dialogRef = this.dialog.open(LinkVehicleComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkedVehicle: this.linkedVehicle,
        vehicle: this.vehicle,
        vehicleId: this.vehicleId
      }
    });
  }

  addRvValues(vehicle = {}): void {
    if (!this.roleCheckService.isSuperAdmin()) {
      let gaData = {
        'event': 'addRvValues',
        'value': this.vehicleId
      }
      this.gaEvent.sendGaTagConfigData(gaData)
    }
    const dialogRef = this.dialog.open(AddRvValuesComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        vehicle: vehicle
      }
    });
  }
  OnChange(event) {
    let length = this.commonService.getCarSelectionLength()
    this.checked = event.checked;
    this.vehicle.checked = this.checked;
    this.vehicleActionEvent.emit({ vehicleKey: this.vehicle.DatiumAssetKey, checked: this.checked });
  }
  toggleCarSelection(event): void {
    if (this.commonService.toggleIndex(this.vehicle, 'active')) {
      let length = this.commonService.getCarSelectionLength()
      // check the " checked " previous value because onClick event is always fire before ngModelChange
      if (length >= 25 && this.checked == false) {
        event.preventDefault();
        this.checked = false;
        this.commonService.showSnakeBar("Maximum limit of selection reached");
      } else {
        //Only enable compare vehicle that has index and not rejected

        //For accepted/rejected screen
        let vehicle = Object.assign({}, this.vehicle)
        if (vehicle.hasOwnProperty('accepted')) {
          if (vehicle['accepted'] == 0) {
            const newLength = this.commonService.getNewVehicleSelectionLength();
            // check the " checked " previous value because onClick event is always fire before ngModelChange
            if (newLength > 24 && this.checked == false) {
              event.preventDefault();
              this.checked = false;
              this.commonService.showSnakeBar("Maximum limit of selection reached");
            } else {
              this.dataStoreService.setSelectedNewVehicle(this.vehicle);
            }
          } else {
            if (this.isRvValues) {
              this.dataStoreService.setSelectedCarData({ vehicle: this.vehicle });
            }
          }
        } else {
          if (this.isRvValues) {
            this.dataStoreService.setSelectedCarData({ vehicle: this.vehicle });
          }
        }
      }
    }
  }

  acceptValues(): void {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to accept this vehicle?'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        //console.log(DatiumAssetKey, 'DatiumAssetKey')
        //this.selectedVehicle = DatiumAssetKey
        this.dataStoreService.setAcceptVehicle({ DatiumAssetKey: this.vehicleId, accept: 1 })
      }
    });
  }

  rejectValues(): void {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to reject this vehicle?'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        //console.log(DatiumAssetKey, 'DatiumAssetKey')
        //this.selectedVehicle = DatiumAssetKey
        this.dataStoreService.setAcceptVehicle({ DatiumAssetKey: this.vehicleId, accept: 2 })
      }
    });
  }

  checkDis() {
    //console.log(this.isRvValues, this.commonService.toggleIndex(this.vehicle, 'rejected'))
    if (!this.isRvValues) {
      return true
    } else {
      if (!this.commonService.toggleIndex(this.vehicle, 'rejected')) {
        return true
      } else {
        return false
      }
    }
  }

  captureValues() {
    //vehicleId
    this.dataStoreService.setCapturedVehicle(this.vehicleId)
  }

  ngOnDestroy() {
    // if (this.getSelectedCarDataSignal) {
    //   this.getSelectedCarDataSignal.unsubscribe();
    // }
  }

  deleteBasket() {
    if (this.vehicle['BasketVId']) {
      const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          title: 'Delete Vehicle From Basket?'
        }
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result == 'yes') {
          this.delete();
        }
      });
    }
  }

  basketLoader: any = null
  delete() {
    this.basketLoader = this.vehicle['BasketVId'];
    this.basketService.deleteVehicle({ id: this.vehicle['BasketVId'] }).subscribe(
      (result) => {
        this.basketLoader = null
        try {
          //let response = result.json();
          if (this.isBasket) {
            //window.location.reload();
            const domEvent = new CustomEvent('deleteBasketVehicle', {
              bubbles: true,
              detail: this.vehicle['BasketVId']
            });
            this.el.nativeElement.dispatchEvent(domEvent);
          } else {
            this.vehicle['BasketVId'] = null;
          }
        } catch (e) {

        }
      },
      (error) => {
        this.basketLoader = null
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );
  }

  toggleBasketModel() {
    const confirmDialogRef = this.dialog.open(AddBasketVehicleComponent, {
      width: '500px',
      height: 'auto',
      data: {
        vehicleId: this.vehicleId
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.vehicle['BasketVId'] = result;
      }
    });
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
