import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-vehicles-added',
  templateUrl: './new-vehicles-added.component.html',
  styleUrls: ['./new-vehicles-added.component.scss']
})
export class NewVehiclesAddedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
