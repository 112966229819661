/*
* @ngdoc Config
* @name app.configs.AppHeadings
* @description
* The class is used to holding the static text, we are going to use in the application
* */

export class AppHeadings {
    vehicle = {
        p_title: {
            list: 'Vehicle List',
            search: 'Vehicle Search',
            linkingTree: 'Linking Tree',
            priceTracker: 'Price Tracker'
        },
        heading: {
            new_vehicle: 'Current Releases',
            list: 'Vehicle List',
            used_vehicle: 'Past Releases',
            new_vadmin: 'New Vehicles'
        }
    }
}