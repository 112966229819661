import { Component, OnInit, Input } from '@angular/core';
import { AppSettings } from '../../configs/app-settings';

@Component({
  selector: 'app-text-format',
  templateUrl: './text-format.component.html',
  styleUrls: ['./text-format.component.scss']
})
export class TextFormatComponent implements OnInit {

  @Input() defaultNoFormat: String;
  @Input() data: any;
  @Input() formatType: String;
  @Input() symbol: String;
  Htime
  constructor(private appSettings: AppSettings) {
    if (this.defaultNoFormat == null || this.defaultNoFormat == '') {
      this.defaultNoFormat = 'N/A'
    }
    this.Htime = this.appSettings.FULL_DATE
  }

  ngOnInit() {
  }

}
