import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-autopredict-new',
  templateUrl: './autopredict-new.component.html',
  styleUrls: ['./autopredict-new.component.scss']
})
export class AutopredictNewComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.setTitle('Current Releases');
  }

}
