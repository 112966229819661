import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'history-wrap',
  templateUrl: './history-wrap.component.html',
  styleUrls: ['./history-wrap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HistoryWrapComponent implements OnInit {

  constructor(
    public roleCheckService: RoleCheckerService
  ) { }

  ngOnInit() {
  }

}
