import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss']
})
export class UsageComponent implements OnInit {

  public displayedColumns = [];
  public dataSource;
  public chart;
  @ViewChild(MatSort) sort: MatSort;

  public Highcharts = Highcharts;

  public columnNames = [{
    id: "user",
    value: "User"

  }, {
    id: "api_key",
    value: "API Key"
  },
  {
    id: "request_date",
    value: "Request date"
  },
  {
    id: "number_request",
    value: "Number of Request"
  }];

  constructor() { }

  ngOnInit() {
    this.displayedColumns = this.columnNames.map(x => x.id);
    this.createTable();
    this.makeHighChart();
  }

  makeHighChart() {
    this.chart = Highcharts.chart('container', {

      title: {
        text: 'Chart.update'
      },

      subtitle: {
        text: 'Plain'
      },

      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },

      series: [{
        type: 'column',
        //colorByPoint: true,
        data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
        //showInLegend: false
      }],

      credits: {
        enabled: false
      }

    });
  }

  createTable() {
    let tableArr: Element[] = [
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
      { user: 'Christie Evans', api_key: 'HGImqDDbS3h69DHR8q6hwrAKXKd2IgHo', request_date: 'Nov 14', number_request: '50 requests' },
    ];
    this.dataSource = new MatTableDataSource(tableArr);
    this.dataSource.sort = this.sort;
  }

  populateData(update) {
    if (update) {
      this.chart.update({
        series: [{
          type: 'column',
          colorByPoint: true,
          data: [219.9, 711.5, 1106.4, 1129.2, 1144.0, 1176.0, 1135.6, 1148.5, 2116.4, 1194.1, 915.6, 514.4],
          showInLegend: false
        }],
      })
    }
    else {
      this.chart.update({
        series: [{
          type: 'column',
          colorByPoint: true,
          data: [11, 32, 54, 78, 91, 34, 65, 78, 45, 76, 89, 91],
          showInLegend: false
        }],
      })
    }
  }
}

export interface Element {
  user: string,
  api_key: string,
  request_date: string,
  number_request: string
}






