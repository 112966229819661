import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-rv-matrix',
  templateUrl: './rv-matrix.component.html',
  styleUrls: ['./rv-matrix.component.scss']
})
export class RvMatrixComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.setTitle('RV Matrix');
  }

}