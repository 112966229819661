import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { FilterName } from 'src/app/configs/filter-names';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'app-vehicle-filter-search',
  templateUrl: './vehicle-filter-search.component.html',
  styleUrls: ['./vehicle-filter-search.component.scss']
})
export class VehicleFilterSearchComponent implements OnInit {
  @Input() cssClass: string
  @Input() listingType: string = ''
  @Input() filterClass: string = 'col col-lg-3'
  @Input() btnClass: string = ''
  @Input() type: string = 'search'
  @Input() filterSelData: any;
  @Input() isExportingResults: boolean = false;
  @Output() searchResponse = new EventEmitter();

  searchForm: FormGroup;
  getFilterSelectSignal: Subscription
  searchFormValueChanges: Subscription
  formError = {};

  isMultiple = true;
  isLoading: boolean = false;
  allFilterList: Array<any> = [];
  isFilter: boolean = false
  vSubscription: Subscription
  apiParams: any = {
    filter: 'VFactsGroups',
    VFactsGroups: '',
    Make: '',
    Family: '',
    Body_Type: '',
    Fuel_Type: '',
    Transmission: '',
    Cylinders: '',
    Vehicle_Level: '',
    type: 'bi',
    list_type: null
  }
  filterControls = []
  filterOrder = ['VFactsGroups', 'Make', 'Family', 'Year_Group', 'Body_Type', 'Fuel_Type', 'Transmission', 'Cylinders', 'Vehicle_Level']
  isDisabled = false
  search: string = 'search';
  //resultOption = { 'title': 'Recently Added', 'name': 'recentlyAdded' };
  constructor(private vehicleService: VehicleService, private fb: FormBuilder, private commonService: CommonService, private filterName: FilterName, private roleCheckerService: RoleCheckerService) {
    if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
      this.filterControls = this.filterName.filterNewList;
      if (!roleCheckerService.isSuperAdmin()) {
        const Ind_NewVehicleIndex = this.filterControls.findIndex(x => x.name == 'Ind_NewVehicle');
        if (Ind_NewVehicleIndex > -1) {
          this.filterControls.splice(Ind_NewVehicleIndex, 1);
        }
      }
      this.filterOrder = ['VFactsGroups', 'Make', 'Family', 'Year_Group', 'Body_Type', 'Fuel_Type', 'Transmission', 'Cylinders', 'Vehicle_Level', 'recentlyAdded', 'Ind_NewVehicle']

      /*let resultLength = this.filterControls.filter(obj => {
        if (obj.title == this.resultOption.title && obj.name == this.resultOption.name) {
          return obj;
        }
      })

      //console.log(resultLength, '-----------print the length for the abc');

      if (resultLength.length <= 0) {
        this.filterControls.push(this.resultOption);
      }*/


      // console.log(this.filterControls, '-------print the filter controls after the filter condition')
      this.searchForm = this.fb.group(
        {
          VFactsGroups: new FormControl([]),
          VFactsGroups_check: new FormControl(false),
          Make: new FormControl([]),
          Make_check: new FormControl(false),
          Family: new FormControl([]),
          Family_check: new FormControl(false),
          Year_Group: new FormControl([]),
          Year_Group_check: new FormControl(false),
          Body_Type: new FormControl([]),
          Body_Type_check: new FormControl(false),
          Fuel_Type: new FormControl([]),
          Fuel_Type_check: new FormControl(false),
          Transmission: new FormControl([]),
          Transmission_check: new FormControl(false),
          Cylinders: new FormControl([]),
          Cylinders_check: new FormControl(false),
          Vehicle_Level: new FormControl([]),
          Vehicle_Level_check: new FormControl(false),
          recentlyAdded: new FormControl('all_time'),
          Ind_NewVehicle: new FormControl('all')
        }
      )
    }
    else if (location.pathname.includes('new-vehicles')) {
      this.filterControls = this.filterName.filterNewVehicleList
      this.searchForm = this.fb.group(
        {
          VFactsGroups: new FormControl([]),
          VFactsGroups_check: new FormControl(false),
          Make: new FormControl([]),
          Make_check: new FormControl(false),
          Family: new FormControl([]),
          Family_check: new FormControl(false),
          Year_Group: new FormControl([]),
          Year_Group_check: new FormControl(false),
          Body_Type: new FormControl([]),
          Body_Type_check: new FormControl(false),
          Fuel_Type: new FormControl([]),
          Fuel_Type_check: new FormControl(false),
          Transmission: new FormControl([]),
          Transmission_check: new FormControl(false),
          Cylinders: new FormControl([]),
          Cylinders_check: new FormControl(false),
          Vehicle_Level: new FormControl([]),
          Vehicle_Level_check: new FormControl(false),
          Datium_Values: new FormControl('available')
        }
      )
    }
    else {
      this.filterControls = this.filterName.filterList;
      if (!roleCheckerService.isSuperAdmin()) {
        const Ind_NewVehicleIndex = this.filterControls.findIndex(x => x.name == 'Ind_NewVehicle');
        if (Ind_NewVehicleIndex > -1) {
          this.filterControls.splice(Ind_NewVehicleIndex, 1);
        }
      }
      this.searchForm = this.fb.group(
        {
          VFactsGroups: new FormControl([]),
          VFactsGroups_check: new FormControl(false),
          Make: new FormControl([]),
          Make_check: new FormControl(false),
          Family: new FormControl([]),
          Family_check: new FormControl(false),
          Year_Group: new FormControl([]),
          Year_Group_check: new FormControl(false),
          Body_Type: new FormControl([]),
          Body_Type_check: new FormControl(false),
          Fuel_Type: new FormControl([]),
          Fuel_Type_check: new FormControl(false),
          Transmission: new FormControl([]),
          Transmission_check: new FormControl(false),
          Cylinders: new FormControl([]),
          Cylinders_check: new FormControl(false),
          Vehicle_Level: new FormControl([]),
          Vehicle_Level_check: new FormControl(false),
          Ind_NewVehicle: new FormControl('all')
        }
      )
    }
    //console.log(this.filterControls)
    //this.filterControls = this.filterName.filterList
    //console.log(this.filterControls, 'print the filter controle')

    this.searchFormValueChanges = this.searchForm.valueChanges.subscribe(newVal => {
      //this.dataStore.setSelectedFilters(newVal)
      //console.log(newVal)
      //this.newFormValues = newVal
    })
  }

  ngOnInit() {
    //console.log(this.type)
    if (this.type == 'listing') {
      this.processSearchData()
    } else if (this.type == 'popup') {
      this.getAllFilterList(false);
    } else {
      this.getAllFilterList(true);
    }
  }

  getFormResetIniObj(formData = null) {
    if (formData) {
      //selectedFilters, origData
      let selectedFilters = formData.selectedFilters
      let origData = formData.origData
      let resetObj =
      {
        VFactsGroups: selectedFilters['VFactsGroups'] ? selectedFilters['VFactsGroups'] : '',
        Make: selectedFilters['Make'] ? selectedFilters['Make'] : '',
        Family: selectedFilters['Family'] ? selectedFilters['Family'] : '',
        Year_Group: selectedFilters['Year_Group'] ? selectedFilters['Year_Group'] : '',
        Body_Type: selectedFilters['Body_Type'] ? selectedFilters['Body_Type'] : '',
        Fuel_Type: selectedFilters['Fuel_Type'] ? selectedFilters['Fuel_Type'] : '',
        Transmission: selectedFilters['Transmission'] ? selectedFilters['Transmission'] : '',
        Cylinders: selectedFilters['Cylinders'] ? selectedFilters['Cylinders'] : '',
        Vehicle_Level: selectedFilters['Vehicle_Level'] ? selectedFilters['Vehicle_Level'] : '',
        recentlyAdded: selectedFilters['recentlyAdded'] ? selectedFilters['recentlyAdded'] : 'all_time',
        Datium_Values: selectedFilters['Datium_Values'] ? selectedFilters['Datium_Values'] : 'available',
        Ind_NewVehicle: selectedFilters['Ind_NewVehicle'] ? selectedFilters['Ind_NewVehicle'] : 'all'
      }

      let resetOriObj =
      {
        VFactsGroups: origData['VFactsGroups'] ? origData['VFactsGroups'] : [],
        VFactsGroups_check: origData['VFactsGroups_check'] ? origData['VFactsGroups_check'] : false,
        Make: origData['Make'] ? origData['Make'] : [],
        Make_check: origData['Make_check'] ? origData['Make_check'] : false,
        Family: origData['Family'] ? origData['Family'] : [],
        Family_check: origData['Family_check'] ? origData['Family_check'] : false,
        Year_Group: origData['Year_Group'] ? origData['Year_Group'] : [],
        Year_Group_check: origData['Year_Group_check'] ? origData['Year_Group_check'] : false,
        Body_Type: origData['Body_Type'] ? origData['Body_Type'] : [],
        Body_Type_check: origData['Body_Type_check'] ? origData['Body_Type_check'] : false,
        Fuel_Type: origData['Fuel_Type'] ? origData['Fuel_Type'] : [],
        Fuel_Type_check: origData['Fuel_Type_check'] ? origData['Fuel_Type_check'] : false,
        Transmission: origData['Transmission'] ? origData['Transmission'] : [],
        Transmission_check: origData['Transmission_check'] ? origData['Transmission_check'] : false,
        Cylinders: origData['Cylinders'] ? origData['Cylinders'] : [],
        Cylinders_check: origData['Cylinders_check'] ? origData['Cylinders_check'] : false,
        Vehicle_Level: origData['Vehicle_Level'] ? origData['Vehicle_Level'] : [],
        Vehicle_Level_check: origData['Vehicle_Level_check'] ? origData['Vehicle_Level_check'] : false,
        recentlyAdded: origData['recentlyAdded'] ? origData['recentlyAdded'] : 'all_time',
        Datium_Values: origData['Datium_Values'] ? origData['Datium_Values'] : 'available',
        Ind_NewVehicle: origData['Ind_NewVehicle'] ? origData['Ind_NewVehicle'] : 'all'
      }
      if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
        if (resetObj['Datium_Values']) {
          delete resetObj['Datium_Values']
        }

        if (resetOriObj['Datium_Values']) {
          delete resetOriObj['Datium_Values']
        }
      }
      else if (location.pathname.includes('new-vehicles')) {
        if (resetObj['recentlyAdded']) {
          delete resetObj['recentlyAdded']
        }

        if (resetOriObj['recentlyAdded']) {
          delete resetOriObj['recentlyAdded']
        }

        if (resetObj['Ind_NewVehicle']) {
          delete resetObj['Ind_NewVehicle']
        }

        if (resetOriObj['Ind_NewVehicle']) {
          delete resetOriObj['Ind_NewVehicle']
        }
      }
      else {
        if (resetObj['Datium_Values']) {
          delete resetObj['Datium_Values']
        }

        if (resetOriObj['Datium_Values']) {
          delete resetOriObj['Datium_Values']
        }

        if (resetObj['recentlyAdded']) {
          delete resetObj['recentlyAdded']
        }

        if (resetOriObj['recentlyAdded']) {
          delete resetOriObj['recentlyAdded']
        }
      }
      return {
        resetObj
        , resetOriObj
      }
    } else {
      let resetObj =
      {
        VFactsGroups: '',
        Make: '',
        Family: '',
        Year_Group: '',
        Body_Type: '',
        Fuel_Type: '',
        Transmission: '',
        Cylinders: '',
        Vehicle_Level: '',
        recentlyAdded: 'all_time',
        Datium_Values: 'available',
        Ind_NewVehicle: 'all'
      }
      let resetOriObj =
      {
        VFactsGroups: [],
        VFactsGroups_check: false,
        Make: [],
        Make_check: false,
        Family: [],
        Family_check: false,
        Year_Group: [],
        Year_Group_check: false,
        Body_Type: [],
        Body_Type_check: false,
        Fuel_Type: [],
        Fuel_Type_check: false,
        Transmission: [],
        Transmission_check: false,
        Cylinders: [],
        Cylinders_check: false,
        Vehicle_Level: [],
        Vehicle_Level_check: false,
        recentlyAdded: 'all_time',
        Datium_Values: 'available',
        Ind_NewVehicle: 'all'
      }
      if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
        if (resetObj['Datium_Values']) {
          delete resetObj['Datium_Values']
        }

        if (resetOriObj['Datium_Values']) {
          delete resetOriObj['Datium_Values']
        }
      }
      else if (location.pathname.includes('new-vehicles')) {
        if (resetObj['recentlyAdded']) {
          delete resetObj['recentlyAdded']
        }

        if (resetOriObj['recentlyAdded']) {
          delete resetOriObj['recentlyAdded']
        }

        if (resetObj['Ind_NewVehicle']) {
          delete resetObj['Ind_NewVehicle']
        }

        if (resetOriObj['Ind_NewVehicle']) {
          delete resetOriObj['Ind_NewVehicle']
        }
      }
      else {
        if (resetObj['recentlyAdded']) {
          delete resetObj['recentlyAdded']
        }

        if (resetOriObj['recentlyAdded']) {
          delete resetOriObj['recentlyAdded']
        }

        if (resetObj['Datium_Values']) {
          delete resetObj['Datium_Values']
        }

        if (resetOriObj['Datium_Values']) {
          delete resetOriObj['Datium_Values']
        }
      }
      return {
        resetObj, resetOriObj

      }
    }
  }


  processSearchData() {
    let localData = this.commonService.getLocalStorage('vehicle_filters')
    try {
      let selectedFilters = JSON.parse(localData);
      //console.log('selectedFilters', selectedFilters)
      if (selectedFilters == null) {
        let resetObjs = this.getFormResetIniObj()
        let resetObj = resetObjs.resetObj

        let resetOriObj = resetObjs.resetOriObj

        if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
          if (resetObj['Datium_Values']) {
            delete resetObj['Datium_Values']
          }

          if (resetOriObj['Datium_Values']) {
            delete resetOriObj['Datium_Values']
          }
        }
        else if (location.pathname.includes('new-vehicles')) {
          if (resetOriObj['recentlyAdded']) {
            delete resetOriObj['recentlyAdded']
          }
          if (resetObj['recentlyAdded']) {
            delete resetObj['recentlyAdded']
          }

          if (resetOriObj['Ind_NewVehicle']) {
            delete resetOriObj['Ind_NewVehicle']
          }
          if (resetObj['Ind_NewVehicle']) {
            delete resetObj['Ind_NewVehicle']
          }
        }
        else {
          if (resetOriObj['recentlyAdded']) {
            delete resetOriObj['recentlyAdded']
          }
          if (resetObj['recentlyAdded']) {
            delete resetObj['recentlyAdded']
          }

          if (resetObj['Datium_Values']) {
            delete resetObj['Datium_Values']
          }
          if (resetOriObj['Datium_Values']) {
            delete resetOriObj['Datium_Values']
          }
        }

        this.searchForm.setValue(resetOriObj)
        //console.log(this.searchForm.value)
        this.formError = {}
        this.apiParams = resetObj;//this.commonService.getInitialFilterApiParams(resetObj)
        //console.log('apiParams', this.apiParams)
        this.apiParams.filter = 'VFactsGroups'
        this.apiParams.type = 'bi'
        this.searchResponse.emit({ reset: false, submit: true, error: false, data: resetObj, reqType: this.search })
        this.getAllFilterList(false);
        //clear filter initial stored state
        this.commonService.removeLocalStorage('vehicle_filters')
      } else {
        let initData = selectedFilters['data'];
        let VFactsGroups = initData['VFactsGroups'] ? initData['VFactsGroups'] : []
        let Make = initData['Make'] ? initData['Make'] : []
        this.setFormData(selectedFilters['data'], selectedFilters['origData'])
      }
      /*if (selectedFilters == null) {
        this.apiParams.filter = 'VFactsGroups'
        this.apiParams.type = 'bi'
        this.getAllFilterList(false);
        this.searchResponse.emit({ reset: false, submit: false, error: true, data: this.searchForm.value })
        return this.handleServerFormError({
          'VFactsGroups': 'Please choose VFacts Groups.'
        })
      }
      if (selectedFilters['data'] == null) {
        this.apiParams.filter = 'VFactsGroups'
        this.apiParams.type = 'bi'
        this.getAllFilterList(false);
        this.searchResponse.emit({ reset: false, submit: false, error: true, data: this.searchForm.value })
        return this.handleServerFormError({
          'VFactsGroups': 'Please choose VFacts Groups.'
        })
      }
      let initData = selectedFilters['data'];
      let VFactsGroups = initData['VFactsGroups'] ? initData['VFactsGroups'] : []
      let Make = initData['Make'] ? initData['Make'] : []
      if (VFactsGroups && Make) {
        console.log('selection error')
        if (!VFactsGroups) {
          this.apiParams.filter = 'VFactsGroups'
          this.apiParams.type = 'bi'
          this.getAllFilterList(false);
          this.searchResponse.emit({ reset: false, submit: false, error: true, data: this.searchForm.value })
          return this.handleServerFormError({
            'VFactsGroups': 'Please choose VFacts Groups.'
          })
        }
        if (!Make) {
          this.apiParams.filter = 'VFactsGroups'
          this.apiParams.type = 'bi'
          this.getAllFilterList(false);
          this.searchResponse.emit({ reset: false, submit: false, error: true, data: this.searchForm.value })
          return this.handleServerFormError({
            'Make': 'Please choose Make.'
          })
        }
      }
      this.setFormData(selectedFilters['data'], selectedFilters['origData'])*/
      //this.searchResponse.emit({ reset: false, submit: true, error: false, data: this.searchForm.value })
    } catch (e) {
      console.log(e)
      this.searchResponse.emit({ reset: false, submit: false, error: true, data: {}, reqType: this.search })
    }
  }


  setFormData(selectedFilters, origData) {
    //console.log(selectedFilters, origData)
    // selectedFilters - only contain valid selected values in string format
    // origData - Original selected values in array format
    try {
      let resetObjs = this.getFormResetIniObj({ selectedFilters: selectedFilters, origData: origData })
      let resetObj = resetObjs.resetObj

      let resetOriObj = resetObjs.resetOriObj

      //console.log(resetObjs)
      if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
        if (resetObj['Datium_Values']) {
          delete resetObj['Datium_Values']
        }
        if (resetOriObj['Datium_Values']) {
          delete resetOriObj['Datium_Values']
        }
      } else if (location.pathname.includes('new-vehicles')) {
        if (resetOriObj['recentlyAdded']) {
          delete resetOriObj['recentlyAdded']
        }
        if (resetObj['recentlyAdded']) {
          delete resetObj['recentlyAdded']
        }

        if (resetOriObj['Ind_NewVehicle']) {
          delete resetOriObj['Ind_NewVehicle']
        }
        if (resetObj['Ind_NewVehicle']) {
          delete resetObj['Ind_NewVehicle']
        }
      } else {
        if (resetOriObj['recentlyAdded']) {
          delete resetOriObj['recentlyAdded']
        }
        if (resetObj['recentlyAdded']) {
          delete resetObj['recentlyAdded']
        }
        if (resetObj['Datium_Values']) {
          delete resetObj['Datium_Values']
        }
        if (resetOriObj['Datium_Values']) {
          delete resetOriObj['Datium_Values']
        }
      }
      this.searchForm.setValue(resetOriObj)
      //console.log(this.searchForm.value)
      this.formError = {}
      this.apiParams = resetObj;//this.commonService.getInitialFilterApiParams(resetObj)
      //console.log('apiParams', this.apiParams)
      this.apiParams.filter = 'VFactsGroups'
      this.apiParams.type = 'bi'
      //console.log('herte1')
      this.searchResponse.emit({ reset: false, submit: true, error: false, data: resetObj, reqType: this.search })
      this.getAllFilterList(false);
      //console.log('herte2')
      //clear filter initial stored state
      this.commonService.removeLocalStorage('vehicle_filters')
    } catch (e) {
      console.log(e)
    }
  }

  /*
  * @name getAllFilterList
  * @param toggle:boolean
  * @description Get all filter list based on selection
  * @return none
  */
  getAllFilterList(toggle = true) {
    ///console.log(toggle, 'toggle')
    // Cancel previous request
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe()
    }
    this.toggleLoader(toggle ? 'isLoading' : 'isFilter', true);
    if (this.listingType === 'new') {
      this.apiParams['list_type'] = 'new_list';
    } else if (this.listingType === 'old') {
      this.apiParams['list_type'] = 'old_list';
    } else if (this.listingType === 'calculator') {
      this.apiParams['list_type'] = 'calculator';
    } else {
      this.apiParams['list_type'] = '';
    }
    //console.log(this.apiParams, '------this is the api params');
    this.vSubscription = this.vehicleService.getVehicleFilters(this.apiParams, 'post').subscribe(
      (result) => {
        try {
          this.toggleLoader(toggle ? 'isLoading' : 'isFilter', false)
          let response = result.json();
          this.allFilterList = response.data;
        } catch (e) {

        }
      },
      (error) => {
        this.toggleLoader(toggle ? 'isLoading' : 'isFilter', false)
        //console.log(error)
        let response = error.json();
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    )
  }

  /*
  * @name sendFilterSelect
  * @param event:EventEmitter 
  * @description Get the selection data from filter component
  * @return none
  */
  sendFilterSelect(event) {
    //console.log(this.searchForm, this.allFilterList, event)
    this.apiParams = this.commonService.getFilterApiParams(this.searchForm, this.allFilterList, event)
    //console.log(this.apiParams, 'this.apiParams')
    try {
      if (event['filter'] == 'recentlyAdded' || event['filter'] == 'Datium_Values' || event['filter'] == 'Ind_NewVehicle') {

      } else {
        this.getAllFilterList(false)
      }
    } catch (e) {

    }

  }

  /*
  * @name toggleLoader
  * @param loader(toggle variable name),toggle:boolean
  * @description toggle the search loader
  * @return none
  */
  toggleLoader(loader = 'isLoading', toggle = false) {
    // console.log(toggle, loader, '---------------------toggle')
    this[loader] = toggle
    for (var control in this.searchForm.controls) {
      if (toggle == true) {
        this.searchForm.controls[control].disable();
      } else {
        this.searchForm.controls[control].enable();
      }
    }
    if (toggle == true) {
      //this.commonService.showSnakeBar('Fetching filters..')
    }
  }

  /*
  * @name resetFilters
  * @param none
  * @description reset the vehicle filter form and get all filter list
  * @return none
  */
  resetFilters() {
    let resetObj =
    {
      VFactsGroups: [],
      Make: [],
      Family: [],
      Year_Group: [],
      Body_Type: [],
      Fuel_Type: [],
      Transmission: [],
      Cylinders: [],
      Vehicle_Level: [],
      recentlyAdded: 'all_time',
      Datium_Values: 'available',
      Ind_NewVehicle: 'all'
    }

    this.apiParams = {
      filter: 'VFactsGroups',
      VFactsGroups: '',
      Make: '',
      Family: '',
      Year_Group: '',
      Body_Type: '',
      Fuel_Type: '',
      Transmission: '',
      Cylinders: '',
      Vehicle_Level: '',
      recentlyAdded: 'all_time',
      type: 'bi',
      Datium_Values: 'available',
      Ind_NewVehicle: 'all'
    }
    if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
      if (resetObj['Datium_Values']) {
        delete resetObj['Datium_Values']
      }

      if (this.apiParams['Datium_Values']) {
        delete this.apiParams['Datium_Values']
      }
    }
    else if (location.pathname.includes('new-vehicles')) {
      if (resetObj['recentlyAdded']) {
        delete resetObj['recentlyAdded']
      }

      if (this.apiParams['recentlyAdded']) {
        delete this.apiParams['recentlyAdded']
      }

      if (resetObj['Ind_NewVehicle']) {
        delete resetObj['Ind_NewVehicle']
      }

      if (this.apiParams['Ind_NewVehicle']) {
        delete this.apiParams['Ind_NewVehicle']
      }
    }
    else {
      if (resetObj['recentlyAdded']) {
        delete resetObj['recentlyAdded']
      }
      if (this.apiParams['recentlyAdded']) {
        delete this.apiParams['recentlyAdded']
      }
      if (resetObj['Datium_Values']) {
        delete resetObj['Datium_Values']
      }
      if (this.apiParams['Datium_Values']) {
        delete this.apiParams['Datium_Values']
      }
    }
    let resetObjs = this.getFormResetIniObj()

    let resetOriObj = resetObjs.resetOriObj

    this.searchForm.setValue(resetOriObj)
    this.formError = {}
    this.getAllFilterList(false);
    this.searchResponse.emit({ reset: true, submit: false, data: resetObj, reqType: this.search, clearFilter: true })
  }

  /*
  * @name handleSearchForm
  * @param searchFormObj:FormGroup
  * @description handle the search form
  * @return none
  */
  handleSearchForm(searchFormObj: FormGroup) {
    if (searchFormObj.valid) {
      let VFactsGroups = searchFormObj.value.VFactsGroups
      let Make = searchFormObj.value.Make
      //console.log(searchFormObj.value)
      /*if (VFactsGroups.length <= 0 && Make.length <= 0) {
        if (VFactsGroups.length <= 0) {
          this.searchResponse.emit({ reset: false, submit: false, error: true, data: searchFormObj.value })
          return this.handleServerFormError({
            'VFactsGroups': 'Please choose VFacts Groups.'
          })
        }
        if (Make.length) {
          this.searchResponse.emit({ reset: false, submit: false, error: true, data: searchFormObj.value })
          return this.handleServerFormError({
            'Make': 'Please choose Make.'
          })
        }
      }*/
      //const isEmpty = Object.keys(searchFormObj.value).every(x => (searchFormObj.value[x] == null || searchFormObj.value[x] == ''));
      let frmValue = Object.assign({}, searchFormObj.value)
      if (frmValue['recentlyAdded']) {
        delete frmValue['recentlyAdded']
      }
      if (frmValue['Datium_Values']) {
        delete frmValue['Datium_Values']
      }
      if (frmValue['Ind_NewVehicle']) {
        delete frmValue['Ind_NewVehicle']
      }
      const isEmpty = this.commonService.checkAllEmpty(frmValue)
      //console.log(isEmpty, 'isEmpty')
      if (isEmpty) {
        this.handleServerFormError({
          'VFactsGroups': 'Please choose VFactsGroups.'
        })
        this.searchResponse.emit({ reset: false, submit: false, error: true, data: searchFormObj.value, reqType: this.search })
        return false;
      }
      //console.log(searchFormObj.value, this.allFilterList)
      let formData = this.commonService.getInitialFilterApiParams(searchFormObj.value, this.allFilterList)
      //console.log('---------print the formData-----------', formData)
      this.searchResponse.emit({ reset: false, submit: true, error: false, data: formData, origData: searchFormObj.value, reqType: this.search });
    } else {
      this.formValidate(searchFormObj);
    }
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        //console.log(key, this.searchForm.contains(key))
        if (this.searchForm.contains(key)) {
          let control = this.searchForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
          //console.log(this.formError, control)
        }
      });
    }
    //console.log(this.searchForm, this.searchForm.controls.VFactsGroups.invalid)

  }

  /*
  * @name ngOnDestroy
  * @param none
  * @description Free the used resources when component destroyed
  * @return none
  */
  ngOnDestroy() {
    if (this.searchFormValueChanges) {
      this.searchFormValueChanges.unsubscribe()
    }
    if (this.getFilterSelectSignal) {
      this.getFilterSelectSignal.unsubscribe()
    }
  }

}
