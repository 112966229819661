import { Component, OnInit, Input } from '@angular/core';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-vehicle-dt-indexes',
  templateUrl: './vehicle-dt-indexes.component.html',
  styleUrls: ['./vehicle-dt-indexes.component.scss']
})
export class VehicleDtIndexesComponent implements OnInit {

  @Input() vehicle: any
  @Input() type: string
  constructor(public roleCheckService: RoleCheckerService, public commonService: CommonService) { }

  ngOnInit() {
  }


}
