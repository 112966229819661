/*
* @ngdoc Service
* @name app.services.HighChartService
* @description
* This service is used to handle the task related to temporary storing the data as Observable
* */
import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
    providedIn: 'root'
})

export class HighChartService {

    charts;
    defaultOptions = {
        xAxis: {
            categories: ['21 Jan', '22 Jan', '23 Jan', '24 Jan', '25 Jan', '26 Jan']
        },
        title: {
            text: 'Frequency Progress Chart'
        },
        subtitle: {
            text: ''
        },
        yAxis: {
            title: {
                text: 'Frequency'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                events: {
                    click: (event) => {
                        this.chartLabel({ a: event.point.options.y });
                    }
                }
            }
        },
        series: [{
            name: 'Frequency',
            data: [0, { y: 216.4, name: "AVG:34" }, 106.4, 129.2, 144.0, 176.0],
            color: '#2ea97b'
        }],
        credits: {
            enabled: false
        }
    }

    constructor() {

    }

    createChart(container, options?: Object) {
        //console.log(container, options);
        let opts: any = !!options ? options : this.defaultOptions;
        let e = document.createElement("div");
        container.appendChild(e);

        if (!!opts.chart) {
            opts.chart['renderTo'] = e;
        }
        else {
            opts.chart = {
                'renderTo': e
            }
        }

        if (this.charts && this.charts.xAxis) {
            this.charts.destroy();
            this.charts = new Highcharts.Chart(opts);
        }
        else {
            this.charts = new Highcharts.Chart(opts);
        }

        if (!(this.charts.series[0].data && this.charts.series[0].data.length)) {
            this.charts.renderer.text('No Data Available', 140, 120)
                .css({
                    color: '#4572A7',
                    fontSize: '16px'
                })
                .add();
        }
    }

    removeFirst() {
        this.charts.shift();
    }

    removeLast() {
        this.charts.pop();
    }

    getChartInstances(): number {
        return this.charts.length;
    }

    getCharts() {
        return this.charts;
    }

    chartLabel(label) {

        if (this.charts[0].lbl) {
            this.charts[0].lbl.destroy();
        }
        this.charts[0].lbl = this.charts[0].renderer.label('Avg Value ' + ' points ' + label.a, 100, 60)
            .attr({
                padding: 10,
                r: 5,
                fill: Highcharts.getOptions().colors[1],
                zIndex: 5
            })
            .css({
                color: 'white'
            })
            .add();

        setTimeout(() => {
            if (this.charts[0].lbl) {
                this.charts[0].lbl.element.remove();
            }
        }, 2000);
    }
}
