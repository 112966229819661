/*
* @ngdoc Component
* @name app.linking-tree.LinkingWrapperComponent
* @description
* This component is used to manage the linking tree screen
* */

import { Component, OnInit, OnDestroy, Input, OnChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { Subscription } from 'rxjs';
import { DataStoreService } from '../../../services/data-store.service';
import { VehicleLinkageComponent } from '../../../shared/vehicle-linkage/vehicle-linkage.component';
import { MatDialog } from '@angular/material';
import { GaEventsService } from '../../../services/ga-events.service';

@Component({
  selector: 'linking-wrapper',
  templateUrl: './linking-wrapper.component.html',
  styleUrls: ['./linking-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkingWrapperComponent implements OnInit, OnDestroy, OnChanges {

  @Input() isAdmin: boolean;
  @Input() routeName: string;
  @Input() filterState: any;
  @Input() resetAndSearch: Boolean;
  @Output() listLoaded = new EventEmitter();
  @Output() listState = new EventEmitter();
  @Output() sendError = new EventEmitter();
  notFoundHeading = 'Hum.. Looks like not record(s) found for this filter'
  notFoundDescription = 'Please update your filter above.'

  isLoadingList: boolean = true;
  isPaging: boolean = false;
  vehicleList = [];

  vSubscription: Subscription
  paramsSubscription: Subscription
  requestSubscription: Subscription
  checkResetVehicleList: Subscription
  checkLoadingVehicleList: Subscription
  getSelectedFilters: Subscription

  resetRVHistorySubscription: Subscription
  total = 0
  page: any = 1
  limit = 10;
  selectedFilter = {}
  sort = 'asc'
  sortBy = 'new_price'
  throttle = 300;
  scrollDistance = 0.5;
  scrollUpDistance = 1;
  direction = 'down';
  disableScroll = true;
  noMoreRec = false;

  preventClose = null
  fetchingDetails = null;
  currentTotal: number = 0;
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private vehicleService: VehicleService,
    private dataStore: DataStoreService,
    private gaEvent: GaEventsService
  ) {
    //console.log('-------------subscrive')
    // router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationStart) {
    //     //console.log('NavigationStart')
    //     if (!location.pathname.includes('linking-tree')) {
    //       //console.log('vSubscription')
    //       if (this.vSubscription != null) {
    //         this.vSubscription.unsubscribe();
    //         //console.log('vSubscription123')
    //       }
    //     }
    //   }

    //   if (event instanceof NavigationEnd) {
    //     console.log('-----------------------NavigationEnd', event)
    //     this.getSelectedFilters = this.dataStore.getSelectedFilters().subscribe(data => {
    //       ///console.log('vehicle listing', data)
    //       this.selectedFilter = data.filters;
    //     })

    //     this.paramsSubscription = this.activatedRoute
    //       .queryParams
    //       .subscribe(params => {
    //         let sort = params['sort'] || "";
    //         if (sort != 'asc' && sort != 'desc') {
    //           this.sort = 'asc'
    //         } else {
    //           this.sort = sort
    //         }
    //         let sortBy = params['sortBy'] || "";
    //         if (sortBy != 'new_price' && sortBy != 'units') {
    //           this.sortBy = 'new_price'
    //         } else {
    //           this.sortBy = sortBy
    //         }

    //         // on page load get vehicle listing
    //         //this.getVehicleList();
    //       });

    //     this.checkLoadingVehicleList = this.dataStore.checkLoadingVehicleList().subscribe(data => {
    //       this.page = 1
    //       this.limit = 10;
    //       //console.log('loading list initially')
    //       if (data['type'] == 'linking-tree') {
    //         this.getVehicleList();
    //       }
    //     })

    //     this.checkResetVehicleList = this.dataStore.checkResetVehicleList().subscribe(data => {
    //       this.resetPaginationRecords();
    //     })
    //     this.paramsSubscription.unsubscribe()
    //   }
    // });

    // // reset vehicle listing array because user click on search button
    // if (this.resetAndSearch == true) {
    //   // cancel exiting request
    //   if (this.vSubscription != null) {
    //     this.vSubscription.unsubscribe();
    //   }
    //   this.page = 1
    //   this.limit = 10;
    //   this.vehicleList = []
    //   this.disableScroll = true;
    //   this.getVehicleList()
    // }

    this.resetRVHistorySubscription = this.dataStore.checkVehicleDetailsReload().subscribe((data) => {
      console.log('constructor', data)
      if (data['cancel'] == true && data['reset'] == true && data['DatiumAssetKey']) {
        this.getLinkedVehicleDetails(data['DatiumAssetKey']);
      }
    });

    // this.resetRVHistorySubscription = this.dataStore.checkVehicleDetailsReload().subscribe((data) => {
    //   //console.log(data, 'data')
    //   if (data['cancel'] == true && data['reset'] == true && data['DatiumAssetKey']) {
    //     this.getVehicleDetails(data['DatiumAssetKey']);
    //   }
    // });


  }

  /*
  * @name getLinkedVehicleDetails
  * @param params
  * @description get linked vehicle details
  * @return none
  */
  getLinkedVehicleDetails(DatiumAssetKey) {
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.fetchingDetails = DatiumAssetKey;
    this.commonService.showSnakeBar('Please wait while we are fetching the latest changes.');
    this.vSubscription = this.vehicleService.getLinkingTreeVehicleDetail(DatiumAssetKey).subscribe(
      (result) => {
        try {
          this.fetchingDetails = null;
          let response = result.json();
          let vehicleRes = response.data;
          if (vehicleRes) {
            const indexDt = this.vehicleList.findIndex(obj => obj.vehicle.DatiumAssetKey == DatiumAssetKey)
            console.log(indexDt, 'indexDt')
            if (indexDt != -1) {
              console.log('vehicle', vehicleRes)
              this.preventClose = DatiumAssetKey;
              this.vehicleList[indexDt] = vehicleRes;
            }
          }
        } catch (e) {

        }
      },
      (error) => {
        this.fetchingDetails = null;
        try {
          let response = error.json();
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) { }
      },
      () => {
        this.commonService.showSnakeBar('Data has reloaded successfully.');
      }
    )
  }

  ngOnChanges(changes) {
    // Reset vehicle listing array because user click on search button
    //console.log('changes.resetAndSearch', changes)
    if (changes.filterState) {
      let filterStateChanges = changes.filterState;
      //console.log(filterStateChanges, 'filterStateChanges')
      if (filterStateChanges.firstChange == false) {
        let filterVal = filterStateChanges.currentValue
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
        }
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please correct the errors.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
        }
      } else {
        let filterVal = filterStateChanges.currentValue
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.isLoadingList = false;
        }
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
      }
    }
    /*if (changes.filterState.currentValue === true) {
      // cancel exiting request
      if (this.vSubscription != null) {
        // console.log('unsubscribe')
        this.vSubscription.unsubscribe();
      }
      this.vehicleList = []
      //this.disableScroll = true;
      this.page = 1
      this.limit = 10;
      console.log(this.page, 'page')
      this.getVehicleList()
    } else {
      ///this.isLoadingList = false;
    }*/
  }

  ngOnInit() {

  }

  /*
  * @name resetPaginationRecords
  * @param None
  * @description reset pagination, records and selected filter
  * @return none
  */
  resetPaginationRecords() {
    this.page = 1
    this.limit = 10
    this.total = 0
    this.currentTotal = 0
    this.selectedFilter = {}
    this.vehicleList = [];
    this.sort = 'asc'
    this.sortBy = 'new_price'
    //this.disableScroll = true;
    this.noMoreRec = false;
    //reset loaders
    this.isLoadingList = false;
    this.isPaging = false;
  }

  toggleLoader(show = false, paging = false) {
    if (paging == false) {
      this.isLoadingList = show
    } else {
      this.isPaging = show;
    }
  }

  /*
  * @name getVehicleList
  * @param None
  * @description Api call for fetching the vehicle list
  * @return none
  */
  getVehicleList(paging = false) {
    //this.disableScroll = false;
    this.toggleLoader(true, paging)
    let apiParams = {
      page: this.page,
      limit: this.limit,
      sort: this.sort,
      sortBy: this.sortBy
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    //console.log('listing params', apiParams)
    /*setTimeout(() => {
      if (this.routeName) {
        this.router.navigate([this.routeName], { relativeTo: this.route, queryParams: apiParams, replaceUrl: true })
      }
    })*/
    //this.commonService.sendUserTo('vehicle-list', apiParams)
    // cancel previous request
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.vSubscription = this.vehicleService.getLinkedTreeVehicleListing(apiParams).subscribe(
      (result) => {
        try {
          let response = result.json();
          let vehicleData = response.data;
          if (this.page == 1) {
            this.total = vehicleData.total
          }
          this.currentTotal = vehicleData.total
          this.limit = vehicleData.limit
          this.page = vehicleData.page
          //console.log(this.vehicleList.length)
          if (this.currentTotal == 0 && paging === true) {
            this.noMoreRec = true;
          } else {
            this.noMoreRec = false;
          }
          if (vehicleData.records.length > 0) {
            this.appendVehicleListing(vehicleData.records)
            //this.vehicleList = vehicleData.records
          } else {
            if (this.page == 1) {
              this.notFoundHeading = 'Hum.. Looks like no record(s) found for this filter'
              this.notFoundDescription = 'Please update your filter above.'
            }
          }
          // let's know the wrapper parent component, that list is loaded successfully
          this.listLoaded.emit(true)
          ///console.log(this.vehicleList.length)
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error.json();
        this.toggleLoader(false, paging)
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.listLoaded.emit(false)
        if (this.page != 1) {
          this.page -= 1
        }
      },
      () => {
        this.toggleLoader(false, paging)
      }
    )
  }


  /*
  * @name onScroll
  * @param newList:None
  * @description paginate on scroll
  * @return none
  */
  onScroll() {
    /*if (this.disableScroll === true) {
      return false;
    }*/
    if (this.isLoadingList === true || this.isPaging === true) {
      return false;
    }
    let page = parseInt(this.page);
    this.page = page + 1;
    if (this.total > 10) {
      this.getVehicleList(true)
    }
  }

  /*
  * @name orderBy
  * @param None
  * @description handle order by records                   
  * @return none
  */
  orderBy(sortBy = 'new_price', sort = 'asc') {
    this.resetPaginationRecords()
    if (sortBy != 'new_price' && sortBy != 'units') {
      this.sortBy = 'new_price'
    } else {
      this.sortBy = sortBy
    }
    if (sort != 'asc' && sort != 'desc') {
      this.sort = 'asc'
    } else {
      this.sort = sort
    }

    //this.page = 1
    //this.limit = 10;
    this.getVehicleList();
  }

  /*
  * @name appendVehicleListing
  * @param newList:Array
  * @description paginate over vehicle listing                   
  * @return none
  */
  appendVehicleListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.vehicleList.length > 0) {
        newState = this.vehicleList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.vehicleList = newState
    }

  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      this.sendError.emit(errors)
    } else {
      this.sendError.emit({})
    }
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.checkLoadingVehicleList) {
      this.checkLoadingVehicleList.unsubscribe()
    }
    if (this.checkResetVehicleList) {
      this.checkResetVehicleList.unsubscribe()
    }
    if (this.getSelectedFilters) {
      this.getSelectedFilters.unsubscribe()
    }

    if (this.resetRVHistorySubscription) {
      this.resetRVHistorySubscription.unsubscribe()
    }
  }


  vehicleLinkage(child, parent): void {
    let gaData = {
      'event': 'linkingTreeDetails',
      'value': `${child.DatiumAssetKey}~${parent.DatiumAssetKey}`
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    const dialogRef = this.dialog.open(VehicleLinkageComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        childV: child,
        parentV: parent
      }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

}

