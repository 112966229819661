import { Component, OnInit, ViewChild } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DataStoreService } from 'src/app/services/data-store.service';
import { MatPaginator, MatSort } from '@angular/material';
import { EditProductComponent } from '../edit-product/edit-product.component';


@Component({
  selector: 'app-product-offer',
  templateUrl: './product-offer.component.html',
  styleUrls: ['./product-offer.component.scss']
})
export class ProductOfferComponent implements OnInit {

  displayedColumns: string[] = ['Name', 'Products', 'OfferType', 'action'];
  isLoading: boolean = false;
  listData = []

  vSubscription: Subscription;
  vFactListing: Subscription;
  delBRLoader: number = null
  delDLoader: number = null
  delRELoader: number = null
  makArray: Array<string> = []
  permArray: Array<any> = []
  ProductOffers: Array<any> = []
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    public dataStore: DataStoreService,
    public roleCheckService: RoleCheckerService,
    public permissionService: PermissionsService) {

  }

  ngOnInit() {
    this.commonService.setTitle('Products');
    this.getProductOfferListing()
  }

  /*
  * @name getProductOfferListing
  * @param none
  * @description get the listing of products
  * @return none
  */
  getProductOfferListing() {
    this.isLoading = true
    //let type = 'BusinessRisk';
    this.vFactListing = this.permissionService.getProductOfferList().subscribe(
      (result) => {
        this.isLoading = false
        try {
          let response = result.json();
          this.listData = response.data.products;
          this.makArray = response.data.makes;
          this.permArray = response.data.permissions;
          this.ProductOffers = response.data.ProductOffers
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        this.isLoading = false;
        let response = error.json();

        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isLoading = false
      },
      () => {

      }
    )
  }

  /*
 * @name updateProducts
 * @param none
 * @description Update new (Deflation, BusinessRisk and Remarketing Efficiency)
 * @return none
 */
  updateProducts(object) {
    const dialogRef = this.dialog.open(EditProductComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: true,
        editProduct: object,
        makes: this.makArray,
        permissions: this.permArray,
        ProductOffers: this.ProductOffers
      },
      disableClose: true
    });

    dialogRef.componentInstance.onUpdateProduct.subscribe(res => {
      this.getProductOfferListing()
    })
  }


  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }
  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.vFactListing) {
      this.vFactListing.unsubscribe()
    }
  }
}