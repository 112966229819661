import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {

  helpTopics: Array<any> = [
    {
      ques: "What is the purpose of AutoPredict?",
      ans: "To aid any user in determining the future value of a motor vehicle in Australia."
    },
    {
      ques: "What is a Residual Value (RV)?",
      ans: "Residual Value in AutoPredict refers to the predicted future value of a car in Australia."
    },
    {
      ques: "What type of data is fed into AutoPredict?",
      ans: "Currently, all the used car sales data fed into AutoPredict and shown in our interactive charts are Auction sales. The RV's produced by AutoPredict use only Auction sales and are reflective of this Auction market."
    },
    {
      ques: "Where does AutoPredict get its data from?",
      ans: "AutoPredict sources data from a number of parties, however the majority of data comes from Pickles Auctions, one of Australia's largest auctioneers of used motor vehicles."
    },
    {
      ques: "How does AutoPredict calculate RV's?",
      ans: "We use a proprietary statistical model that analyses millions of historical used car sales to identify and predict continuing trends and patterns within the Australian marketplace."
    },
    {
      ques: "How good are the RV's in AutoPredict?",
      ans: "While predicting the future is always tough, we believe our RV's are the best available in the market. Nevertheless, through our interactive charts that show 5 years worth of historical sales data we arm you with the ability to judge for yourself how closely we reflect the market."
    },
    {
      ques: "Can I get an RV for a specific term and kilometer?",
      ans: "Yes, you can click on the calculator button next to the vehicle description which will open the RV calculator. Here you can enter in any term or kilometer you like and the RV will automatically adjust."
    },
    {
      ques: "What does the column 'Units' represent?",
      ans: "The value in the column 'Units' is the number of used vehicle sale records we have in our database where those vehicles have sold in the latest 5 year period."
    },
    {
      ques: "In the search options, what does 'Vehicle Level' mean?",
      ans: "The 'Vehicle Level' drop-down has two options to select from: Base and Non-Base. Selecting base will ensure only the base variant within a model range is included in the search query. Selecting 'Non-Base' will restrict the query to all other variants within a model range. For example, within the Toyota Camry range there are a number of variants including the Ascent, Ascent Sport, SX and SL. However by selecting 'Base' this will only include the Ascent variant in the search query as the Ascent is the base variant within the Toyota Camry model range. Using the 'Vehicle Level' drop down can help to restrict your search to base variants for a number of different models, which makes comparing much easier."
    },
    {
      ques: "In the search options, what does 'Recently added on' mean?",
      ans: "This option allows you to restrict your search to vehicles that entered the database over a specific time period. New vehicles are released by Manufacturers into the market on a regular basis, this option allows you to restrict your search to those new vehicles."
    },
    {
      ques: "Can I subscribe to AutoPredict for just 1 month?",
      ans: "Yes! You can subscribe for 1 month, or 3 or 10, it's completely up to you. Our platform is designed for jump-in and jump-out use, so you only use it when you need it. All data, including adjustments and linkings, are stored against your account and will be visible the next time your subscription starts."
    },
    {
      ques: "How can I change my subscription?",
      ans: 'Please contact us using the Contact form in the help section and include a description of your change request.'
    }
  ]
  constructor() { }

  ngOnInit() {
    //console.log(this.helpTopics, this.helpTopics[0], this.helpTopics[0].ques)
  }

}
