import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialComponentsModule } from './../material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ApCommonModule } from '../ap-common/ap-common.module';

// Vehicle filters
import { VfactsGroupFilterComponent } from './filters/vfacts-group-filter/vfacts-group-filter.component';
import { VehicleMakeFilterComponent } from './filters/vehicle-make-filter/vehicle-make-filter.component';
import { VehicleFamilyFilterComponent } from './filters/vehicle-family-filter/vehicle-family-filter.component';
import { FuelTypeFilterComponent } from './filters/fuel-type-filter/fuel-type-filter.component';
import { VehicleTransmissionFilterComponent } from './filters/vehicle-transmission-filter/vehicle-transmission-filter.component';
import { CylindersFilterComponent } from './filters/cylinders-filter/cylinders-filter.component';
import { VehicleBriefComponent } from './vehicle-brief/vehicle-brief.component';
import { BodyTypeFilterComponent } from './filters/body-type-filter/body-type-filter.component';
import { VehicleLevelFilterComponent } from './filters/vehicle-level-filter/vehicle-level-filter.component';
import { VehicleChartComponent } from "./../vehicle/vehicle-list/vehicle-chart/vehicle-chart.component";

// Calculator
import { RvCalculatorComponent } from './calculator/rv-calculator/rv-calculator.component';
import { RvValuesComponent } from './calculator/rv-values/rv-values.component';

// Link vehicle
import { LinkVehicleComponent } from './link-vehicle/link-vehicle.component';
import { LinkVehicleDetailComponent } from '../vehicle/vehicle-list/link-vehicle-detail/link-vehicle-detail.component';

// Page heading Component
import { PageTitleComponent } from './page-title/page-title.component';

import { AddRvValuesComponent } from './add-rv-values/add-rv-values.component';
import { ManageRecommendationsComponent } from './manage-recommendations/manage-recommendations.component';
import { VehicleLinkageComponent } from './vehicle-linkage/vehicle-linkage.component';
import { ChangeLinkingComponent } from './change-linking/change-linking.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';


import { TextFormatComponent } from './text-format/text-format.component';
import { NoRecordFoundComponent } from './no-record-found/no-record-found.component';
import { SmallLoaderComponent } from './small-loader/small-loader.component';
import { LoaderComponent } from './loader/loader.component';

//Charts
//import { HighchartsChartComponent } from 'highcharts-angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { VehicleListLoadingComponent } from '../vehicle/vehicle-list/vehicle-list-loading/vehicle-list-loading.component';
import { SelectCheckAllComponent } from './filters/select-check-all/select-check-all.component';
import { VehicleFilterComponent } from "./filters/vehicle-filter/vehicle-filter.component";
import { VehicleFilterSearchComponent } from './filters/vehicle-filter-search/vehicle-filter-search.component';
import { VeLoaderComponent } from './ve-loader/ve-loader.component';
import { VehicleDtIndexesComponent } from './vehicle/vehicle-dt-indexes/vehicle-dt-indexes.component';
import { VehicleSortIconComponent } from './vehicle-sort-icon/vehicle-sort-icon.component';
import { PopupLoaderComponent } from './popup-loader/popup-loader.component';
import { NumberDirective } from './directives/numbers-only.directive';
import { AddBasketComponent } from './add-basket/add-basket.component';
import { AddBasketVehicleComponent } from './add-basket-vehicle/add-basket-vehicle.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { QuickSearchModalComponent } from './quick-search-modal/quick-search-modal.component';
//import { SvgIconComponent } from './svg-icon/svg-icon.component';


import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QuickSearchVehicleDetailsComponent } from './quick-search-vehicle-details/quick-search-vehicle-details.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { ExportListsComponent } from './export-lists/export-lists.component';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    RouterModule,
    ApCommonModule,
    AutocompleteLibModule,
    ClipboardModule
  ],
  declarations: [
    PageTitleComponent,
    VfactsGroupFilterComponent,
    VehicleMakeFilterComponent,
    VehicleFamilyFilterComponent,
    VehicleFamilyFilterComponent,
    FuelTypeFilterComponent,
    VehicleTransmissionFilterComponent,
    CylindersFilterComponent,
    VehicleBriefComponent,
    VehicleLevelFilterComponent,
    BodyTypeFilterComponent,
    RvCalculatorComponent,
    RvValuesComponent,
    LinkVehicleComponent,
    LinkVehicleDetailComponent,
    VehicleChartComponent,
    AddRvValuesComponent,
    ManageRecommendationsComponent,
    VehicleLinkageComponent,
    ChangeLinkingComponent,
    ConfirmationPopupComponent,
    PopupLoaderComponent,
    TextFormatComponent,
    NoRecordFoundComponent,
    SmallLoaderComponent,
    LoaderComponent,
    VehicleListLoadingComponent,
    SelectCheckAllComponent,
    VehicleFilterComponent,
    VehicleFilterSearchComponent,
    VeLoaderComponent,
    VehicleDtIndexesComponent,
    VehicleSortIconComponent,
    //SvgIconComponent,
    NumberDirective,
    AddBasketComponent,
    AddBasketVehicleComponent,
    QuickSearchModalComponent,
    QuickSearchVehicleDetailsComponent,
    OutsideClickDirective,
    ExportListsComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PageTitleComponent,
    VfactsGroupFilterComponent,
    VehicleMakeFilterComponent,
    VehicleFamilyFilterComponent,
    VehicleFamilyFilterComponent,
    FuelTypeFilterComponent,
    VehicleTransmissionFilterComponent,
    CylindersFilterComponent,
    VehicleBriefComponent,
    VehicleLevelFilterComponent,
    BodyTypeFilterComponent,
    RvCalculatorComponent,
    RvValuesComponent,
    LinkVehicleComponent,
    LinkVehicleDetailComponent,
    VehicleChartComponent,
    DatePipe,
    AddRvValuesComponent,
    TextFormatComponent,
    NoRecordFoundComponent,
    SmallLoaderComponent,
    LoaderComponent,
    VehicleListLoadingComponent,
    HighchartsChartModule,
    VehicleFilterComponent,
    VehicleFilterSearchComponent,
    VeLoaderComponent,
    VehicleDtIndexesComponent,
    VehicleSortIconComponent,
    //SvgIconComponent,
    NumberDirective,
    QuickSearchModalComponent,
    OutsideClickDirective,
    ExportListsComponent
  ],
  entryComponents: [
    RvCalculatorComponent,
    RvValuesComponent,
    LinkVehicleComponent,
    AddRvValuesComponent,
    ManageRecommendationsComponent,
    VehicleLinkageComponent,
    ChangeLinkingComponent,
    ConfirmationPopupComponent,
    PopupLoaderComponent,
    AddBasketComponent,
    AddBasketVehicleComponent,
    QuickSearchModalComponent
  ],
  providers: [
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class SharedModule { }
