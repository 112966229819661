/*
* @ngdoc Component
* @name app.vehicle.BiVehicleListComponent
* @description
* This component is used to manage the vehicle listing with bi directional filter
* */

import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ViewEncapsulation } from '@angular/core';
import { DataStoreService } from '../../services/data-store.service';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { VehicleService } from 'src/app/services/vehicle.service';
import { FormGroup } from '@angular/forms';
import { IfStmt } from '@angular/compiler';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

enum reqType {
  search = 'search',
  download = 'download'
};

@Component({
  selector: 'app-bi-vehicle-list',
  templateUrl: './bi-vehicle-list.component.html',
  styleUrls: ['./bi-vehicle-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BiVehicleListComponent implements OnInit, OnChanges {

  @Input() filterState: any;
  @Input() vList = [];
  @Input() isBasket: boolean = false;
  @Input() type: string;
  @Output() listState = new EventEmitter();
  @Output() resetTotalSelection = new EventEmitter();
  @Output() resetSelection = new EventEmitter();
  @Output() totalRecords = new EventEmitter();
  @Output() exportFinishEvent = new EventEmitter();
  @Output() searchFinishEvent = new EventEmitter();
  public isExporting: boolean = false;
  public isSelectAll: boolean = false;
  @Input() selectAllCheckbox: boolean = false;
  @Output() selectAllEvent = new EventEmitter();
  @Input() routeName: string;
  notFoundHeading = 'Hum.. Looks like not record(s) found for this filter'
  notFoundDescription = 'Please update your filter above.'
  isLoadingList: boolean = true;
  isPaging: boolean = false;
  vehicleList = [];

  vSubscription: Subscription
  vSubscriptionDel: Subscription
  getSelectedFilters: Subscription

  resetRVHistorySubscription: Subscription
  rejectVehicleSub: Subscription
  acceptVehicleSub: Subscription
  getSelectedVehicleSignal: Subscription
  capturedVehicleSubscription: Subscription

  total = 0
  currentTotal = 0
  page: any = 1
  limit = 10;
  selectedFilter = {}
  sort = 'desc'
  sortBy = 'units'
  throttle = 300;
  scrollDistance = 0.5;
  scrollUpDistance = 1;
  direction = 'down';
  disableScroll = true;
  noMoreRec = false;
  exportRecordsLimit: number = 200;
  isSuperAdmin: boolean = false;

  preventClose = null
  preventToggle = null
  newScreen = null
  getAcceptedVehicle: Subscription
  loaderDat = null;
  fromBasket: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute, private commonService: CommonService, private vehicleService: VehicleService, private dataStore: DataStoreService, private activatedRoute: ActivatedRoute,
    private roleCheckService: RoleCheckerService) {
    if (this.roleCheckService.isSuperAdmin()) {
      this.isSuperAdmin = true;
    }
    this.resetRVHistorySubscription = this.dataStore.checkVehicleDetailsReload().subscribe((data) => {
      //console.log(data, 'data')
      if (data['cancel'] == true && data['reset'] == true && data['DatiumAssetKey']) {
        this.getVehicleDetails(data['DatiumAssetKey'], data['newScreen']);
      }
    });

    this.getAcceptedVehicle = this.dataStore.checkAcceptVehicle().subscribe(data => {
      //console.log(data, 'data')
      if (data['accept'] == 1) {
        this.acceptVehicle(data['DatiumAssetKey'])
      } else if (data['accept'] == 2) {
        this.rejectVehicle(data['DatiumAssetKey'])
      }
    })

    this.getSelectedVehicleSignal = this.dataStore.getSelectedNewVehicles().subscribe(data => {
      let vList = data['data'];
      let flag = data['flag'];
      if (vList.length > 0) {
        // this.vehicleList.forEach(obj => {
        //   const index = vList.findIndex(DatiumAssetKey => obj.DatiumAssetKey == DatiumAssetKey);
        //   if (index != -1) {
        //     this.vehicleList[index].accepted = flag;
        //   }
        // })
        vList.forEach(DatiumAssetKey => {
          let index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey)
          //console.log('DatiumAssetKey', DatiumAssetKey)
          //console.log('index', index)
          if (index != -1) {
            //console.log('vehicle', this.vehicleList[index])
            this.vehicleList[index].accepted = (flag == 1) ? 1 : 2;
          }
        });
      }
    })

    this.capturedVehicleSubscription = this.dataStore.getCapturedVehicle().subscribe((DatiumAssetKey) => {
      console.log(DatiumAssetKey, 'DatiumAssetKey')
      if (DatiumAssetKey) {
        this.captureVehicleRV(DatiumAssetKey);
      }
    });


    if (location.href.includes('basket')) {
      this.fromBasket = true;
    }
  }

  getVehicleValues(DatiumAssetKey) {
    try {
      let index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey)
      if (index != -1) {
        if ('rvValues' in this.vehicleList[index]) {
          let rvValues = this.vehicleList[index]['rvValues']
          let reObj = {
            rv_per_12: null,
            rv_per_24: null,
            rv_per_36: null,
            rv_per_48: null,
            rv_per_60: null,
            rv_dollar_12: null,
            rv_dollar_24: null,
            rv_dollar_36: null,
            rv_dollar_48: null,
            rv_dollar_60: null
          }
          if ('percentageValue' in rvValues) {
            reObj.rv_per_12 = parseFloat(rvValues['percentageValue']['12_30000'].formatted)
            reObj.rv_per_24 = parseFloat(rvValues['percentageValue']['24_60000'].formatted)
            reObj.rv_per_36 = parseFloat(rvValues['percentageValue']['36_90000'].formatted)
            reObj.rv_per_48 = parseFloat(rvValues['percentageValue']['48_120000'].formatted)
            reObj.rv_per_60 = parseFloat(rvValues['percentageValue']['60_150000'].formatted)
            if (Number.isInteger(reObj.rv_per_12)) {
              reObj.rv_per_12 = reObj.rv_per_12 * 1.00;
            }
            if (Number.isInteger(reObj.rv_per_24)) {
              reObj.rv_per_24 = reObj.rv_per_24 * 1.00;
            }
            if (Number.isInteger(reObj.rv_per_36)) {
              reObj.rv_per_36 = reObj.rv_per_36 * 1.00;
            }
            if (Number.isInteger(reObj.rv_per_48)) {
              reObj.rv_per_48 = reObj.rv_per_48 * 1.00;
            }
            if (Number.isInteger(reObj.rv_per_60)) {
              reObj.rv_per_60 = reObj.rv_per_60 * 1.00;
            }
          }
          if ('dollarValue' in rvValues) {
            reObj.rv_dollar_12 = parseInt(rvValues['dollarValue']['12_30000'].formatted.replace(",", ""))
            reObj.rv_dollar_24 = parseInt(rvValues['dollarValue']['24_60000'].formatted.replace(",", ""))
            reObj.rv_dollar_36 = parseInt(rvValues['dollarValue']['36_90000'].formatted.replace(",", ""))
            reObj.rv_dollar_48 = parseInt(rvValues['dollarValue']['48_120000'].formatted.replace(",", ""))
            reObj.rv_dollar_60 = parseInt(rvValues['dollarValue']['60_150000'].formatted.replace(",", ""))
          }
          return reObj
        }
      }
    } catch (error) {
      console.log(error)
      this.commonService.showSnakeBar('An error occurred while capturing RV. Please try again later');
    }
    return false;
  }

  captureVehicleRV(DatiumAssetKey) {
    let vData = this.getVehicleValues(DatiumAssetKey)
    if (vData == false) {
      return false;
    }
    this.dataStore.setVeLoader(DatiumAssetKey, true, "Capturing RV...")
    this.vehicleService.captureVehicleRV(DatiumAssetKey, 'rv', vData).subscribe(
      (result) => {
        this.commonService.showSnakeBar('RV has been captured successfully.');
        try {
          this.dataStore.setVeLoader(DatiumAssetKey, false)
        } catch (e) {

        }
      },
      (error) => {
        this.dataStore.setVeLoader(DatiumAssetKey, false)
        //this.loaderKey = ""
        try {
          let response = error.json();
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) {

        }
      },
      () => {
        //this.commonService.showSnakeBar('RV has been captured successfully.');
      }
    )
  }

  /*
  * @name acceptVehicle
  * @param None
  * @description accepting the vehicle
  * @return none
  */
  acceptVehicle(DatiumAssetKey) {
    if (this.loaderDat) {
      this.commonService.showSnakeBar('Please wait.. a request is already in progress');
      return false
    }
    this.loaderDat = DatiumAssetKey
    this.dataStore.setVeLoader(DatiumAssetKey, true, "Accepting the vehicle...")
    this.acceptVehicleSub = this.vehicleService.acceptVehicle(DatiumAssetKey).subscribe(
      (result) => {
        try {
          this.dataStore.setVeLoader(DatiumAssetKey, false)
          let response = result.json();
          //console.log(response, 'response')
          const index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey);
          if (index != -1) {
            this.vehicleList[index].accepted = 1;
          }
          this.commonService.showSnakeBar(response.message);
          this.resetTotalSelection.emit({ DatiumAssetKey: DatiumAssetKey, accepted: 1 })
          //this.resetTotalSelection.emit(DatiumAssetKey)
          //this.dataStore.resetVehicleDetailsReload(DatiumAssetKey, true, true, true)
          this.getVehicleDetails(DatiumAssetKey, true)
        } catch (e) {

        }
        this.loaderDat = null
      },
      (error) => {
        //console.log(error)
        this.dataStore.setVeLoader(DatiumAssetKey, false)
        try {
          let response = error.json();
          //this.isLoading = false;
          if (response.message) {
            this.handleServerFormError(response.errors)
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.loaderDat = null
      },
      () => {
        //this.isLoading = false;
      }
    )
  }

  /*
  * @name rejectVehicle
  * @param None
  * @description rejecting the vehicle
  * @return none
  */
  rejectVehicle(DatiumAssetKey) {
    if (this.loaderDat) {
      this.commonService.showSnakeBar('Please wait.. a request is already in progress');
      return false
    }
    this.loaderDat = DatiumAssetKey
    this.dataStore.setVeLoader(DatiumAssetKey, true, "Rejecting the vehicle...")
    this.rejectVehicleSub = this.vehicleService.rejectVehicle(DatiumAssetKey).subscribe(
      (result) => {
        this.dataStore.setVeLoader(DatiumAssetKey, false)
        try {
          let response = result.json();
          //console.log(response, 'response')
          const index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey);
          if (index != -1) {
            this.vehicleList[index].accepted = 2;
          }
          this.commonService.showSnakeBar(response.message);
          this.resetTotalSelection.emit({ DatiumAssetKey: DatiumAssetKey, accepted: 2 })
        } catch (e) {

        }
        this.loaderDat = null
      },
      (error) => {
        this.dataStore.setVeLoader(DatiumAssetKey, false)
        //console.log(error)
        try {
          let response = error.json();
          //this.isLoading = false;
          if (response.message) {
            this.handleServerFormError(response.errors)
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.loaderDat = null
      },
      () => {
        //this.isLoading = false;
      }
    )
  }

  /*
  * @name getVehicleDetails
  * @param DatiumAssetKey:String,newScreen:boolean
  * @description Get vehicle details
  * @return none
  */
  getVehicleDetails(DatiumAssetKey, newScreen = false) {
    //if (this.vSubscriptionDel != null) {
    //  this.vSubscriptionDel.unsubscribe();
    //}
    this.dataStore.setVeLoader(DatiumAssetKey, true, "Fetching the latest changes...")
    //this.commonService.showSnakeBar('Please wait while we are fetching the latest changes.');
    //console.log('getVehicleDetails', DatiumAssetKey)
    this.vSubscriptionDel = this.vehicleService.getVehicleDetail(DatiumAssetKey).subscribe(
      (result) => {
        try {
          this.dataStore.setVeLoader(DatiumAssetKey, false)
          let response = result.json();
          let vehicle = response.data;
          if (vehicle) {
            const indexDt = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey)
            if (indexDt != -1) {
              //console.log('vehicle', vehicle)
              this.preventClose = DatiumAssetKey;
              this.preventToggle = DatiumAssetKey;
              this.vehicleList[indexDt] = vehicle;
              if (newScreen === true) {
                this.newScreen = DatiumAssetKey;
              } else {
                this.newScreen = null;
              }
            }
          }
        } catch (e) {

        }
      },
      (error) => {
        this.dataStore.setVeLoader(DatiumAssetKey, false)
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.commonService.showSnakeBar('Data has reloaded successfully.');
      }
    )
  }

  checkEmptyFilter() {
    let sFilter = {
      Body_Type: this.selectedFilter['Body_Type'],
      Cylinders: this.selectedFilter['Cylinders'],
      Family: this.selectedFilter['Family'],
      Make: this.selectedFilter['Make'],
      Year_Group: this.selectedFilter['Year_Group'],
      Fuel_Type: this.selectedFilter['Fuel_Type'],
      Transmission: this.selectedFilter['Transmission'],
      VFactsGroups: this.selectedFilter['VFactsGroups'],
      Vehicle_Level: this.selectedFilter['Vehicle_Level'],
    }
    let isEmpty = this.commonService.checkAllEmpty(sFilter)
    //Object.keys(sFilter).every(x => (sFilter[x] == null || sFilter[x] == ''));
    return isEmpty
  }

  ngOnChanges(changes) {
    // Reset vehicle listing array because user click on search button
    if (this.vList && this.isBasket == true) {
      this.vehicleList = changes['vList']['currentValue'];
      this.isLoadingList = false;
      this.notFoundHeading = 'No vehicle found for this basket.'
      this.notFoundDescription = '';
      this.total = this.vehicleList.length;
      return false;
    }
    if (changes.filterState) {
      let filterStateChanges = changes.filterState;
      if (filterStateChanges.firstChange == false) {
        let filterVal = filterStateChanges.currentValue;
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            this.vSubscription.unsubscribe();
          }
          if (filterVal.reqType !== reqType.download) {
            this.resetPaginationRecords();
          }

          ///this.vehicleList = []
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          if (this.checkEmptyFilter()) {
            this.notFoundHeading = 'Please choose a filter first.';
            this.notFoundDescription = '';
            // cancel exiting request
            if (this.vSubscription != null) {
              this.vSubscription.unsubscribe();
            }
            this.isLoadingList = false;
            return false;
          }
          this.getVehicleList();
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.';
          this.notFoundDescription = '';
          // cancel exiting request
          if (this.vSubscription != null) {
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords();
          //this.vehicleList = [];
        }
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please correct the errors.';
          this.notFoundDescription = '';
          // cancel exiting request
          if (this.vSubscription != null) {
            this.vSubscription.unsubscribe();
          }
        }
      } else {
        let filterVal = filterStateChanges.currentValue;
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please choose a filter.';
          this.notFoundDescription = '';
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.isLoadingList = false;
        }
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            this.vSubscription.unsubscribe();
          }
          //this.vehicleList = []
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.resetPaginationRecords();
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          if (this.checkEmptyFilter()) {
            this.notFoundHeading = 'Please choose a filter first.';
            this.notFoundDescription = '';
            // cancel exiting request
            if (this.vSubscription != null) {
              this.vSubscription.unsubscribe();
            }
            if (filterVal.error === false) {
              this.isLoadingList = false;
              return false;
            }
          }
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.';
          this.notFoundDescription = '';
          // cancel exiting request
          if (this.vSubscription != null) {
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords();
        }
      }
    }
    /*if (changes.filterState.currentValue === true) {
      // cancel exiting request
      if (this.vSubscription != null) {
        // console.log('unsubscribe')
        this.vSubscription.unsubscribe();
      }
      this.vehicleList = []
      //this.disableScroll = true;
      this.page = 1
      this.limit = 10;
      console.log(this.page, 'page')
      this.getVehicleList()
    } else {
      ///this.isLoadingList = false;
    }*/
  }

  ngOnInit() {
    //console.log(this.filterState['data'])
    //this.selectedFilter = this.commonService.getInitialFilterApiParams(this.filterState['data'])
    // console.log(this.selectedFilter)
    //this.getVehicleList()
  }

  /*
  * @name resetPaginationRecords
  * @param None
  * @description reset pagination, records and selected filter
  * @return none
  */
  resetPaginationRecords(skipFilter: boolean = false) {
    this.page = 1
    this.limit = this.type == 'new_vehicles' ? 25 : 10;
    this.total = 0
    if (skipFilter === false) {
      this.selectedFilter = {}
    }
    this.vehicleList = [];
    this.sort = 'desc'
    this.sortBy = 'units'
    //this.disableScroll = true;
    this.noMoreRec = false;
    this.isLoadingList = false
    this.isPaging = false
    if (this.type == 'new_vehicles') {
      this.resetSelection.emit()
    }
    this.dataStore.setSelectedCarData({ clear: true });
    this.commonService.clearCarSelectionLength()
    this.commonService.clearNewVehicleSelectionLength()
  }

  /*
  * @name toggleLoader
  * @param show:boolean,paging:boolean
  * @description toggle the loader icon
  * @return none
  */
  toggleLoader(show = false, paging = false) {
    if (paging == false) {
      this.isLoadingList = show
    } else {
      this.isPaging = show;
    }
  }

  /*
  * @name getVehicleList
  * @param paging:boolean
  * @description Fetching the vehicle list
  * @return none
  */
  getVehicleList(paging = false) {
    if (paging == false) {
      this.dataStore.setSelectedCarData({ clear: true });
      this.commonService.clearCarSelectionLength()
      this.commonService.clearNewVehicleSelectionLength()
    } else {
      this.filterState.reqType = reqType.search;
    }
    let apiParams = {
      page: this.filterState.reqType === reqType.search ? this.page : parseInt(this.filterState.exportCsvPageNo),
      limit: this.filterState.reqType === reqType.search ? this.limit : this.exportRecordsLimit,
      sort: this.sort,
      sortBy: this.sortBy,
      reqType: this.filterState.reqType
      //list_type: (this.routeName == 'vehicle-list-new') ? 'new_list' : 'old_list'
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    if (location.pathname.includes('vehicle-list-used')) {
      apiParams['list_type'] = 'old_list'
    }
    else if (location.pathname.includes('vehicle-list-new') || location.pathname.includes('vehicle-list-bi')) {
      apiParams['list_type'] = 'new_list'
    }
    if (this.type == 'new_vehicles') {
      apiParams['list_type'] = 'new_vehicle'
    }
    // if (this.routeName) {
    //   this.router.navigate([location.pathname], { relativeTo: this.route, queryParams: apiParams, replaceUrl: true })
    // }

    //this.commonService.sendUserTo('vehicle-list', apiParams)
    // cancel previous request
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }

    let funName = 'getVehicleListing'
    if (this.type == 'new_vehicles') {
      funName = 'getNewVehicleListing'
      if (this.filterState.reqType === reqType.download) {
        funName = 'exportNewVehicleList';
        this.isExporting = true;
      }
    }

    if (this.filterState.reqType === reqType.search) {
      this.toggleLoader(true, paging);
    }

    if (this.filterState.reqType === reqType.download && this.type !== 'new_vehicles') {
      funName = 'exportVehicleList';
      this.isExporting = true;
    }
    this.vSubscription = this.vehicleService[funName](apiParams, 'post').subscribe(
      (result) => {
        try {
          if (this.filterState.reqType === reqType.search) {
            let response = result.json();
            let vehicleData = response.data;
            if (this.page == 1) {
              this.total = vehicleData.total
              if (vehicleData.records.length == 0) {
                this.notFoundHeading = "No record found."
                this.notFoundDescription = ''
              }
              if (this.type === 'new_vehicles') {
                this.listState.emit(this.total)
              }
              this.totalRecords.emit(this.total);
            }
            this.currentTotal = vehicleData.total
            this.limit = vehicleData.limit
            this.page = vehicleData.page;
            //console.log(this.vehicleList.length)
            if (this.currentTotal == 0 && paging === true) {
              this.noMoreRec = true;
            } else {
              this.noMoreRec = false;
            }
            if (vehicleData.records.length > 0) {
              this.appendVehicleListing(vehicleData.records)
              //this.vehicleList = vehicleData.records
            } else {
              if (this.page == 1) {
                this.notFoundHeading = 'Hum.. Looks like no record(s) found for this filter'
                this.notFoundDescription = 'Please update your filter above.'
              }
            }
            // let's know the wrapper parent component, that list is loaded successfully
            if (this.type != 'new_vehicles') {
              this.listState.emit(true)
            }
            this.searchFinishEvent.emit('done');
          } else {
            const filename = this.isSuperAdmin ? 'Datium.xlsx' : 'RV.xlsx';
            let blob = new Blob([result.blob()], { type: 'application/xlsx' });
            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, filename);
            }
            else {
              var link = document.createElement("a");
              // Browsers that support HTML5 download attribute
              if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
            this.isExporting = false;
            this.exportFinishEvent.emit("done");
          }

        } catch (e) {
          this.commonService.commonSnakeBar();
        }
      },
      (error) => {
        this.exportFinishEvent.emit("done");
        this.toggleLoader(false, paging);
        try {
          let response = error.json();
          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          if (this.type != 'new_vehicles') {
            this.listState.emit(false);
          }
          // decrement the page
          if (this.page != 1) {
            this.page -= 1
          }
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.toggleLoader(false, paging);
        this.exportFinishEvent.emit("done");
      }
    )

  }

  selectAllForAdmin(event: any) {
    let dataLength = 25;
    if (this.vehicleList.length < 25) {
      dataLength = this.vehicleList.length
    }
    for (let i = 0; i < dataLength; i++) {
      this.vehicleList[i].checked = event.checked;
    }
    this.isSelectAll = event.checked;
    this.selectAllEvent.emit({ selectAll: this.isSelectAll, vehicles: this.vehicleList.slice(0, dataLength) });
  }

  /*
  * @name onScroll
  * @param :None
  * @description paginate on scroll
  * @return none
  */
  onScroll() {
    /*if (this.disableScroll === true) {
      return false;
    }*/
    if (this.isLoadingList === true || this.isPaging === true) {
      return false;
    }
    //console.log(typeof (this.page), 'this.page')
    let page = parseInt(this.page);
    this.page = page + 1;
    //console.log(typeof (this.page), 'this.page')
    if (this.currentTotal > 10) {
      //console.log('onScroll request')
      this.getVehicleList(true)
    }
  }

  /*
  * @name orderBy
  * @param sortBy:String,sort:String
  * @description handle order by records
  * @return none
  */
  orderBy(sortBy = 'units', sort = 'desc') {
    if (location.href.includes('basket')) {
      return false;
    }
    this.resetPaginationRecords(true)
    if (sortBy != 'new_price' && sortBy != 'units') {
      this.sortBy = 'units'
    } else {
      this.sortBy = sortBy
    }
    if (sort != 'asc' && sort != 'desc') {
      this.sort = 'desc'
    } else {
      this.sort = sort
    }
    //this.page = 1
    //this.limit = 10;
    //this.vehicleList = []
    this.filterState.reqType = 'search';
    this.getVehicleList();
  }

  /*
  * @name appendVehicleListing
  * @param newList:Array
  * @description Append the newly added vehicle list
  * @return none
  */
  appendVehicleListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.vehicleList.length > 0) {
        newState = this.vehicleList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.vehicleList = newState
    }

  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      if (this.type != 'new_vehicles') {
        this.listState.emit(errors)
      }
      this.notFoundHeading = 'Please correct the errors'
      this.notFoundDescription = errors[Object.keys(errors)[0]];
    } else {
      if (this.type != 'new_vehicles') {
        this.listState.emit({})
      }
    }
  }

  /*
  * @name ngOnDestroy
  * @param None
  * @description Destroy the subscription. Not using takeUntil(RxJs)
  * @return none
  */
  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.vSubscriptionDel) {
      this.vSubscriptionDel.unsubscribe()
    }
    if (this.resetRVHistorySubscription) {
      this.resetRVHistorySubscription.unsubscribe()
    }
    if (this.getSelectedFilters) {
      this.getSelectedFilters.unsubscribe()
    }
    if (this.rejectVehicleSub) {
      this.rejectVehicleSub.unsubscribe()
    }
    if (this.getAcceptedVehicle) {
      this.getAcceptedVehicle.unsubscribe()
    }
    if (this.acceptVehicleSub) {
      this.acceptVehicleSub.unsubscribe()
    }
    if (this.getSelectedVehicleSignal) {
      this.getSelectedVehicleSignal.unsubscribe()
    }
  }

}
