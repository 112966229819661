import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BasketService } from 'src/app/services/basket.service';
import { CommonService } from 'src/app/services/common.service';
import { AddBasketComponent } from '../add-basket/add-basket.component';

@Component({
  selector: 'app-add-basket-vehicle',
  templateUrl: './add-basket-vehicle.component.html',
  styleUrls: ['./add-basket-vehicle.component.scss']
})
export class AddBasketVehicleComponent implements OnInit {

  vehicleId: string = '';
  basketLoader: boolean = false;
  adding: boolean = false;
  baskets: any[] = [];
  basketIds = [];
  basketId = [];
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddBasketVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private basketService: BasketService,
    public commonService: CommonService
  ) {
    if (this.data) {
      this.vehicleId = this.data['vehicleId'];
    }
  }

  ngOnInit() {
    this.basketListing();
  }

  basketListing() {
    this.basketLoader = true;
    this.basketService.getBasketListing().subscribe(
      (result) => {
        this.basketLoader = false
        try {
          let response = result.json();
          this.baskets = response.data.baskets;
        } catch (e) {
        }
      },
      (error) => {
        this.basketLoader = false
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );
  }

  handleBasketSelection(event) {
    this.basketId = event;
    console.log(this.basketId, 'bsId');
  }


  isProcessing = false;
  addVehicleBasket() {
    this.isProcessing = true;
    const ids = [...this.basketId].join(',');
    this.basketService.addVehicle({
      datiumAssetKey: this.vehicleId,
      id: ids
    }).subscribe(
      (result) => {
        this.isProcessing = false;
        try {
          let response = result.json();
          if (response.data) {
            this.dialogRef.close(response.data);
          } else {
          }
        } catch (e) {

        }
      },
      (error) => {
        this.isProcessing = null
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );
  }

  addNewBasket() {
    const dialogRef = this.dialog.open(AddBasketComponent, {
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'result');
      if (result) {
        this.baskets.push(result);
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
