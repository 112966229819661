import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AdminUsersService } from '../../services/admin-users.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-add-update-user',
  templateUrl: './add-update-user.component.html',
  styleUrls: ['./add-update-user.component.scss']
})
export class AddUpdateUserComponent implements OnInit {

  public onAddUser: EventEmitter<any> = new EventEmitter();
  public onUpdateUser: EventEmitter<any> = new EventEmitter();

  public userInvite: FormGroup;
  public isProcessing: boolean = false;
  public formError = {};
  public isEdit: boolean = false;
  public user: any = {};
  public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
  public phoneNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  constructor(
    public adminUserService: AdminUsersService,
    private commonService: CommonService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddUpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (this.data && this.data.isEdit) {
      this.isEdit = this.data.isEdit;
      this.user = this.data.user;
    }
  }

  ngOnInit() {
    this.initForm();
  }


  initForm() {
    if (this.isEdit) {
      this.userInvite = new FormGroup({
        'name': new FormControl(this.user.Name, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'User_Id': new FormControl(this.user.User_Id),
        'email': new FormControl(this.user.Email, [
          Validators.required, Validators.email, Validators.maxLength(40)
        ]),
        'phone_no': new FormControl(this.user.PhoneNo, [
          Validators.minLength(8), Validators.maxLength(10)
        ])
      });
    }
    else {
      this.userInvite = new FormGroup({
        'name': new FormControl(null, [
          Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[A-Za-z_-][A-Za-z0-9 _-]*$")
        ]),
        'email': new FormControl(null, [
          Validators.required, Validators.email, Validators.maxLength(40)
        ]),
        'phone_no': new FormControl(null, [
          Validators.minLength(8), Validators.maxLength(10)
        ])
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  sendInvite(form) {
    if (form.valid) {
      this.isProcessing = true;
      this.formError = {}
      this.adminUserService.addUserToCompany(form.value).subscribe(res => {
        try {
          const response = res.json();
          this.onAddUser.emit();
          this.commonService.showSnakeBar(response.message);
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.userInvite.contains(key)) {
          let control = this.userInvite.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  updateUser(form) {
    if (form.valid) {
      this.isProcessing = true;
      console.log(form.value)
      this.adminUserService.updateCompanyUser(form.value).subscribe(res => {
        try {
          const response = res.json();
          this.onUpdateUser.emit(form.value);
          this.commonService.showSnakeBar(response.message);
          this.closeModal();
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        // this.closeModal();
      }, () => {
        this.isProcessing = false;
        //this.closeModal();
      })
    }
    else {
      this.formValidate(form);
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }
}
