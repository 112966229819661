/*
* @ngdoc Component
* @name app.vehicle.vehicle-list.LinkVehicleDetailComponent
* @description
* This component is used to show linked vehicle details
* */

import { Component, OnInit, Input, Output, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { AppSettings } from 'src/app/configs/app-settings';
import { DataStoreService } from 'src/app/services/data-store.service';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'link-vehicle-detail',
  templateUrl: './link-vehicle-detail.component.html',
  styleUrls: ['./link-vehicle-detail.component.scss']
})
export class LinkVehicleDetailComponent implements OnInit, OnChanges {

  @Input() isRvValues: string;
  @Input() linkedVehicle;
  @Input() vehicleId;
  @Input() rvHidden: boolean;
  @Input() isDataLoaded: boolean = false;
  @Input() linkedVehicleId;
  @Input() isAdmin: boolean;
  @Output() printChart: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  error = '';
  vSubscription: Subscription;
  loadRVHistorySubscription: Subscription;
  resetRVHistorySubscription: Subscription;
  linkedVehicleData = {}

  constructor(
    private vehicleService: VehicleService,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dataStore: DataStoreService,
    public roleCheckService: RoleCheckerService
  ) {
    if (!this.linkedVehicleId) {
      this.error = 'Invalid Datium Asset Key';
    } else {
      this.error = '';
    }
  }

  ngOnChanges(simpleChange) {
    if (simpleChange.isDataLoaded && simpleChange.isDataLoaded.currentValue === true) {
      this.linkedVehicleData = this.linkedVehicle;
      this.printChart.emit(this.linkedVehicle);
    }
  }

  ngOnInit() {
    this.setInitTasks();
  }

  setInitTasks() {
    if (!this.isDataLoaded) {
      this.loadRVHistorySubscription = this.dataStore.checkRVHistoryListDatiumAssetKey().subscribe((data) => {
        if (data['DatiumAssetKey'] == this.vehicleId) {
          if (this.vSubscription) {
            this.vSubscription.unsubscribe()
          }
          this.getLinkedVehicleDetails();
        }
      });
      this.resetRVHistorySubscription = this.dataStore.checkResetRVHistoryListDatiumAssetKey().subscribe((data) => {
        if (data['DatiumAssetKey'] == this.vehicleId) {
          if (data['reset'] == true && data['cancel'] == false) {
            this.linkedVehicleData = {};
          }
          if (data['cancel'] == true && data['reset'] == false) {
            if (this.vSubscription) {
              this.vSubscription.unsubscribe()
            }
          }
          if (data['cancel'] == true && data['reset'] == true) {
            if (this.vSubscription) {
              this.vSubscription.unsubscribe()
            }
            this.linkedVehicleData = {};
            this.getLinkedVehicleDetails();
          }
        }
      });
    }
    else {
      this.linkedVehicleData = this.linkedVehicle;
      this.printChart.emit(this.linkedVehicle);
    }
  }

  /*
  * @name getLinkedVehicleDetails
  * @param None
  * @description get linked vehicle details
  * @return none
  */
  getLinkedVehicleDetails() {
    this.toggleLoader(true)
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.vSubscription = this.vehicleService.getVehicleDetail(this.linkedVehicleId).subscribe(
      (result) => {
        try {
          let response = result.json();
          this.linkedVehicleData = response.data;
          this.printChart.emit(this.linkedVehicleData);
        } catch (e) {

        }
      },
      (error) => {
        let response = error.json();
        this.toggleLoader(false)
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.toggleLoader(false)
      }
    )
  }

  /*
  * @name toggleLoader
  * @param None
  * @description toggle listing loaders
  * @return none
  */
  toggleLoader(show = false) {
    this.isLoading = show
  }

  /*
 * @name formValidate
 * @param  Form
 * @description validate form                       
 * @return none
 */
  formValidate(formGroup: FormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.loadRVHistorySubscription) {
      this.loadRVHistorySubscription.unsubscribe()
    }
    if (this.resetRVHistorySubscription) {
      this.resetRVHistorySubscription.unsubscribe()
    }
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
