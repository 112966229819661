import { Component, OnInit } from '@angular/core';
import { RoleCheckerService } from '../../services/role-checker.service';
import { JwtService } from '../../services/jwt.service';
import { MatDialog } from '@angular/material';
import { RvCalculatorComponent } from 'src/app/shared/calculator/rv-calculator/rv-calculator.component';
import { environment } from 'src/environments/environment';
import { GaEventsService } from 'src/app/services/ga-events.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  public menu_items;
  private permissions: Array<any>;
  public isSuperAdmin: boolean = false;
  public isCompanyOwner: boolean = false;
  public isCompanyAdmin: boolean = false;
  public isCompanyUser: boolean = false;
  
  constructor(
    private roleCheckService: RoleCheckerService,
    private jwtService: JwtService,
    public dialog: MatDialog,
    private gaEvent: GaEventsService
  ) { }

  ngOnInit() {
    this.isSuperAdmin = this.roleCheckService.isSuperAdmin();
    this.isCompanyOwner = this.roleCheckService.isCompanyOwner();
    this.isCompanyAdmin =  this.roleCheckService.isCompanyAdmin();
    this.isCompanyUser = this.roleCheckService.isCompanyUser();
    this.permissions = this.jwtService.getUserPermissions();
    
    this.menu_items = this.assignMenuItems();
  }

  assignMenuItems() {
    let items = [];
    items.push(
      {
        "menu_name": "Dashboard",
        "menu_link": "/dashboard"
      }
    );

    if (this.isSuperAdmin || (this.isCompanyOwner && !this.roleCheckService.checkAutoAccept())) {
      items.push({
        "menu_name": "New Vehicles",
        "menu_link": "/new-vehicles",
        "slug": "new_v"
      });
    }

    if (this.isSuperAdmin || this.permissions.includes('new_view_rv') || this.permissions.includes('new_edit_rv')) {
      items.push({
        "menu_name": "Current Releases",
        "menu_link": "/vehicle-search-new"
      });
    }
    
    if (this.isSuperAdmin || this.permissions.includes('used_view_rv') || this.permissions.includes('used_edit_rv')) {
      items.push(
        {
          "menu_name": "Past Releases",
          "menu_link": "/vehicle-search-used"
        }
      );
    }

    let toolsMenu = [];
    if (this.isSuperAdmin || this.permissions.includes('calculator')) {
      toolsMenu.push(
        {
          "name": this.isSuperAdmin ? 'Datium Calculator' : 'RV Calculator', 
        }
      );
    }

    if (this.isSuperAdmin || this.permissions.includes('price_tracker')) {
      toolsMenu.push(
        {
          "name": 'Price Tracker',
          "url": '/price-tracker'
        }
      );
    }

    if (!this.isSuperAdmin && (this.permissions.includes('new_view_rv') || this.permissions.includes('new_edit_rv') || this.permissions.includes('used_view_rv') || this.permissions.includes('used_edit_rv'))) {
      toolsMenu.push(
        {
          "name": 'Saved Snapshots',
          "url": '/my-account/reports'
        }
      );
    }
      
    if (this.isSuperAdmin || this.permissions.includes('basket')) {
      toolsMenu.push(
        {
          "name": 'Basket',
          "url": '/basket'
        }
      );
    }

    if (this.isSuperAdmin || this.permissions.includes('quick-search')) {
      toolsMenu.push(
        {
          "name": 'Quick Search',
          "url": '/quick-search'
        }
      );
    }
    //push sub items 1 into main array
    items.push(
      {
        "menu_name": "Tools",
        "sub_menu": toolsMenu
      }
    );

    let settingsMenu = [];
    if (this.isSuperAdmin || this.permissions.includes('used_edit_rv') || this.permissions.includes('new_edit_rv')) {
      settingsMenu.push(
        {
          "name": 'Risk Adjustments',
          "url": '/risk-adjustments'
        }
      );
    }

    if (this.isSuperAdmin || this.permissions.includes('new_view_rv') || this.permissions.includes('new_edit_rv') || this.permissions.includes('used_view_rv') || this.permissions.includes('used_edit_rv')) {
      settingsMenu.push(
        {
          "name": 'Linking Tree',
          "url": '/linking-tree'
        }
      );
    }

    if (!this.isCompanyUser) {
      settingsMenu.push(
        {
          "name": 'Manage Users'
        }
      );
    }
    //push sub items 2 into main array
    items.push(
      {
        "menu_name": "Settings",
        "sub_menu":  settingsMenu
      }
    ); 
    
    return {
      items: items
    };
  }

  openCalculator(): void {
    if (!this.roleCheckService.isSuperAdmin()) {
      let gaData = {
        'event': 'rvCalculator'
      }
      this.gaEvent.sendGaTagConfigData(gaData)
    }
    const dialogRef = this.dialog.open(RvCalculatorComponent, {
      width: 'auto',
      height: 'auto',
      data: {}
    });
  }

  manageUsers() {
    if(this.roleCheckService.isSuperAdmin()) {
      window.location.href = environment.portalUrl + "users";
    } else {
      window.location.href = environment.portalUrl + "my-account/manage-users";
    }
    
  }

}
