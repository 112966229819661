import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'no-record-found',
  templateUrl: './no-record-found.component.html',
  styleUrls: ['./no-record-found.component.scss']
})
export class NoRecordFoundComponent implements OnInit {

  @Input() notFoundHeading: string;
  @Input() notFoundDescription: string;
  @Input() noRecord: Boolean
  @Input() cStyle: any
  @Input() showImg: boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
