import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonService } from '../../services/common.service';
import { CompanyService } from '../../services/company.service';
import { DataStoreService } from '../../services/data-store.service';
import { ConfirmationPopupComponent } from '../../shared/confirmation-popup/confirmation-popup.component';
import { UpdateCompanyV2Component } from '../portal/update-company-v2/update-company-v2.component';
import { JwtService } from 'src/app/services/jwt.service';
import { InstaApiKeyComponent } from '../insta-api-key/insta-api-key.component';

@Component({
  selector: 'company-row-details',
  templateUrl: './company-row-details.component.html',
  styleUrls: ['./company-row-details.component.scss']
})

export class CompanyRowDetailsComponent implements OnInit {

  @Input() CompanyObject: any;
  @Output() processToggle = new EventEmitter();

  public isProcessing;

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    private companyService: CompanyService,
    public dataStore: DataStoreService,
    public jwtService: JwtService
  ) { }

  ngOnInit() {

  }

  changeCompanyStatus(id, status) {
    const reqModel = {
      status: status,
      companyId: id
    };
    this.isProcessing = true;
    this.companyService.companyStatus(reqModel).subscribe(res => {
      try {
        const obj = {
          data: reqModel,
          companyAdded: false,
          companyUpdated: false,
          companyStatusChange: true
        }
        this.dataStore.sendCompanyListSignal(obj);
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, () => {
      this.isProcessing = false;
    })
  }


  updateCompany(object) {
    const dialogRef = this.dialog.open(UpdateCompanyV2Component, {
      width: 'auto',
      height: 'auto',
      data: {
        company: object
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyUpdate.subscribe(res => {
      const obj = {
        data: res,
        companyAdded: false,
        companyUpdated: true,
        companyStatusChange: false
      }
      this.dataStore.sendCompanyListSignal(obj);
    })
  }

  confirmDeactivation(companyId) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Deactivate Company'
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.changeCompanyStatus(companyId, 2);
      }
    });
  }

  createCompanyConsumer(companyId) {
    console.log(companyId)
    this.processToggle.emit({ action: 'consumer', CompanyId: companyId })
  }

  viewApiKey(companyObj) {
    const confirmDialogRef = this.dialog.open(InstaApiKeyComponent, {
      width: '500px',
      height: 'auto',
      data: {
        companyDetails: companyObj
      }
    });
  }
}
