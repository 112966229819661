/*
* @ngdoc Services
* @name app.services.http-interceptor
* @description
* This class is used to inject global data/headers and handling of global errors related to http requests.
* */

import { Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend } from "@angular/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { MatSnackBar, MatDialog } from '@angular/material';

// operators
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtService } from "./jwt.service";
import { Router } from "@angular/router";
import { GaEventsService } from './ga-events.service';

@Injectable()
export class HttpInterceptor extends Http {

  constructor(
    backend: XHRBackend,
    options: RequestOptions,
    public http: Http,
    public snackBar: MatSnackBar,
    private jwtService: JwtService,
    private router: Router,
    private dialogRef: MatDialog,
    private gaEvent: GaEventsService
  ) {
    super(backend, options)
  }

  public request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    return super.request(url, options).pipe(
      catchError(error => this.handleError(error)))
  }

  public handleError = (error: Response | any) => {
    //Globally handling the errors
    const body = error.json();
    if (error.status === 401) {
      let gaData = {
        'event': 'userSesExpire',
        'value': `${this.jwtService.getUserName()}~${new Date()}`
      };
      this.gaEvent.sendGaTagConfigData(gaData);
      this.jwtService.deleteLocalStorage(false, true);
      this.snackBar.open(body.message, 'Done', {
        duration: 3000
      });

    } else if (error.status === 403) {
      this.snackBar.open(body.message, 'Done', {
        duration: 3000
      });
      this.dialogRef.closeAll()
      this.router.navigate(["/dashboard"]);
    }
    let errMsg: string;
    if (error.error instanceof Error) {
      //A client-side or network error occurred.
    } else if (error instanceof Response) {
      // Server side error
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error['message'] ? error['message'] : error.toString();
    }
    return throwError(error)
  }
}
