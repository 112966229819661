import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { AppHeadings } from 'src/app/configs/app-headings';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-vehicle-list-wrapper',
  templateUrl: './vehicle-list-wrapper.component.html',
  styleUrls: ['./vehicle-list-wrapper.component.scss']
})

export class VehicleListWrapperComponent implements OnInit {

  resetAndSearch: boolean = false;
  pageTitle: string = '';
  serverError = {}
  constructor(
    private dataStore: DataStoreService,
    private appHeading: AppHeadings,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.pageTitle = this.appHeading.vehicle.p_title.list;
    this.commonService.setTitle(this.pageTitle);
  }


  /*
  * @name handleFilterSearchForm
  * @param params
  * @description Handle search form
  * @return none
  */
  handleFilterSearchForm(data) {
    //console.log(data, 'type')
    if (data == 'form_submit') {
      this.resetAndSearch = true;
    }
    else if (data == 'params') {
      this.dataStore.loadVehicleList('list');
    } else {
      //this.dataStore.loadVehicleList();
    }
  }

  /*
  * @name handleSearchFormReset
  * @param params
  * @description Handle search form reset event
  * @return none
  */
  handleSearchFormReset(data) {
    this.dataStore.resetVehicleList()
  }

  /*
  * @name handleFilterSearchForm
  * @param params
  * @description Handle search form
  * @return none
  */
  handleFormErrors(data) {
    this.serverError = data;
  }

  /*
  * @name handleNoFilterSel
  * @param params
  * @description Handle no filter selected response
  * @return none
  */
  handleNoFilterSel(data) {

  }

  vehicleListLoaded(isLoaded) {
    //if (isLoaded === true) {
    this.resetAndSearch = false;
    //}
  }
}
