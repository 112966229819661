import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { VehicleService } from '../../../services/vehicle.service';
import { CommonService } from '../../../services/common.service';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { DatePipe } from '@angular/common';
import { GaEventsService } from '../../../services/ga-events.service';

@Component({
  selector: 'vehicle-chart',
  templateUrl: './vehicle-chart.component.html',
  styleUrls: ['./vehicle-chart.component.scss']
})

export class VehicleChartComponent implements OnInit {

  @Input() vehicleId;
  @Input() vehicle;
  @Input() isLinkedVehicleTabOpened: boolean = false;
  @Input() isLinkedVehicleChartView: boolean = false;
  public Highcharts = Highcharts;
  public chartDataLoading: boolean = true;
  @Output() isChartDataLoading: EventEmitter<string> = new EventEmitter();
  public charApiResponseData;
  public maxDate = new Date()
  public startDate = new Date(new Date().setFullYear((new Date).getFullYear() - 1))
  public chartOptions: any = {};
  public carRegions: string[] = [];
  public carYearGroup: string[] = []
  public bestFitLine: string[] = [];

  public chartModel: any = {
    Region: 'All',
    StartDate: this.startDate,
    EndDate: this.maxDate,
    Year_Group: 'All'
  };

  constructor(
    private vehicleService: VehicleService,
    private commonService: CommonService,
    private roleCheckService: RoleCheckerService,
    public dp: DatePipe,
    private gaEvent: GaEventsService
  ) {
    //console.log(this.chartModel)
    this.chartModel.EndDate = this.maxDate
    this.chartModel.StartDate = this.startDate
    //this.chartModel.EndDate = this.dp.transform(this.maxDate, 'dd/MM/yyyy')
    ///console.log(new String(this.chartModel.EndDate).length)
    //this.chartModel.StartDate = this.dp.transform(this.startDate, 'dd/MM/yyyy')
    //console.log(new String(this.chartModel.StartDate).length)
    //console.log(this.chartModel)
    //this.chartModel.StartDate = '05-09-2018' //`${(this.maxDate.getMonth() + 1)}/${this.maxDate.getDate()}/${this.maxDate.getFullYear()}`
    // this.chartModel.EndDate = '05-09-2019'//`${(this.startDate.getMonth() + 1)}/${this.startDate.getDate()}/${this.startDate.getFullYear()}`

  }

  ngOnInit() {
    this.fetchChartData();
    this.isChartDataLoading.emit('loading');
  }

  ngOnChanges(){
    // this is only for linked vehicle chart tab, in which we are rendering chart on tab click
    if(this.isLinkedVehicleTabOpened && this.charApiResponseData !== undefined) {
        this.prepareChartData();
    }
  }

  FixLocaleDateString(localeDate) {
    var newStr = "";
    for (var i = 0; i < localeDate.length; i++) {
      var code = localeDate.charCodeAt(i);
      if (code >= 47 && code <= 57) {
        newStr += localeDate.charAt(i);
      }
    }
    return newStr;
  }



  fetchChartData() {
    this.chartOptions = this.defaultChartOptions();
    this.chartDataLoading = true;
    const reqModel = {
      Region: this.chartModel.Region == 'All' ? '' : this.chartModel.Region,
      StartDate: this.chartModel.StartDate ? this.dp.transform(this.chartModel.StartDate, 'yyyy/MM/dd') : '',
      EndDate: this.chartModel.EndDate ? this.dp.transform(this.chartModel.EndDate, 'yyyy/MM/dd') : '',
      Year_Group: this.chartModel.Year_Group == 'All' ? '' : this.chartModel.Year_Group,
      //StartDate: (this.chartModel.StartDate) ? (new Date(this.chartModel.StartDate)).toLocaleDateString() : '',
      //EndDate: (this.chartModel.EndDate) ? (new Date(this.chartModel.EndDate)).toLocaleDateString() : ''
    }
    
    this.vehicleService.getVehicleChartDetails(this.vehicleId, reqModel).subscribe(res => {
      const response = res.json();
      this.charApiResponseData = response;
      // for all vehicle charts except linked vehicle, render chart immediately after api call
      if(this.isLinkedVehicleChartView === false) {
        this.prepareChartData();
      }
      this.chartDataLoading = true;
      this.isChartDataLoading.emit('done');
    }, (err) => {
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      }
      else {
        this.commonService.commonSnakeBar();
      }
      this.chartDataLoading = false;
      this.isChartDataLoading.emit('done');
    }, () => {
      this.chartDataLoading = false;
      this.isChartDataLoading.emit('done');
    });
  }

  prepareChartData() {
    this.chartDataLoading = true;
    this.chartOptions = this.defaultChartOptions();
    if (this.charApiResponseData.status && this.charApiResponseData.data && this.charApiResponseData.data.Regions) {
        this.carRegions = this.charApiResponseData.data.Regions;
      }
      if (this.charApiResponseData.status && this.charApiResponseData.data && this.charApiResponseData.data.YearGroupList) {
        this.carYearGroup = this.charApiResponseData.data.YearGroupList;
      }
      if (this.charApiResponseData.status && this.charApiResponseData.data && this.charApiResponseData.data.bestFit && this.charApiResponseData.data.bestFit.length > 0) {
        this.bestFitLine = this.charApiResponseData.data.bestFit;
      } else {
        this.bestFitLine = [];
      }
      if (this.charApiResponseData.status && this.charApiResponseData.data && this.charApiResponseData.data.ChartData && this.charApiResponseData.data.ChartData.length > 0) {
        this.fillChartData(this.charApiResponseData.data.ChartData);
      }
      else {
        this.fillChartData();
      }
      setTimeout(() => {
        this.chartDataLoading = false;
      }, 500);
      
  }



  fillChartData(chartData = null) {
    this.pushUnitsAndAgeData(chartData);
    if (this.vehicle && this.vehicle.diValues && this.vehicle.diValues.dollarValue) {
      const diKeys = Object.keys(this.vehicle.diValues.dollarValue);
      let carDiPrnctValues = [];
      let carDiDollarValues = [];
      for (let j = 0; j < diKeys.length; j++) {
        let temp = { x: 0, y: 0 };
        if (this.vehicle.diValues.dollarValue[diKeys[j]] && this.vehicle.diValues.dollarValue[diKeys[j]].formatted) {
          carDiDollarValues.push(parseInt(this.vehicle.diValues.dollarValue[diKeys[j]].formatted.replace(",", "")));
          temp.x = (j + 1) * 12;
          temp.y = this.vehicle.diValues.percentageValue[diKeys[j]].formatted;
          carDiPrnctValues.push(temp);
        }
      }
      if (carDiPrnctValues.length > 0) {
        this.chartOptions.series.push({
          name: 'DI Index',
          color: this.roleCheckService.isSuperAdmin() == true ? '#2ca9e1' : '#ff68a1',
          marker: { symbol: 'circle' },
          data: carDiPrnctValues
        });
      }
    }

    if (this.vehicle && this.vehicle.dmValues && this.vehicle.dmValues.dollarValue && this.roleCheckService.isSuperAdmin() == true) {
      const dmKeys = Object.keys(this.vehicle.dmValues.dollarValue);
      let carDmPrnctValues = [];
      let carDmDollarValues = [];
      for (let j = 0; j < dmKeys.length; j++) {
        let temp = { x: 0, y: 0 };
        if (this.vehicle.dmValues.dollarValue[dmKeys[j]] && this.vehicle.dmValues.dollarValue[dmKeys[j]].formatted) {
          carDmDollarValues.push(parseInt(this.vehicle.dmValues.dollarValue[dmKeys[j]].formatted.replace(",", "")));
          temp.x = (j + 1) * 12;
          temp.y = this.vehicle.dmValues.percentageValue[dmKeys[j]].formatted;
          carDmPrnctValues.push(temp);
        }
      }
      if (carDmPrnctValues.length > 0) {
        this.chartOptions.series.push({
          name: 'Datium',
          color: '#ff68a1',
          marker: { symbol: 'circle' },
          data: carDmPrnctValues
        });
      }
    }

    if (this.vehicle && this.vehicle.rvValues && this.vehicle.rvValues.dollarValue && this.roleCheckService.isSuperAdmin() == false) {
      const rvKeys = Object.keys(this.vehicle.rvValues.dollarValue);
      let carRvPrnctValues = [];
      let carRvDollarValues = [];
      for (let j = 0; j < rvKeys.length; j++) {
        let temp = { x: 0, y: 0 };
        if (this.vehicle.rvValues.dollarValue[rvKeys[j]] && this.vehicle.rvValues.dollarValue[rvKeys[j]].formatted) {
          carRvDollarValues.push(parseInt(this.vehicle.rvValues.dollarValue[rvKeys[j]].formatted.replace(",", "")));
          temp.x = (j + 1) * 12;
          temp.y = this.vehicle.rvValues.percentageValue[rvKeys[j]].formatted;
          carRvPrnctValues.push(temp);
        }
      }
      if (carRvPrnctValues.length > 0) {
        this.chartOptions.series.push({
          name: 'RV',
          color: '#2ca9e1',
          marker: { symbol: 'circle' },
          data: carRvPrnctValues
        });
      }
    }
    this.pushPriceAndAgeData(chartData);
    this.generateBestFitLine();
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  formatMonth(data) {
    return parseInt(data.split(" ")[0]);
  }

  pushUnitsAndAgeData(chartData) {
    if (chartData) {
      let dataObj = [];
      for (let i = 0; i < chartData.length; i++) {
        dataObj.push({
          x: parseInt(chartData[i].Age),
          y: parseInt(chartData[i].UnitSold)
        })
      }
      this.chartOptions.series.push({
        name: 'Units',
        color: '#D3D3D3',
        type: 'column',
        yAxis: 1,
        data: dataObj
      });
    }
    else {
      this.chartModel.StartDate = undefined;
      this.chartModel.EndDate = undefined;
    }
  }

  pushPriceAndAgeData(chartData) {
    if (chartData) {
      let dataObj = [];
      for (let i = 0; i < chartData.length; i++) {
        dataObj.push({
          x: parseInt(chartData[i].Age),
          y: parseFloat(chartData[i].AvgPer)
        })
      }
      this.chartOptions.series.push({
        name: 'Price',
        color: '#f7a35c',
        type: 'scatter',
        marker: { symbol: 'circle', radius: 3 },
        data: dataObj
      });
    }
    else {
      this.chartModel.StartDate = undefined;
      this.chartModel.EndDate = undefined;
    }
  }

  generateBestFitLine() {
    if (this.bestFitLine.length > 0) {
      let dataObj = [];
      this.bestFitLine.map(el => {
        dataObj.push({
          x: parseInt(el[0]),
          y: parseFloat(el[1])
        });
      });
      // console.log(dataObj, 'dataObj')
      this.chartOptions.series.push({
        name: 'Fit Line',
        color: '#f7a560',
        type: 'line',
        // marker: { symbol: 'circle', radius: 3 },
        data: this.bestFitLine,
        dashStyle: 'ShortDash'
      });
    }
  }

  onDateChange() {
    if (this.chartModel.StartDate > this.chartModel.EndDate) {
      this.commonService.showSnakeBar("Invalid date selection");
    }
    else if (this.chartModel.StartDate && this.chartModel.EndDate) {
      let gaData = {
        'event': 'vChartDateClick',
        'value': this.vehicleId
      }
      this.gaEvent.sendGaTagConfigData(gaData)

      this.fetchChartData();
    }
  }

  onRegionChange() {
    let gaData = {
      'event': 'vChartRegion',
      'value': `${this.vehicleId}~${this.chartModel.Region}`
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    this.fetchChartData();
  }

  onYearChange() {
    /*let gaData = {
      'event': 'vChartYearGroup',
      'value': `${this.vehicleId}~${this.chartModel.Year_Group}`
    }
    this.gaEvent.sendGaTagConfigData(gaData)*/
    this.fetchChartData();
  }

  defaultChartOptions() {
    let self = this;
    return {
      xAxis: {
        title: {
          text: "Age in Months"
        },
        min: 0,
        tickInterval: 12
      },
      title: {
        text: '',
      },
      subtitle: {
        text: ''
      },
      legend: {
        labelFormatter: function () {
          if (this.name == 'Units') {
            return 'Units';
          }
          else if (this.name == 'Price') {
            return 'Sales Price %';
          }
          else if (this.name == 'Datium' && self.roleCheckService.isSuperAdmin() == true) {
            return 'Datium';
          }
          else if (this.name == 'RV' && self.roleCheckService.isSuperAdmin() == false) {
            return 'RV';
          }
          else if (this.name == 'DI Index') {
            return 'DI Index';
          } else if (this.name == 'Fit Line') {
            return 'Line Of Best Fit';
          }
        }
      },
      yAxis: [
        {
          title: {
            text: '% of RRP',
          },
          min: 0,
          max: 100,
          labels: {
            formatter: function () {
              return this.value + "%";
            }
          }
        },
        {
          allowDecimals: false,
          title: {
            text: 'Units',
          },
          opposite: true
        }
      ],
      credits: {
        enabled: false
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: '100%'
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }
        ]
      },
      tooltip: {
        formatter: function () {
          if (this.series.name == 'Units') {
            return 'Age (' + this.x + (this.x > 1 ? ' months' : ' month') + ') and Unit Count (' + this.y + (this.y > 1 ? ' units' : ' unit') + ')';
          }
          else if (this.series.name == 'Price') {
            return 'Age (' + this.x + (this.x > 1 ? ' months' : ' month') + ') and Sales Price (' + this.y + '%)';
          }
          else if (this.series.name == 'RV' && self.roleCheckService.isSuperAdmin() == false) {
            return 'RV (' + this.y + ' %)';
          }
          else if (this.series.name == 'Datium' && self.roleCheckService.isSuperAdmin() == true) {
            return 'Datium (' + this.y + ' %)';
          }
          else if (this.series.name == 'DI Index') {
            return 'DI Index (' + this.y + ' %)';
          }
          else {
            return this.series.name;
          }
        }
      },
      series: [],
      plotOptions: {
        series: {
          pointWidth: 6
        }
      },
    }
  }

  isChartEmpty() {
    if (this.chartOptions && this.chartOptions.series && this.chartOptions.series.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  disableFieldStatus() {
    if (this.chartDataLoading) {
      return true;
    }
    else if (this.carYearGroup && this.carRegions.length <= 0) {
      return true;
    }
    else if (this.carYearGroup && this.carYearGroup.length <= 0) {
      return true;
    }
    else {
      return false;
    }
  }
}
