import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { AppHeadings } from 'src/app/configs/app-headings';


@Component({
  selector: 'app-autopredict-used',
  templateUrl: './autopredict-used.component.html',
  styleUrls: ['./autopredict-used.component.scss']
})
export class AutopredictUsedComponent implements OnInit {

  constructor(private commonService: CommonService, private appHeadings: AppHeadings) { }

  ngOnInit() {
    ///this.commonService.setTitle('Past Releases');
  }

}
