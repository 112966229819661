import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RoleCheckerService } from '../../services/role-checker.service';

@Component({
  selector: 'app-help-support',
  templateUrl: './help-support.component.html',
  styleUrls: ['./help-support.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpSupportComponent implements OnInit {

  visibleTab: string = 'administration';
  selectorLoading: boolean = true;
  isSuperAdmin = false
  constructor(public roleService: RoleCheckerService) {

  }

  ngOnInit() {
    this.isSuperAdmin = this.roleService.isSuperAdmin()
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.selectorLoading = false;
    // }, 1000);
  }

  toggleTopics(tab) {
    if (this.visibleTab != tab) {
      this.visibleTab = tab
      window.scrollTo(0, 0)
    }
  }

}
