/*
* @ngdoc Component
* @name app.vehicle.vehicle-list.VehicleRvHistoryComponent
* @description
* This component is used to show/clear/reset the history of particular vehicle by Datium Asset Key
* */

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { VehicleService } from '../../../services/vehicle.service';
import { CommonService } from '../../../services/common.service';
import { AppSettings } from '../../../configs/app-settings';
import { DataStoreService } from '../../../services/data-store.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'vehicle-rv-history',
  templateUrl: './vehicle-rv-history.component.html',
  styleUrls: ['./vehicle-rv-history.component.scss']
})
export class VehicleRvHistoryComponent implements OnInit {

  @Input() vehicleId;
  @Input() vehicle;

  isLoadingList: boolean = false;
  isPaging: boolean = false;
  vSubscription: Subscription;
  loadRVHistorySubscription: Subscription;
  resetRVHistorySubscription: Subscription;
  total = 0
  page: any = 1
  limit = 10;
  noMoreRec = false;
  rvHistoryList = []
  timeLineDateFormat;
  Htime;
  error = '';
  currentTotal = 0;
  constructor(
    private vehicleService: VehicleService,
    private commonService: CommonService,
    private appSettings: AppSettings,
    private dataStore: DataStoreService,
    public roleCheckService: RoleCheckerService
  ) {
    this.timeLineDateFormat = this.appSettings.HISTORY_TIME_FORMAT
    this.Htime = this.appSettings.HISTORY_COMMENT_TIME
    if (!this.vehicleId) {
      this.error = 'Invalid Datium Asset Key';
    } else {
      this.error = '';
    }

    this.loadRVHistorySubscription = this.dataStore.checkRVHistoryListDatiumAssetKey().subscribe(
      (data) => {
        if (data['DatiumAssetKey'] == this.vehicleId) {
          if (this.vSubscription) {
            this.vSubscription.unsubscribe()
          }
          if (this.roleCheckService.isSuperAdmin()) {
            this.getDiHistoryList();
          }
          else {
            this.getRvHistoryList();
          }
        }
      },
    )
    this.resetRVHistorySubscription = this.dataStore.checkResetRVHistoryListDatiumAssetKey().subscribe(
      (data) => {
        //console.log('data', data)
        if (data['DatiumAssetKey'] == this.vehicleId) {
          if (data['reset'] == true && data['cancel'] == false) {
            this.rvHistoryList = [];
            /*if (this.roleCheckService.isSuperAdmin()) {
              this.getDiHistoryList();
            }
            else {
              this.getRvHistoryList();
            }*/
          }
          if (data['cancel'] == true && data['reset'] == false) {
            if (this.vSubscription) {
              this.vSubscription.unsubscribe()
            }
          }
          if (data['cancel'] == true && data['reset'] == true) {
            if (this.vSubscription) {
              this.vSubscription.unsubscribe()
            }
            this.rvHistoryList = [];
            if (this.roleCheckService.isSuperAdmin()) {
              this.getDiHistoryList();
            }
            else {
              this.getRvHistoryList();
            }
          }
        }
      },
    )
  }

  ngOnInit() {

  }
  /*
  * @name getRvHistoryList
  * @param None
  * @description Api call for fetching the rv history list
  * @return none
  */
  getRvHistoryList(paging = false) {
    // If we are on Accept/reject screen, we first need to check if vehicle is accepted or not.
    // If accepted load the adjustments history
    if (!this.commonService.toggleIndex(this.vehicle, 'toggle')) {
      return false;
    }
    this.toggleLoader(true, paging)

    // cancel previous request
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.vSubscription = this.vehicleService.getRVChangesHistory(this.vehicleId).subscribe(
      (result) => {
        try {
          let response = result.json();
          let historyData = response.data;
          if (this.page == 1) {
            this.total = historyData.total
          }
          this.currentTotal = historyData.total
          this.limit = historyData.limit
          this.page = historyData.page
          //console.log(this.vehicleList.length)
          if (this.currentTotal == 0 && paging === true) {
            this.noMoreRec = true;
          } else {
            this.noMoreRec = false;
          }
          if (historyData.records.length > 0) {
            this.appendRvHistoryListing(historyData.records)
            //this.vehicleList = vehicleData.records
          }
          ///console.log(this.vehicleList.length)
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error.json();
        this.toggleLoader(false, paging)
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.toggleLoader(false, paging)
      }
    )
  }

  /*
* @name getDiHistoryList
* @param None
* @description Api call for fetching the rv history list
* @return none
*/
  getDiHistoryList(paging = false) {
    this.toggleLoader(true, paging)
    // cancel previous request
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.vSubscription = this.vehicleService.getDIChangesHistory(this.vehicleId).subscribe(
      (result) => {
        try {
          let response = result.json();
          let historyData = response.data;
          this.total = historyData.total
          this.limit = historyData.limit
          this.page = historyData.page
          //console.log(this.vehicleList.length)
          if (this.total == 0 && paging === true) {
            this.noMoreRec = true;
          } else {
            this.noMoreRec = false;
          }
          if (historyData.records.length > 0) {
            this.appendRvHistoryListing(historyData.records)
            //this.vehicleList = vehicleData.records
          }
          ///console.log(this.vehicleList.length)
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error.json();
        this.toggleLoader(false, paging)
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.toggleLoader(false, paging)
      }
    )
  }

  /*
  * @name toggleLoader
  * @param None
  * @description toggle listing loaders
  * @return none
  */
  toggleLoader(show = false, paging = false) {
    if (paging == false) {
      this.isLoadingList = show
    } else {
      this.isPaging = show;
    }
  }

  /*
  * @name appendRvHistoryListing
  * @param None
  * @description add new Rv values after paginate
  * @return none
  */
  appendRvHistoryListing(newList = []) {
    //console.log('newList', newList)
    if (newList.length > 0) {
      let newState = [];
      if (this.rvHistoryList.length > 0) {
        newState = this.rvHistoryList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.rvHistoryList = newState
    }

  }


  /*
   * @name onScroll
   * @param newList:None
   * @description paginate on scroll
   * @return none
   */
  onScroll() {
    /*if (this.disableScroll === true) {
      return false;
    }*/
    if (this.isLoadingList === true || this.isPaging === true) {
      return false;
    }
    //console.log(this.page, 'this.page')
    let page = parseInt(this.page);
    this.page = page + 1;
    //console.log(this.page, 'this.page')
    if (this.total > 10) {
      //console.log('onScroll request')
      if (this.roleCheckService.isSuperAdmin()) {
        this.getDiHistoryList(true);
      }
      else {
        this.getRvHistoryList(true);
      }
    }
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.loadRVHistorySubscription) {
      this.loadRVHistorySubscription.unsubscribe()
    }
    if (this.resetRVHistorySubscription) {
      this.resetRVHistorySubscription.unsubscribe()
    }
  }

  getClass(value) {
    let className;
    if (value == null) {
      className = ''
    } else if (value <= 0) {
      className = 'text-danger'
    } else if (value >= 0) {
      className = 'text-success'
    }
    return className
  }

}
