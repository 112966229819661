import { Component, OnInit } from '@angular/core';
import { DataStoreService } from 'src/app/services/data-store.service';
import { AppHeadings } from 'src/app/configs/app-headings';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-linking-tree',
  templateUrl: './linking-tree.component.html',
  styleUrls: ['./linking-tree.component.scss']
})
export class LinkingTreeComponent implements OnInit {

  pageTitle: string
  resetAndSearch: boolean = false;
  serverError = {}
  filterState = {
    submit: false,
    reset: false,
    initialState: false,
    data: {},
    error: false,
    loader: true
  }
  type = ""
  constructor(private dataStore: DataStoreService, private appHeading: AppHeadings, private commonService: CommonService) {
    this.pageTitle = this.appHeading.vehicle.p_title.linkingTree;
    this.commonService.setTitle(this.pageTitle)
  }

  ngOnInit() {
  }


  /*
  * @name handleFilterSearchForm
  * @param params
  * @description Handle search form
  * @return none
  */
  handleFilterSearchForm(data) {
    if (data == 'form_submit') {
      this.resetAndSearch = true;
    }
    else if (data == 'params') {
      this.dataStore.loadVehicleList('linking-tree');
    }
    else {
      //
    }
  }

  /*
  * @name handleSearchFormReset
  * @param params
  * @description Handle search form reset event
  * @return none
  */
  handleSearchFormReset(data) {
    this.dataStore.resetVehicleList()
  }

  vehicleListLoaded(isLoaded) {
    //if (isLoaded === true) {
    this.resetAndSearch = false;
    //}
  }

  //{ reset: boolean, submit: boolean, data: object, error: boolean }
  searchResponse(response) {
    //console.log(response)
    //console.log(this.filterState)
    this.filterState = response
    //console.log(this.filterState)
    //this.commonService.setLocalStorage('vehicle_filters', JSON.stringify(response.data))
    //this.commonService.sendUserTo('vehicle-list-bi')
  }


  /*
  * @name handleFilterSearchForm
  * @param params
  * @description Handle search form
  * @return none
  */
  handleFormErrors(data) {
    this.serverError = data;
  }

}

