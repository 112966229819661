/*
* @ngdoc Config
* @name app.configs.ApiUrls
* @description
* The service is used to define all the Api urls
* */

export class ApiUrls {
  vehicle = {
    vehicle: 'vehicle',
    capture_vehicle: 'capture-vehicle',
    reports: 'reports',
    new_link: 'vehicle',
    new_vehicle_link: 'new-link',
    new_vehicle: 'new-vehicle',
    new_vehicle_list: 'new-vehicle-list',
    filter: 'vehicle-filters',
    list: 'vehicle-list',
    list_new: 'new-vehicle-list',
    rv_history: 'rv-history',
    di_history: 'di-history',
    chart_detail: 'vehicle-graph-detail',
    linked_vehicle: 'vehicle/linked-vehicleIds',
    base_data: 'base-data',
    addRvDi: 'add-rvdi',
    recommendation: 'recommendations',
    changeLink: 'change-linking',
    deleteLink: 'delete-linking',
    addRecom: 'add-recommendation',
    deleteRecom: 'delete-recommendation',
    linkingTree: 'linking-tree-list',
    lTree: 'linking-tree',
    accept_vehicle: 'accept-vehicle',
    reject_vehicle: 'reject-vehicle',
    multi_actions: 'multi-actions',
    riskAdjustmentListing: 'riskAdjustmentListing',
    addRiskAdjustment: 'addRiskAdjustment',
    updateRiskAdjustment: 'updateRiskManagement',
    deleteRiskAdjustment: 'deleteRiskAdjustment',
    otherVehicleListing: 'other-vehicle-list',
    linkingTreeDetail: 'linking-tree-detail',
    rv_calculator: 'rv-calculator',
    downloadIndex: 'export-vehicles',
    quickVehicleFilter: 'quick-search/filters',
    quickVehicles: 'quick-search/vehicles',
    quickVehicleSearches: 'quick-search/searches',
    export: 'export'
  };
  company = {
    company: 'company',
    list: 'companyListing',
    addCompany: "addCompany",
    updateCompany: "updateCompany",
    addSubscription: "add-subscription",
    updateSubscription: "update-subscription",
    getCompany: "getCompanyDetails",
    companyStatus: "companyStatus",
    subscription: "subscription",
    removeSubscription: "remove-subscription",
    createConsumer: "create-consumer",
    subscriptionList: 'subscription-list',
    deactivateSub: 'de-subscription',
    details: 'details'
  };
  user = {
    userListing: 'getCompanyUserListing',
    permissionListing: 'getUserPermissionList',
    addUserToCompany: 'addUserToCompany',
    editUserDetails: 'editUserDetails',
    deleteUser: 'deleteUser',
    azureAccessToken: 'azure/accessToken',
    singleSignIn: 'singleSignIn',
    logout: 'logout',
    contactUs: 'contactUs',
    userProductsAccess: '/portal/getProductListByCompany'
  };
  admin = {
    userListing: 'users',
    addUser: "users/add",
    UpdateUser: "users/update",
    deleteUser: 'users/delete',
  };
  dashboard = {
    rv_history: 'dashboard/rv-history',
    di_history: 'dashboard/di-history',
    tiles_count: 'dashboard/tiles_count',
    new_vehicles_chart: 'dashboard/newAddedVehiclesChart',
    recely_searched_vehicles_chart: 'dashboard/recenlySearchedVehiclesChart'
  };
  permissions = {
    list: 'permissions'
  };
  products = {
    list: 'products',
    attributes: 'attributes',
    updateProduct: 'update',
    productListing: 'portal/getProductList',
    offer: ''
  };
  p_offer = {
    list: 'product-offers',
    company: 'company-offers',
    update: 'update',
    details: 'details'
  };
  priceTracker = {
    tracker: 'price-tracker',
    filters: 'filters',
    records: 'records'
  };
  basket = {
    list: 'baskets',
    add: 'baskets',
    update: 'baskets',
    delete: 'delete-basket',
    vehicle: 'basket/vehicles',
    addVehicle: 'basket/add-vehicle',
    delVehicle: 'basket/delete-vehicle'
  };
}
