/*
* @ngdoc Component
* @name app.vehicle.vehicle-list.SelCompareVehicleComponent
* @description
* This component is used to handling the compare vehicle chart
* */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatBottomSheet } from '@angular/material';
import { Subscription } from 'rxjs';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-export-lists',
  templateUrl: './export-lists.component.html',
  styleUrls: ['./export-lists.component.scss']
})
export class ExportListsComponent implements OnInit {

  @Input() selectedItems: Array<any> = [];
  @Input() selectedFor:string = "";
  @Output() exportingFinishEvent = new EventEmitter();
  isExporting: boolean = false;
  
  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    private vehicleService: VehicleService
  ) {
    
  }

  ngOnInit() {
    
  }

  
  exportResults() {
    if(this.selectedFor === 'Reports') {
      this.exportReports();
    }
  }

  exportReports() {
    this.isExporting = true;
    this.vehicleService.exportVehicleReport(this.selectedItems).subscribe(
      (result) => {
        try {
            const filename = 'Reports.xlsx';
            let blob = new Blob([result.blob()], { type: 'application/xlsx' });
            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, filename);
            } else {
              var link = document.createElement("a");
              // Browsers that support HTML5 download attribute
              if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
            this.selectedItems = [];
            this.exportingFinishEvent.emit('done');
        } catch (e) {
          this.commonService.commonSnakeBar();
        }
      },
      (error) => {
        const response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isExporting = false;
      }
    )
  }
}
