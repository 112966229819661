import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CompanyService } from '../../services/company.service';
import { CommonService } from '../../services/common.service';
import { DataStoreService } from '../../services/data-store.service';
import { Subscription } from 'rxjs';
import { AddCompanyV2Component } from '../portal/add-company-v2/add-company-v2.component';
import { PopupLoaderComponent } from 'src/app/shared/popup-loader/popup-loader.component';
import { AddOfferComponent } from '../subscription/add-offer/add-offer.component';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})

export class CompaniesListComponent implements OnInit {
  public isProcessing: boolean = false;
  public companyList: any[] = [];
  public isPaging: boolean = false;
  public pagingParams: any;
  public totalRecords: number = 0;
  public getCompanyListSignal: Subscription;
  public listCompleted: boolean = false;
  noMoreRec = false;
  constructor(
    public dialog: MatDialog,
    public companyService: CompanyService,
    public commonService: CommonService,
    public dataStore: DataStoreService
  ) {
    this.getCompanyListSignal = this.dataStore.getCompanyListSignal().subscribe(res => {
      if (res && res.companyStatusChange) {
        const index = this.companyList.findIndex(obj => obj.CompanyId == res.data.companyId);
        if (index != -1) {
          this.companyList[index].CompanyStatus = res.data.status;
        }
      }
      else {
        this.pagingParams = this.getPagingParams();
        this.companyList = [];
        this.listCompleted = false;
        this.getCompaniesList(false);
      }
    })
  }

  ngOnInit() {
    this.pagingParams = this.getPagingParams();
    this.getCompaniesList(false);
  }

  getPagingParams() {
    return {
      page: 1,
      limit: 30,
      sort: 'asc',
    }
  }

  ngOnDestroy() {
    if (this.getCompanyListSignal) {
      this.getCompanyListSignal.unsubscribe();
    }
  }

  addNewCompany(): void {
    const dialogRef = this.dialog.open(AddCompanyV2Component, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: false
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyAdd.subscribe(res => {
      let companyData = res
      const dialogRef = this.dialog.open(AddOfferComponent, {
        width: "auto",
        height: "auto",
        data: {
          companyId: res['CompanyId']
        },
        disableClose: true
      });
      dialogRef.componentInstance.onCompanyOfferAdded.subscribe(res => {
        console.log('here...')
        const obj = {
          data: companyData,
          companyAdded: true,
          companyUpdated: false,
          companyStatusChange: false
        }
        this.dataStore.sendCompanyListSignal(obj);
      })
    })
  }

  getCompaniesList(isForPaging) {
    this.toggleLoader(isForPaging);
    this.companyService.getCompanyListing(this.pagingParams).subscribe(res => {
      try {
        const response = res.json();
        this.appendCompanyListing(response.data.records);
        this.totalRecords = response.data.total;
        if (this.totalRecords == 0 && isForPaging === true) {
          this.noMoreRec = true;
        } else {
          this.noMoreRec = false;
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    }, (err) => {
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    })
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.companyList.length > 0) {
        newState = this.companyList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.companyList = newState;
      if (this.companyList.length == this.totalRecords) {
        this.listCompleted = true;
      }
    }
  }

  toggleLoader(isForPaging) {
    if (isForPaging) {
      this.isPaging = !this.isPaging;
    }
    else {
      this.isProcessing = !this.isProcessing;
    }
  }

  loadMore() {
    if (this.isProcessing === true || this.isPaging === true) {
      return false;
    }
    this.pagingParams.page++;
    if (this.totalRecords > 10) {
      this.getCompaniesList(true)
    }
  }

  createConsumer(CompanyId: number) {
    //this.isProcessing = true;
    const confirmDialogRef = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Creating consumer...'
      }
    });
    this.companyService.createConsumer(CompanyId).subscribe(res => {
      confirmDialogRef.close()
      try {
        const response = res.json();
        this.commonService.showSnakeBar(response.message)
        this.updateCompanyRow({ action: 'consumer', CompanyId: CompanyId })
      }
      catch (e) {
      }
      //this.isProcessing = false;
    }, (err) => {
      confirmDialogRef.close()
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      //this.isProcessing = false;
    })
  }

  updateCompanyRow(data) {
    if (data['action'] == 'consumer') {
      if (data['CompanyId']) {
        let index = this.companyList.findIndex(el => el.CompanyId == data['CompanyId'])
        if (index != -1) {
          this.companyList[index].isApi = 1
        }
      }
    }
  }

  handleProcessAction(data) {
    console.log(data)
    if (data['action'] == 'consumer') {
      if (data['CompanyId']) {
        this.createConsumer(data['CompanyId'])
      }
    }
  }
}
