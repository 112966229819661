/*
* @ngdoc Component
* @name app.vehicle.vehicle-list.filters.VehicleListFilterComponent
* @description
* This component is used to vehicle filter listing
* */

import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, OnChanges } from '@angular/core'
import { MatBottomSheet } from '@angular/material';
import { CompareVehiclesComponent } from '../compare-vehicles/compare-vehicles.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AppHeadings } from '../../../configs/app-headings';
import { DataStoreService } from '../../../services/data-store.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterName } from '../../../configs/filter-names';
@Component({
  selector: 'vehicle-list-filter',
  templateUrl: './vehicle-list-filter.component.html',
  styleUrls: ['./vehicle-list-filter.component.scss']
})
export class VehicleListFilterComponent implements OnInit, OnDestroy, OnChanges {


  @Input() serverError;
  @Input() checkParams: boolean = true;
  @Output() searchFormSubmit = new EventEmitter();
  @Output() searchFormReset = new EventEmitter();
  //@Output() noFilterSel = new EventEmitter();

  searchForm: FormGroup;
  pageHeading = '';
  formError = {};
  firstLoadFilter = true;
  filterOrder = ['VFactsGroups', 'Make', 'Family', 'Body_Type', 'Fuel_Type', 'Transmission', 'Cylinders', 'Vehicle_Level']
  paramsSubscription: Subscription;
  searchFormValueChanges: Subscription;
  getFilterSelectSignal: Subscription;
  getSelectedCarDataSignal: Subscription;
  selectCarForComapare: Array<any> = [];
  constructor(
    private filterName: FilterName,
    private fb: FormBuilder,
    private appHeading: AppHeadings,
    private bottomSheet: MatBottomSheet,
    private dataStore: DataStoreService,
    private activatedRoute: ActivatedRoute
  ) {

    this.searchForm = this.fb.group(
      {
        VFactsGroups: new FormControl(''),
        Make: new FormControl(''),
        Family: new FormControl(''),
        Body_Type: new FormControl(''),
        Fuel_Type: new FormControl(''),
        Transmission: new FormControl(''),
        Cylinders: new FormControl(''),
        Vehicle_Level: new FormControl('')
      }
    )
    this.searchFormValueChanges = this.searchForm.valueChanges.subscribe(newVal => {
      //console.log('newVal', newVal)
      this.dataStore.setSelectedFilters(newVal)
    })

    this.pageHeading = this.appHeading.vehicle.heading.list
    //this.commonService.setTitle(this.appHeading.vehicle.p_title.list)

    this.getFilterSelectSignal = this.dataStore.getFilterSelectSignal().subscribe(data => {
      if (data.filter != null && this.firstLoadFilter === false) {
        this.clearSelectedDependentFilters(data.filter)
      }
    })

    // this.getSelectedCarDataSignal = this.dataStore.getSelectedCarData().subscribe(data => {
    //   this.selectCarForComapare = data;
    // })
  }

  ngOnInit() {
    //console.log(this.serverError, 'serverError')
    //console.log(this.checkParams, this.checkParams === true)
    if (this.checkParams == true) {
      //console.log(this.checkParams, 'check params')
      this.paramsSubscription = this.activatedRoute
        .queryParams
        .subscribe(params => {
          let patchValues = {};
          Object.keys(params).forEach(field => {
            let index = this.filterOrder.indexOf(field)
            if (index != -1) {
              let pVal = params[field] || "";
              if (pVal != "") {
                patchValues[field] = pVal
              }
            }
          });
          setTimeout(() => {
            if (patchValues) {

              this.searchForm.patchValue(patchValues)
              if ('Cylinders' in patchValues) {
                let cy: any = 'All'
                if (patchValues['Cylinders'] == 'All') {
                } else {
                  let cy: any = parseInt(patchValues['Cylinders'])
                }
                this.searchForm.patchValue({
                  'Cylinders': cy
                })
              }
              this.dataStore.setSelectedFilters(this.searchForm.value)

              // Send signal to load vehicle list
              //this.searchFormSubmit.emit('params')
              //this.dataStore.loadVehicleList();

              //console.log('form', this.searchForm.value)
              //console.log('filter', this.filterName.filter)
            }
            //console.log('form new', this.searchForm.value)
            let sendSignal = false;
            let VFactsGroups = params['VFactsGroups'] || "";
            if (VFactsGroups != "") {
              sendSignal = true;
              this.dataStore.setInitFilterSelectSignal('VFactsGroups', VFactsGroups)
            } else {
              sendSignal = false;
              return this.handleServerFormError({
                'VFactsGroups': 'Please choose VFacts Groups.'
              })
            }
            let Make = params['Make'] || "";
            if (Make != "" && sendSignal) {
              sendSignal = true;
              this.dataStore.setInitFilterSelectSignal('Make', Make)
            } else {
              sendSignal = false;
            }
            let Family = params['Family'] || "";
            if (Family != "" && sendSignal) {
              sendSignal = true;
              this.dataStore.setInitFilterSelectSignal('Family', Family)
            }
            let Body_Type = params['Body_Type'] || "";
            if (Body_Type != "" && sendSignal) {
              sendSignal = true;
              this.dataStore.setInitFilterSelectSignal('Body_Type', Body_Type)
            } else {
              sendSignal = false;
            }
            let Fuel_Type = params['Fuel_Type'] || "";
            if (Fuel_Type != "" && sendSignal) {
              this.dataStore.setInitFilterSelectSignal('Fuel_Type', Fuel_Type)
              sendSignal = true;
            } else {
              sendSignal = false;
            }
            let Transmission = params['Transmission'] || "";
            if (Transmission != "" && sendSignal) {
              this.dataStore.setInitFilterSelectSignal('Transmission', Transmission)
              sendSignal = true;
            } else {
              sendSignal = false;
            }
            let Cylinders = params['Cylinders'] || "";
            if (Cylinders != "" && sendSignal) {
              this.dataStore.setInitFilterSelectSignal('Cylinders', Cylinders)
              sendSignal = true;
            } else {
              sendSignal = false;
            }
            this.firstLoadFilter = false;
            this.searchFormSubmit.emit('params')
          }, 2000)

        });
      this.paramsSubscription.unsubscribe();
    }
    //this.commonService.setTitle(this.appHeading.vehicle.p_title.list);
  }

  ngOnChanges(changes): void {
    //console.log(changes)
    if (changes.serverError) {
      if (changes.serverError.currentValue) {
        this.formError = changes.serverError.currentValue
      } else {
        this.formError = {}
      }
    } else {
      this.formError = {}
    }
    this.handleServerFormError(this.formError)
  }

  /*
  * @name clearSelectedDependentFilters
  * @param filter_key:String
  * @description Reset the dependent filter that was already selected
  * @return none
  */
  clearSelectedDependentFilters(filter_key) {
    let index = this.filterOrder.indexOf(filter_key)
    // index return -1 for index that does not exists
    if (index != -1) {
      let newArry = this.filterOrder;
      newArry = newArry.slice(index + 1)
      if (newArry.length > 0) {
        //console.log(newArry)
        let resetInput = {}
        newArry.forEach(element => {
          resetInput[element] = "";
        });
        this.searchForm.patchValue(resetInput)
        //console.log(this.searchForm.value)
      }
    }
  }

  // compareVehicles(): void {
  //   this.bottomSheet.open(CompareVehiclesComponent, { data: this.selectCarForComapare });
  // }

  /*
  * @name resetFilters
  * @param none
  * @description reset the vehicle filter form
  * @return none
  */
  resetFilters() {
    //console.log(this.filterName.filter)
    this.searchForm.setValue(this.filterName.filter)
    this.formError = {}
    this.dataStore.setSelectedFilters(this.filterName.filter)
    this.dataStore.resetVehicleFilterList()
    this.searchFormReset.emit('reset');
    //this.dataStore.resetVehicleList()
  }

  /*
  * @name handleSearchForm
  * @param searchFormObj:FormGroup
  * @description handle the search form
  * @return none
  */
  handleSearchForm(searchFormObj: FormGroup) {
    if (searchFormObj.valid) {
      let VFactsGroups = searchFormObj.value.VFactsGroups
      let Make = searchFormObj.value.Make
      if (!VFactsGroups && !Make) {
        if (!VFactsGroups) {
          return this.handleServerFormError({
            'VFactsGroups': 'Please choose VFacts Groups.'
          })
        }
        if (!Make) {
          return this.handleServerFormError({
            'Make': 'Please choose Make.'
          })
        }
      }
      //console.log(searchFormObj.value)
      this.searchFormSubmit.emit('form_submit')
      //this.dataStore.loadVehicleList();
      //this.commonService.sendUserTo('vehicle-list', searchFormObj.value)
    } else {
      this.formValidate(searchFormObj);
    }
  }

  /*
    * @name formValidate
    * @param  Form
    * @description validate form                       
    * @return none
    */
  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        //console.log(key, this.searchForm.contains(key))
        if (this.searchForm.contains(key)) {
          let control = this.searchForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
          //console.log(this.formError, control)
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.searchFormValueChanges) {
      this.searchFormValueChanges.unsubscribe();
    }
    if (this.getFilterSelectSignal) {
      this.getFilterSelectSignal.unsubscribe();
    }
    if (this.getSelectedCarDataSignal) {
      this.getSelectedCarDataSignal.unsubscribe();
    }
  }
}
