import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'new-vehicle-detail-wrapper',
  templateUrl: './new-vehicle-detail-wrapper.component.html',
  styleUrls: ['./new-vehicle-detail-wrapper.component.scss']
})
export class NewVehicleDetailWrapperComponent implements OnInit {

  @Input() vehicleId;
  @Input() vehicle;

  constructor() { }

  ngOnInit() {
  }

}
