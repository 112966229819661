import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
if (environment.script) {
  const script = document.createElement('script');
  script.innerHTML = `window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', 'f5523074251bd2237d05cdb8f869ddb0855da01a');`
  document.head.appendChild(script);
}

const gaDevProperty = 'UA-139731989-2'
const gaTestProperty = 'UA-139761308-3'
const gaProdProperty = 'UA-139731989-1'

const gtmTagDevProp = 'GTM-5D6H7CC'
const gtmTagTestProp = 'GTM-N79Q5L5'
const gtmTagProdProp = 'GTM-WKDF68C'

const getGaScriptData = () => {

  let gaCode = gaDevProperty
  if (environment.name == 'test') {
    gaCode = gaTestProperty
  } else if (environment.name == 'prod') {
    gaCode = gaProdProperty
  }

  const scriptGa = document.createElement('script')
  scriptGa.async = true
  scriptGa.src = `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
  document.head.appendChild(scriptGa);

  const script = document.createElement('script');
  script.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${gaCode}');`
  document.head.appendChild(script);

  if (environment.name == 'local') {
    return false
  }
  let gtmCode = gtmTagDevProp
  if (environment.name == 'test') {
    gtmCode = gtmTagTestProp
  } else if (environment.name == 'prod') {
    gtmCode = gtmTagProdProp
  }

  const scriptGtm = document.createElement('script')
  scriptGtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmCode}');`
  document.head.appendChild(scriptGtm);

  const noscriptBody = document.createElement('noscript')
  noscriptBody.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  document.body.appendChild(noscriptBody);
}

getGaScriptData()

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
