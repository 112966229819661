import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bi-search-vehicle',
  templateUrl: './bi-search-vehicle.component.html',
  styleUrls: ['./bi-search-vehicle.component.scss']
})
export class BiSearchVehicleComponent implements OnInit {

  @Input() routeName: string;
  constructor() { }

  ngOnInit() {
  }

}
