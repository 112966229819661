/*
* @ngdoc Component
* @name app.vehicle.vehicle.VehicleLinkWrapperComponent
* @description
* This component is used to manage the vehicle linking
* */

import { Component, OnInit, Input } from '@angular/core';
import { LinkVehicleComponent } from 'src/app/shared/link-vehicle/link-vehicle.component';
import { MatDialog } from '@angular/material';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { ConfirmationPopupComponent } from '../../../shared/confirmation-popup/confirmation-popup.component';
import { DataStoreService } from '../../../services/data-store.service';
import { VehicleService } from '../../../services/vehicle.service';
import { CommonService } from '../../../services/common.service';
import { NewAdminLinkComponent } from '../../new-admin-link/new-admin-link.component';

@Component({
  selector: 'vehicle-link-wrapper',
  templateUrl: './vehicle-link-wrapper.component.html',
  styleUrls: ['./vehicle-link-wrapper.component.scss']
})
export class VehicleLinkWrapperComponent implements OnInit {

  @Input() isRvValues: string;
  @Input() linkedVehicle;
  @Input() linkedVehicleId;
  @Input() vehicleId;
  @Input() vehicle;
  @Input() isAdmin: boolean;
  @Input() isLinkedVehicleTabOpened: boolean = false;

  public processedLinkedData: any = {};
  public processingLinkedData: boolean = true;
  isRemoving: boolean = false;
  constructor(
    public dialog: MatDialog,
    public roleCheckService: RoleCheckerService,
    private dataStore: DataStoreService,
    private vehicleService: VehicleService,
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    if ('DatiumAssetKey' in this.vehicle) {
      this.vehicle['DatiumAssetKey'] = this.vehicle['DatiumAssetKey']
      //console.log(this.vehicle, 'this.vehicle')
    }
  }

  printChartData(data) {
    this.processedLinkedData = data;
    this.processingLinkedData = false;
  }

  openNewAdminLinking(NewLink) {
    let popupD = {
      linkedVehicle: this.linkedVehicle,
      linkedVehicleId: this.linkedVehicleId,
      vehicle: this.vehicle,
      vehicleId: this.vehicleId,
      NewLink: NewLink
    }
    //console.log(popupD, 'popupD')
    const dialogRef = this.dialog.open(NewAdminLinkComponent, {
      width: 'auto',
      height: 'auto',
      data: popupD
    });
  }

  openLinkedVehicle(): void {
    this.processedLinkedData.DatiumAssetKey = this.processedLinkedData.DatiumAssetKey;
    const dialogRef = this.dialog.open(LinkVehicleComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkedVehicle: this.processedLinkedData,
        linkedVehicleId: this.linkedVehicleId,
        vehicle: this.vehicle,
        vehicleId: this.vehicleId
      }
    });
  }

  /*
  * @name selectedLink
  * @param None
  * @description Remove linking
  * @return none
  */
  removeLink() {
    let confirmTitle = 'Are you sure you want to remove the linking?';
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: confirmTitle
      }
    });
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.isRemoving = true
        let vId = this.vehicleId
        this.dataStore.setVeLoader(vId, true, "Removing the linking...")
        this.vehicleService.removeLinking(this.vehicleId).subscribe(
          (result) => {
            this.dataStore.setVeLoader(vId, false, "")
            try {
              //this.linkedVehicle = null;
              let response = result.json();
              this.commonService.showSnakeBar(response.message);
              this.dataStore.resetVehicleDetailsReload(this.vehicleId, true, true)
            } catch (e) {

            }
          },
          (error) => {
            this.dataStore.setVeLoader(vId, false, "")
            //console.log(error)
            let response = error.json();
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          },
          () => {
            this.isRemoving = false
          }
        )

      } else {
        // do nothing
      }
    });
  }
}
