
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-quick-search-modal',
  templateUrl: './quick-search-modal.component.html',
  styleUrls: ['./quick-search-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuickSearchModalComponent implements OnInit {

  loadingFilters: boolean = false;
  filters = ['Make', 'Family', 'Year_Group', 'Body_Type', 'Fuel_Type', 'Transmission', 'Cylinders', 'Vehicle_Level'];
  curFilters = 'Make';
  filterModel = {
    'Make': '',
    'Family': '',
    'Year_Group': '',
    'Body_Type': '',
    'Fuel_Type': '',
    'Transmission': '',
    'Cylinders': '',
    'Vehicle_Level': ''
  };
  keyword = 'MakeFamily';
  makeList = [];
  filterList = [];
  isFilterCall: Subscription = undefined;
  selectedVehicle: any = undefined;
  detailOffset = 0;
  constructor(
    //public dialogRef: MatDialogRef<QuickSearchModalComponent>,
    private vehicleService: VehicleService, private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getRecentAndPopularVehicles();
    this.getRecentAndPopularVehicles(1);
  }

  openDetails(event, vehicle) {
    var topPos = parseInt(event.target.getBoundingClientRect().top + window.scrollY);
    this.detailOffset = topPos >= 250 ? topPos - 250 : topPos;//;event.target.offsetTop >= 50 ? event.target.offsetTop - 50 : event.target.offsetTop;
    //console.log(this.detailOffset, 'detailOffset');
    this.selectedVehicle = vehicle
  }

  // Auto setup search and vehicle listing
  setupSearch(vehicle) {
    this.filters.map(el => {
      this.filterModel[el] = vehicle[el];
    });
    this.curFilters = 'Vehicle_Level';
    this.filterModel['Vehicle_Level'] = vehicle['Vehicle_Level'];
    this.resetPagination();
    this.getVehicles();
  }

  handleSelection(filter, value) {
    let nextFilter: string = this.getNextFilter(filter);
    // console.log(nextFilter, 'nextFilter');
    if (nextFilter) {
      this.curFilters = nextFilter;
      this.filterModel[filter] = value;
      this.resetPagination();
      this.fetchFilters();
    } else {
      if (filter == 'Vehicle_Level') {
        this.curFilters = filter;
        this.filterModel[filter] = value;
        this.resetPagination();
        this.getVehicles();
      }
    }
  }

  getFilterIndex = (filterName: string) => {
    return this.filters.indexOf(filterName);
  }

  getNextFilter = (filterName: string) => {
    let nextFilter: string = "";
    let index: number = this.getFilterIndex(filterName);
    if (index != -1 && this.filters[index + 1]) {
      nextFilter = this.filters[index + 1]
    }
    return nextFilter
  }

  clearNextFilters = (filterName: string) => {
    let index: number = this.getFilterIndex(filterName);
    let filLen = this.filters.length
    // if index found and not at first position
    if (index != -1 && index + 1 != filLen) {
      this.filters.slice(index + 1, filLen).map(el => {
        this.filterModel[el] = '';
      })
    }
  }

  selectEvent(item) {
    if (item && Object.keys(item).length > 0) {
      this.filterModel.Make = item.Make;
      this.filterModel.Family = item.Family;
      this.clearNextFilters('Family');
      this.curFilters = this.getNextFilter('Family');
      this.resetPagination();
      this.fetchFilters();
    }
    // console.log(item, 'item');
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // console.log(val, 'val');
    if (val) {
      this.resetPagination();
      this.fetchFilters(val);
    }
  }

  onFocused(e) {
    // do something when input is focused
  }

  clearFilter(filterName) {
    this.filterModel[filterName] = '';
    this.clearNextFilters(filterName);
    this.curFilters = filterName;
    this.resetPagination();
    this.filterList = [];
    if (filterName != 'Make') {
      this.fetchFilters();
    } else {
      this.makeList = [];
    }
    if (filterName == 'Make') {
      this.getRecentAndPopularVehicles();
      this.getRecentAndPopularVehicles(1);
    }
  }

  resetPagination() {
    this.isPaging = false;
    this.total = 0
    this.page = 1
    this.noMore = false;
    this.vehicleList = [];
  }

  fetchFilters(searchedVal = '') {
    let formData = {
      filter: this.curFilters,
      ...this.filterModel,
      search: searchedVal
    };
    this.loadingFilters = true;
    if (this.isFilterCall) {
      this.isFilterCall.unsubscribe();
    }
    this.getVehicles();
    this.isFilterCall = this.vehicleService.getQuickSearchFilters(formData).subscribe(
      (res) => {
        this.loadingFilters = false;
        let result = res.json();
        if (this.curFilters == 'Make') {
          this.makeList = result.data;
        } else {
          this.filterList = result.data;
          if (result.data.length == 1) {
            this.handleSelection(this.curFilters, result.data[0]['filter'])
          }
        }
      },
      (error) => {
        this.loadingFilters = false;
      },
      () => {
        this.loadingFilters = false;
      }
    );
  }


  vehicleLoader: boolean = false;
  vehicleSub: Subscription = null;
  vehicleList = [];
  isPaging = false;
  total = 0
  page: any = 1
  limit = 10;
  noMore: boolean = false;
  getVehicles(paging: boolean = false) {
    if (this.filterModel['Make'] && this.filterModel['Family'] && this.filterModel['Year_Group']) {
      !paging ? this.vehicleLoader = true : this.isPaging = true;
      if (this.vehicleSub) {
        this.vehicleSub.unsubscribe();
      }
      this.vehicleSub = this.vehicleService.getQuickSearchVehicles({ ...this.filterModel, page: this.page, limit: this.limit }).subscribe(
        (res) => {
          !paging ? this.vehicleLoader = false : this.isPaging = false;
          let result = res.json();
          if (result.data.records.length <= 0) {
            this.noMore = true;
          }
          this.vehicleList = [...this.vehicleList, ...result.data.records];
          if (this.vehicleList.length > 0 && this.page == 1) {
            this.total = result.data.total;
          }
        },
        (error) => {
          !paging ? this.vehicleLoader = false : this.isPaging = false;
        },
        () => {
          !paging ? this.vehicleLoader = false : this.isPaging = false;
        }
      );
    } else {
      this.vehicleList = [];
    }
  }

  onScroll() {
    this.page += 1;
    this.getVehicles(true);
  }

  recentVehicles = [];
  popularVehicles = [];
  recentLoader: boolean = false;
  popularLoader: boolean = false;
  getRecentAndPopularVehicles(type: number = 0) {
    type == 0 ? this.recentLoader = true : this.popularLoader = true;
    let params = { type: type };
    this.vehicleService.getVehicleSearches(params).subscribe(
      (res) => {
        type == 0 ? this.recentLoader = false : this.popularLoader = false;
        let result = res.json();
        type == 0 ? this.recentVehicles = result.data : this.popularVehicles = result.data;
      },
      (error) => {
        type == 0 ? this.recentLoader = false : this.popularLoader = false;
      },
      () => {

      }
    );
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
