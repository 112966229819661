import { Injectable } from '@angular/core';
import { ApiUrls } from '../configs/api-urls';
import { CommonHttpService } from './common-http.service';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  constructor(
    private commonHttpService: CommonHttpService,
    private apiUrls: ApiUrls
  ) { }

  /*
  * @name getBasketListing
  * @param
  * @description Get all baskets
  * @return Observable
  */
  getBasketListing() {
    return this.commonHttpService.get(this.apiUrls.basket.list);
  }

  /*
  * @name getBasketVehicleListing
  * @param
  * @description Get all basket vehicles
  * @return Observable
  */
  getBasketVehicleListing(params) {
    return this.commonHttpService.post(this.apiUrls.basket.vehicle, params);
  }

  /*
  * @name addUpdateBasket
  * @param
  * @description Add/Update basket
  * @return Observable
  */
  addUpdateBasket(params) {
    return this.commonHttpService.post(this.apiUrls.basket.add, params);
  }

  /*
  * @name deleteBasket
  * @param
  * @description Delete basket
  * @return Observable
  */
  deleteBasket(params) {
    return this.commonHttpService.post(this.apiUrls.basket.delete, params);
  }

  /*
  * @name addVehicle
  * @param
  * @description Add vehicle
  * @return Observable
  */
  addVehicle(params) {
    return this.commonHttpService.post(this.apiUrls.basket.addVehicle, params);
  }

  /*
  * @name deleteVehicle
  * @param
  * @description delete vehicle
  * @return Observable
  */
  deleteVehicle(params) {
    return this.commonHttpService.post(this.apiUrls.basket.delVehicle, params);
  }
}
