import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { CommonService } from '../../../services/common.service';
import { PermissionsService } from '../../../services/permissions.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { DataStoreService } from '../../../services/data-store.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  @Input('companyId') companyId: number;
  @Input('Offer') Offer: any;
  @Input('isEdit') isEdit: boolean = false
  public onCompanySubAdd: EventEmitter<any> = new EventEmitter();
  isProcessing: boolean = false;
  loadingPer: boolean = false
  loadingText: string = 'Loading products...'
  makArray: Array<string> = []
  permArray: Array<any> = []
  productsArray: Array<any> = []
  formError: any = {};
  subData: any = null
  public companyProductForm: FormGroup;

  startDate = new Date()
  endDate = new Date();
  toggleNewSub: Array<Number> = []
  validPermission: Array<any> = []
  format = 'yyyy-MM-dd'
  addSubModel = {
    AutoPredict: {
      isChecked: false,
      Permission_Id: [],
      TotalUsers: 5,
      Make: [],
      start_date: this.startDate,
      end_date: this.endDate
    },
    InstantValuation: {
      isChecked: false,
      TotalUsers: 5,
      TotalRequests: 300000,
      start_date: this.startDate,
      end_date: this.endDate
    },
  }
  openNewSub: boolean = false
  public autoProduct_Id = 1
  public ivProduct_Id = 2
  public AutoPredictName = 'AutoPredict'
  public InstantValuationName = 'InstantValuation'
  constructor(
    public companyService: CompanyService,
    public commonService: CommonService,
    public permissionService: PermissionsService,
    public dialog: MatDialog,
    public dp: DatePipe,
    public dataStore: DataStoreService
  ) { }

  ngOnInit() {
    this.endDate.setDate(this.endDate.getDate() + 365)
    this.getPermissions();
  }

  minSelectedCheckbox(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkbox
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  getFormDataControl(product, key, defaultValue) {
    if (this.subData != null) {
      if (product == this.autoProduct_Id) {
        if (Object.keys(this.subData.AutoPredict).length > 0) {
          let autpData = this.subData.AutoPredict
          if (key == 'isChecked') {
            return new FormControl(true, Validators.required)
          } else {
            if ('ProductSubscription' in autpData) {
              if (key == 'future') {

              } else if (key == 'end_date') {
                return new FormControl({ value: autpData['ProductSubscription'][key], disabled: false }, Validators.required)
              }
              else {
                return new FormControl({ value: autpData['ProductSubscription'][key], disabled: true }, Validators.required)
              }
            }
          }
        }
      } else if (product == this.ivProduct_Id) {
        if (Object.keys(this.subData.InstantValuation).length > 0) {
          let ivData = this.subData.InstantValuation
          if (key == 'isChecked') {
            return new FormControl(true, Validators.required)
          } else {
            if ('ProductSubscription' in ivData) {
              if (key == 'future') {

              } else if (key == 'end_date') {
                return new FormControl({ value: ivData['ProductSubscription'][key], disabled: false }, Validators.required)
              }
              else {
                return new FormControl({ value: ivData['ProductSubscription'][key], disabled: true }, Validators.required)
              }
            }
          }
        }
      }
    }
    return new FormControl(defaultValue)
  }

  getStartData(product, defaultValue) {
    if (this.subData != null) {
      if (product == this.autoProduct_Id) {
        if (Object.keys(this.subData.AutoPredict).length > 0) {
          let autpData = this.subData.AutoPredict
          if ('ProductSubscription' in autpData) {
            return new FormControl({ value: autpData['ProductSubscription']['start_date'], disabled: true })
          }
        }
      } else if (product == this.ivProduct_Id) {
        if (Object.keys(this.subData.InstantValuation).length > 0) {
          let ivData = this.subData.InstantValuation
          if ('ProductSubscription' in ivData) {
            return new FormControl({ value: ivData['ProductSubscription']['start_date'], disabled: true })
          }
        }
      }
    }
    return new FormControl(defaultValue)
  }

  checkSubMake(list: boolean = false) {
    let exists = false
    if (this.isEdit) {
      if (Object.keys(this.subData.AutoPredict).length > 0) {
        let autpData = this.subData.AutoPredict
        if ('ProductSubscription' in autpData) {
          if (list === false) {
            exists = true
          } else {
            exists = autpData['ProductSubscription']['Make'].join(', ')
          }
        }
      }
    }
    return exists
  }

  initForm() {
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 365);
    let autpStr = this.getStartData(this.autoProduct_Id, this.startDate)
    let ivStr = this.getStartData(this.ivProduct_Id, this.startDate)
    this.companyProductForm = new FormGroup({
      AutoPredict: new FormGroup({
        isChecked: this.getFormDataControl(this.autoProduct_Id, 'isChecked', false),
        Permission_Id: new FormArray(this.getPermissionsControls()),
        TotalUsers: this.getFormDataControl(this.autoProduct_Id, 'TotalUsers', 5),
        Make: this.getFormDataControl(this.autoProduct_Id, 'Make', []),
        start_date: autpStr,
        end_date: this.getFormDataControl(this.autoProduct_Id, 'end_date', this.endDate),
      }),
      InstantValuation: new FormGroup({
        isChecked: this.getFormDataControl(this.ivProduct_Id, 'isChecked', false),
        TotalUsers: this.getFormDataControl(this.ivProduct_Id, 'TotalUsers', 1000),
        TotalRequests: this.getFormDataControl(this.ivProduct_Id, 'TotalRequests', 300000),
        start_date: ivStr,
        end_date: this.getFormDataControl(this.ivProduct_Id, 'end_date', this.endDate),
      }),
    });
    //console.log(this.companyProductForm.controls)
    //console.log(this.companyProductForm.controls.AutoPredict['controls']['Permission_Id']['controls'])
    //console.log(this.companyProductForm.get('AutoPredict.TotalUsers').hasError('required'))
    //console.log(this.companyProductForm.get('AutoPredict.TotalUsers'))
    this.validPermission = this.getAUTPPermissions()

  }

  getNewSubPermissionControl() {
    //console.log(this.companyProductForm.controls.AutoPredict['controls']['newSubscription']['controls'])
    return this.companyProductForm.controls.AutoPredict['controls']['newSubscription']['controls']['Permission_Id']['controls']
  }

  getSubInputDate(Product_Id: number) {
    let date = new Date()
    if (Product_Id == this.autoProduct_Id) {
      if (this.checkSubExists(this.autoProduct_Id)) {
        //console.log(this.getControl('AutoPredict.end_date').value)
        let s_date = new Date(this.getControl('AutoPredict.end_date').value)
        s_date.setDate(s_date.getDate() + 1);
        date = s_date
        //var endDate = new Date(s_date);
        //endDate.setDate(endDate.getDate() + 365);
      } else {
        date = this.startDate
      }
    } else if (Product_Id == this.ivProduct_Id) {
      if (this.checkSubExists(this.ivProduct_Id)) {
        let s_date = new Date(this.getControl('InstantValuation.end_date').value)
        s_date.setDate(s_date.getDate() + 1);
        date = s_date
        //var endDate = new Date(s_date);
        //endDate.setDate(endDate.getDate() + 365);
      } else {
        date = this.startDate
      }
    }
    return date
  }

  setProduct(event, Product_Id) {
    if (Product_Id == this.autoProduct_Id) {
      if (event.checked == true) {
        this.getControl('AutoPredict.isChecked').setValue(true)
        this.getControl('AutoPredict.Permission_Id').setValidators([this.minSelectedCheckbox()])
        this.getControl('AutoPredict.Permission_Id').updateValueAndValidity();
        this.getControl('AutoPredict.TotalUsers').setValidators([Validators.required])
        this.getControl('AutoPredict.TotalUsers').updateValueAndValidity();
        this.getControl('AutoPredict.Make').setValidators([Validators.required])
        this.getControl('AutoPredict.Make').updateValueAndValidity();
        this.getControl('AutoPredict.start_date').setValidators([Validators.required])
        this.getControl('AutoPredict.start_date').updateValueAndValidity();
        this.getControl('AutoPredict.end_date').setValidators([Validators.required])
        this.getControl('AutoPredict.end_date').updateValueAndValidity();

      } else {
        if (this.isEdit && this.checkSubExists(Product_Id)) {
          //remove current subscription
          this.removeSubscription(this.autoProduct_Id)
        } else {
          this.getControl('AutoPredict.isChecked').setValue(false)
          this.getControl('AutoPredict.Permission_Id').setValidators(null)
          this.getControl('AutoPredict.Permission_Id').updateValueAndValidity();
          this.getControl('AutoPredict.TotalUsers').setValidators(null)
          this.getControl('AutoPredict.TotalUsers').updateValueAndValidity();
          this.getControl('AutoPredict.Make').setValidators(null)
          this.getControl('AutoPredict.Make').updateValueAndValidity();
          this.getControl('AutoPredict.start_date').setValidators(null)
          this.getControl('AutoPredict.start_date').updateValueAndValidity();
          this.getControl('AutoPredict.end_date').setValidators(null)
          this.getControl('AutoPredict.end_date').updateValueAndValidity();
        }
      }
    } else if (Product_Id == this.ivProduct_Id) {
      if (event.checked == true) {
        this.getControl('InstantValuation.isChecked').setValue(true)
        this.getControl('InstantValuation.TotalUsers').setValidators([Validators.required])
        this.getControl('InstantValuation.TotalUsers').updateValueAndValidity();
        this.getControl('InstantValuation.TotalRequests').setValidators([Validators.required])
        this.getControl('InstantValuation.TotalRequests').updateValueAndValidity();
        this.getControl('InstantValuation.start_date').setValidators([Validators.required])
        this.getControl('InstantValuation.start_date').updateValueAndValidity();
        this.getControl('InstantValuation.end_date').setValidators([Validators.required])
        this.getControl('InstantValuation.end_date').updateValueAndValidity();
      } else {
        if (this.isEdit && this.checkSubExists(Product_Id)) {
          //remove current subscription
          this.removeSubscription(this.ivProduct_Id)
        } else {
          this.getControl('InstantValuation.isChecked').setValue(false)
          this.getControl('InstantValuation.TotalUsers').setValidators(null)
          this.getControl('InstantValuation.TotalUsers').updateValueAndValidity();
          this.getControl('InstantValuation.TotalRequests').setValidators(null)
          this.getControl('InstantValuation.TotalRequests').updateValueAndValidity();
          this.getControl('InstantValuation.start_date').setValidators(null)
          this.getControl('InstantValuation.start_date').updateValueAndValidity();
          this.getControl('InstantValuation.end_date').setValidators(null)
          this.getControl('InstantValuation.end_date').updateValueAndValidity();
        }
      }
    }
  }

  saveProducts(pForm) {
    if (pForm.valid) {
      //console.log(pForm)
      if (this.isEdit) {
        this.updateProduct(pForm)
      } else {
        this.addProduct(pForm)
      }
    } else {
      //this.formValidate(pForm)
      //console.log('invalid form')
      //console.log(pForm)
      this.commonService.showSnakeBar("Please correct the errors.")
    }
  }

  getSubmitFormData(Product_Id, newSub: boolean = false) {
    let formData = {}
    if (Product_Id == this.autoProduct_Id) {
      if (newSub === false) {
        let selectedPermissions: Array<Number> = this.getControl('AutoPredict.Permission_Id').value
        selectedPermissions = selectedPermissions.map((checked, index) => checked ? this.validPermission[index].key : null)
          .filter(value => value !== null);
        if (selectedPermissions.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one permission.")
          return false
        }
        //console.log(selectedPermissions)
        let copyPermissions = selectedPermissions.slice()

        let Makes: Array<string> = this.getControl('AutoPredict.Make').value
        if (Makes.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one make.")
          return false
        }
        let copyMakes = Makes.slice()
        formData = {
          'TotalUsers': this.getControl('AutoPredict.TotalUsers').value,
          'Permission_Id': copyPermissions.join(","),
          'Make': copyMakes.join(","),
          'start_date': this.dp.transform(this.getControl('AutoPredict.start_date').value, this.format),
          'end_date': this.dp.transform(this.getControl('AutoPredict.end_date').value, this.format)
        }
      } else {
        let selectedPermissions: Array<Number> = this.getControl('AutoPredict.newSubscription.Permission_Id').value
        selectedPermissions = selectedPermissions.map((checked, index) => checked ? this.validPermission[index].key : null)
          .filter(value => value !== null);
        if (selectedPermissions.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one permission for new subscription.")
          return false
        }
        //console.log(selectedPermissions)
        let copyPermissions = selectedPermissions.slice()

        let Makes: Array<string> = this.getControl('AutoPredict.newSubscription.Make').value
        if (Makes.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one make for new subscription.")
          return false
        }
        let copyMakes = Makes.slice()
        formData = {
          'TotalUsers': this.getControl('AutoPredict.newSubscription.TotalUsers').value,
          'Permission_Id': copyPermissions.join(","),
          'Make': copyMakes.join(","),
          'start_date': this.dp.transform(this.getControl('AutoPredict.newSubscription.start_date').value, this.format),
          'end_date': this.dp.transform(this.getControl('AutoPredict.newSubscription.end_date').value, this.format)
        }
      }

    } else if (Product_Id == this.ivProduct_Id) {
      if (newSub === false) {
        formData = {
          'start_date': this.dp.transform(this.getControl('InstantValuation.start_date').value, this.format),
          'end_date': this.dp.transform(this.getControl('InstantValuation.end_date').value, this.format),
          'TotalUsers': this.getControl('InstantValuation.TotalUsers').value,
          'TotalRequests': this.getControl('InstantValuation.TotalRequests').value,
        }
      } else {
        formData = {
          'start_date': this.dp.transform(this.getControl('InstantValuation.newSubscription.start_date').value, this.format),
          'end_date': this.dp.transform(this.getControl('InstantValuation.newSubscription.end_date').value, this.format),
          'TotalUsers': this.getControl('InstantValuation.newSubscription.TotalUsers').value,
          'TotalRequests': this.getControl('InstantValuation.newSubscription.TotalRequests').value,
        }
      }
    }
    return formData
  }

  addProduct(pForm) {
    if (pForm.valid) {
      if (this.InstantValuationChecked.value == true || this.AutoPredictChecked.value == true) {
        //console.log(pForm.controls['AutoPredict'].value)
        //console.log(pForm.controls['InstantValuation'].value)
        let formData = Object.assign({}, { 'companyId': this.companyId })
        if (this.AutoPredictChecked.value == true) {
          let autpData = this.getSubmitFormData(this.autoProduct_Id)
          if (autpData == false) {
            return false
          }
          formData['AutoPredict'] = autpData
        }
        if (this.InstantValuationChecked.value == true) {
          let ivData = this.getSubmitFormData(this.ivProduct_Id)
          if (ivData == false) {
            return false
          }
          formData['InstantValuation'] = ivData
        }
        this.isProcessing = true;

        this.companyService.addSubscription(formData).subscribe(res => {
          try {
            const response = res.json();
            //console.log(response)
            const obj = {
              data: res,
              companyAdded: true,
              companyUpdated: false,
              companyStatusChange: false
            }
            this.dataStore.sendCompanyListSignal(obj);
            //this.onCompanySubAdd.emit(response.data);
            this.commonService.showSnakeBar(response.message);
            this.closeModal();
          }
          catch (e) {
            //console.log(e)
            this.commonService.commonSnakeBar();
          }
          this.isProcessing = false;
        }, (err) => {
          try {
            let response = err.json();
            this.isProcessing = false;
            if (response.message) {
              if ('errors' in response.errors) {
                this.handleServerFormError(response.errors)
              }
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          } catch (e) {
            this.commonService.commonSnakeBar();
          }
        }, () => {
          this.isProcessing = false;
        })
      } else {
        this.commonService.showSnakeBar("Please choose at least one product.")
      }
    } else {
      //this.formValidate(pForm)
      //console.log('invalid form')
      this.commonService.showSnakeBar("Please correct the errors.")
    }
  }

  getUpdateSubFormData() {
    let formData = Object.assign({}, { 'companyId': this.companyId })
    if (this.checkSubExists(this.autoProduct_Id)) {
      if (this.AutoPredictChecked.value == true) {
        formData['AutoPredict_New'] = false
        formData['AutoPredict'] = {
          'end_date': this.dp.transform(this.getControl('AutoPredict.end_date').value, this.format)
        }
        if (this.toggleNewSub.indexOf(this.autoProduct_Id) != -1) {
          let autpData = this.getSubmitFormData(this.autoProduct_Id, true)
          if (autpData == false) {
            return false
          }
          formData['AutoPredict']['newSub'] = autpData
        }
      }
    } else {
      if (this.AutoPredictChecked.value == true) {
        formData['AutoPredict_New'] = true
        let autpData = this.getSubmitFormData(this.autoProduct_Id)
        if (autpData == false) {
          return false
        }
        formData['AutoPredict'] = autpData
      }
    }
    if (this.checkSubExists(this.ivProduct_Id)) {
      if (this.InstantValuationChecked.value == true) {
        formData['InstantValuation_New'] = false
        formData['InstantValuation'] = {
          'end_date': this.dp.transform(this.getControl('InstantValuation.end_date').value, this.format),
        }
        if (this.toggleNewSub.indexOf(this.ivProduct_Id) != -1) {
          let newSub = this.getSubmitFormData(this.ivProduct_Id, true)
          if (newSub == false) {
            return false
          }
          formData['InstantValuation']['newSub'] = newSub
        }
      }
    } else {
      if (this.InstantValuationChecked.value == true) {
        formData['InstantValuation_New'] = true
        let ivData = this.getSubmitFormData(this.ivProduct_Id)
        if (ivData == false) {
          return ivData
        }
        formData['InstantValuation'] = ivData
      }
    }
    return formData
  }

  updateProduct(pForm) {
    if (pForm.valid) {
      if (this.InstantValuationChecked.value == true || this.AutoPredictChecked.value == true) {
        //console.log(pForm.controls['AutoPredict'].value)
        //console.log(pForm.controls['InstantValuation'].value)
        let formData = Object.assign({}, this.getUpdateSubFormData())
        //formData['companyId'] = this.companyId

        this.isProcessing = true;

        this.companyService.updateSubscription(formData).subscribe(res => {
          try {
            const response = res.json();
            //console.log(response)
            this.reloadCompanyListing(response)
            //this.onCompanySubAdd.emit(response.data);
            this.commonService.showSnakeBar(response.message);
            this.closeModal();
          }
          catch (e) {
            //console.log(e)
            this.commonService.commonSnakeBar();
          }
          this.isProcessing = false;
        }, (err) => {
          try {
            let response = err.json();
            this.isProcessing = false;
            if (response.message) {
              if ('errors' in response.errors) {
                this.handleServerFormError(response.errors)
              }
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          } catch (e) {
            this.commonService.commonSnakeBar();
          }
        }, () => {
          this.isProcessing = false;
        })
      } else {
        this.commonService.showSnakeBar("Please choose at least one product.")
      }
    } else {
      //this.formValidate(pForm)
      //console.log('invalid form')
      this.commonService.showSnakeBar("Please correct the errors.")
    }
  }

  get AutoPredictChecked() {
    return this.companyProductForm.get('AutoPredict.isChecked') as FormControl;
  }

  get InstantValuationChecked() {
    return this.companyProductForm.get('InstantValuation.isChecked') as FormControl;
  }

  getPermissionsControls(newSub: boolean = false) {
    let Permission_Id = []
    let isAutpSub = false
    //show selected controls if subscription exists
    if (this.subData != null) {
      if (Object.keys(this.subData.AutoPredict).length > 0) {
        let autpData = this.subData.AutoPredict
        if ('ProductSubscription' in autpData) {
          Permission_Id = autpData['ProductSubscription']['Permission_Id']
          isAutpSub = true
        }
      }
    }
    return this.getAUTPPermissions().map(control => {
      //console.log(control)
      //show selected controls  and disable other ones if subscription exists
      if (isAutpSub == true && newSub == false) {
        if (Permission_Id.indexOf(control['key']) != -1) {
          return new FormControl({ value: true, disabled: true })
        }
        return new FormControl({ value: false, disabled: true })
      } else {
        return new FormControl({ value: false, disabled: false })
      }
    })
  }

  getAUTPPermissions() {
    return this.permArray
  }

  getControlError(path, type: string = 'required') {
    return this.companyProductForm.get(path).hasError(type)
  }

  checkControlDirty(path) {
    return this.companyProductForm.get(path).invalid && (this.companyProductForm.get(path).pristine || this.companyProductForm.get(path).touched)
  }

  checkControlError(path, pForm, errorType: string = 'required') {
    return this.getControlError(path, errorType) && (this.checkControlDirty(path))
  }

  getControl(path) {
    return this.companyProductForm.get(path) as FormControl
  }

  addNewSub(Product_Id) {
    let index = this.toggleNewSub.indexOf(Product_Id)
    //console.log(this.toggleNewSub, index, Product_Id)
    if (index == -1) {
      this.toggleNewSub.push(Product_Id)
      if (Product_Id == this.autoProduct_Id) {
        // future subscription must be after the current end date
        let startDate = this.getSubInputDate(this.autoProduct_Id)
        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 365);
        let autpControl = this.companyProductForm.get('AutoPredict') as FormGroup
        autpControl.addControl('newSubscription',
          new FormGroup({
            isNew: new FormControl(true),
            Permission_Id: new FormArray(this.getPermissionsControls(true), [this.minSelectedCheckbox()]),
            TotalUsers: new FormControl(5, [Validators.required]),
            Make: new FormControl([], [Validators.required]),
            start_date: new FormControl(startDate, [Validators.required]),
            end_date: new FormControl(endDate, [Validators.required]),
          })
        );
      } else if (Product_Id == this.ivProduct_Id) {
        // future subscription must be after the current end date
        let startDate = this.getSubInputDate(this.ivProduct_Id)
        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 365);
        let ivControl = this.companyProductForm.get('InstantValuation') as FormGroup
        ivControl.addControl('newSubscription', new FormGroup({
          isNew: new FormControl(true),
          TotalUsers: new FormControl(1000, [Validators.required]),
          TotalRequests: new FormControl(300000, [Validators.required]),
          start_date: new FormControl(startDate, [Validators.required]),
          end_date: new FormControl(endDate, [Validators.required]),
        }))
      }
    } else {
      this.toggleNewSub.splice(index, 1)
      if (Product_Id == this.autoProduct_Id) {
        let autpControl = this.companyProductForm.get('AutoPredict') as FormGroup
        autpControl.removeControl('newSubscription')
      } else if (Product_Id == this.ivProduct_Id) {
        let ivControl = this.companyProductForm.get('InstantValuation') as FormGroup
        ivControl.removeControl('newSubscription')
      }
    }
    //console.log(Product_Id, this.toggleNewSub)
  }

  checkSubExists(Product_Id) {
    if (this.subData != null) {
      if (Product_Id == this.autoProduct_Id) {
        if (Object.keys(this.subData.AutoPredict).length > 0) {
          if ('ProductSubscription' in this.subData.AutoPredict) {
            return true
          }

        }
      } else if (Product_Id == this.ivProduct_Id) {
        if (Object.keys(this.subData.InstantValuation).length > 0) {
          if ('ProductSubscription' in this.subData.InstantValuation) {
            return true
          }
        }
      }
    }
    return false
  }

  reloadCompanyListing(res) {
    const obj = {
      data: res,
      companyAdded: true,
      companyUpdated: false,
      companyStatusChange: false
    }
    this.dataStore.sendCompanyListSignal(obj);
  }

  removeSubscription(Product_Id) {
    if (this.isEdit && this.companyId) {
      const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          title: 'Are you sure you want to delete all the subscription?'
        }
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result == 'yes') {
          this.loadingPer = true
          this.loadingText = "Removing subscription.."
          this.companyService.removeCompanySubscription(this.companyId, Product_Id).subscribe(res => {
            this.loadingPer = false
            this.loadingText = "Loading products..."
            try {
              const response = res.json();
              this.subData = response['data']

              this.reloadCompanyListing(response)

              this.commonService.showSnakeBar(response.message);
              this.initForm()
            }
            catch (e) {
              console.log(e)
              this.commonService.commonSnakeBar();
            }
            this.isProcessing = false;
          }, (err) => {
            this.loadingText = "Loading products..."
            this.loadingPer = false
            try {
              let response = err.json();
              this.isProcessing = false;
              if (response.message) {
                if ('errors' in response.errors) {
                  this.handleServerFormError(response.errors)
                }
                this.commonService.showSnakeBar(response.message);
              } else {
                this.commonService.commonSnakeBar();
              }
            }
            catch (e) {
              console.log(e)
              this.commonService.commonSnakeBar();
            }
          }, () => {
            this.isProcessing = false;
          })
        } else {
          if (Product_Id == this.autoProduct_Id) {
            this.AutoPredictChecked.setValue(true)
          } else if (Product_Id == this.ivProduct_Id) {
            this.InstantValuationChecked.setValue(true)
          }
        }
      });
    }
  }

  getPermissions() {
    this.loadingPer = true

    this.permissionService.getProductAttributesList().subscribe(res => {
      this.loadingPer = false
      try {
        const response = res.json();
        this.makArray = response['data']['makes']
        this.permArray = response['data']['permissions']
        this.productsArray = response['data']['products']
        if (this.isEdit) {
          this.getSubscriptions()
        } else {
          this.initForm()
        }
      }
      catch (e) {
        console.log(e)
        //this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      this.loadingPer = false
      try {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      }
      catch (e) {
        console.log(e)
        //this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  getSubscriptions() {
    if (this.isEdit && this.companyId) {
      this.loadingPer = true
      this.loadingText = "Loading subscriptions.."
      this.companyService.getCompanySubscription(this.companyId).subscribe(res => {
        this.loadingPer = false
        this.loadingText = "Loading products..."
        try {
          const response = res.json();
          this.subData = response['data']
          this.initForm()
        }
        catch (e) {
          console.log(e)
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        this.loadingText = "Loading products..."
        this.loadingPer = false
        try {
          let response = err.json();
          this.isProcessing = false;
          if (response.message) {
            if ('errors' in response.errors) {
              this.handleServerFormError(response.errors)
            }
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
        catch (e) {
          console.log(e)
          this.commonService.commonSnakeBar();
        }
      }, () => {
        this.isProcessing = false;
      })
    }
  }

  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  closeModal() {
    this.dialog.closeAll();
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (key == 'p_ids') {
          this.formError['permission'] = errors[key]
        }
        /*if (this.companyProductForm.contains(key)) {
          let control = this.companyProductForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }*/
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }
}
