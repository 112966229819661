import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'new-vehicles',
  templateUrl: './new-vehicles.component.html',
  styleUrls: ['./new-vehicles.component.scss']
})
export class NewVehiclesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
