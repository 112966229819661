/*
* @ngdoc Service
* @name app.services.DataStoreService
* @description
* This service is used to handle the task related to temporary storing the data as Observable
* */
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  private filterSignal = new Subject<any>();
  private selectedFilters = new Subject<any>();
  private filterList = new Subject<any>();
  private loadVehicleSignal = new Subject<any>();
  private resetVehicleListSignal = new Subject<any>();
  private reloadFilterListSignal = new Subject<any>();
  private resetVehicleListFilterSignal = new Subject<any>();
  private selectedInitFilters = new Subject<any>();
  private selectedCarData = new Subject<any>();
  private vehicleDetailsReloadSignal = new Subject<any>();

  private companyListSignal = new Subject<any>();
  private userListSignal = new Subject<any>()

  private reloadRVHistorySignal = new Subject<any>();
  private resetRVHistorySignal = new Subject<any>();

  private noRecTitle = new Subject<any>();
  private acceptVehicle = new Subject<any>();
  private selectedNewVehicle = new Subject<any>();
  private selectedNewVehicles = new Subject<any>();
  private selectedRecVehicles = new Subject<any>();
  private veLoaderSignal = new Subject<any>();
  private capturedVehicle = new Subject<any>();
  private adminUserListSignal = new Subject<any>();

  constructor() {
    let ctrl = this;
  }

  sendFilterSelectSignal(filter, selected = "") {
    this.filterSignal.next({ filter: filter, selected: selected });
  }

  getFilterSelectSignal(): Observable<any> {
    return this.filterSignal.asObservable();
  }

  sendCompanyListSignal(data) {
    this.companyListSignal.next(data);
  }

  getCompanyListSignal(): Observable<any> {
    return this.companyListSignal.asObservable();
  }

  sendAdminUserListSignal(data) {
    this.adminUserListSignal.next(data);
  }

  getAdminUserListSignal(): Observable<any> {
    return this.adminUserListSignal.asObservable();
  }

  sendUserListSignal() {
    this.userListSignal.next();
  }

  getUserListSignal(): Observable<any> {
    return this.userListSignal.asObservable();
  }

  clearFilterSelectSignal() {
    this.filterSignal.next();
  }

  /*
  * @name setInitFilterSelectSignal
  * @param filter:Object
  * @description set selected filter for vehicle for initial screen
  * @return none
  */
  setInitFilterSelectSignal(filter, selected = "") {
    this.selectedInitFilters.next({ filter: filter, selected: selected });
  }

  /*
  * @name getInitFilterSelectSignal
  * @param none
  * @description get selected filter for vehicle for initial screen
  * @return Observable
  */
  getInitFilterSelectSignal(): Observable<any> {
    return this.selectedInitFilters.asObservable();
  }

  /*
  * @name clearInitclearFilterSelectSignal
  * @param none
  * @description clear selected filter for vehicle for initial screen
  * @return Observable
  */
  clearInitclearFilterSelectSignal() {
    this.selectedInitFilters.next();
  }

  /*
  * @name setSelectedFilters
  * @param filter:Object
  * @description set selected filter for vehicle
  * @return none
  */
  setSelectedFilters(filters = {}) {
    this.selectedFilters.next({ filters: filters });
  }

  /*
  * @name getSelectedFilters
  * @param none
  * @description get selected filter for vehicle
  * @return Observable
  */
  getSelectedFilters(): Observable<any> {
    return this.selectedFilters.asObservable();
  }

  /*
  * @name clearSelectedFilters
  * @param none
  * @description clear the selected filter for vehicle
  * @return Observable
  */
  clearSelectedFilters() {
    this.selectedFilters.next();
  }

  /*
  * @name setFiltersList
  * @param filter:Object
  * @description set loaded filter list
  * @return none
  */
  setFiltersList(filters = {}) {
    this.filterList.next({ filters: filters });
  }

  /*
  * @name getFiltersList
  * @param none
  * @description get loaded filter list
  * @return Observable
  */
  getFiltersList(): Observable<any> {
    return this.filterList.asObservable();
  }

  /*
  * @name clearFiltersList
  * @param none
  * @description clear all filter for vehicle
  * @return Observable
  */
  clearFiltersList() {
    this.filterList.next();
  }

  /*
  * @name loadVehicleList
  * @param none
  * @description load vehicle list
  * @return Observable
  */
  loadVehicleList(type = 'list') {
    this.loadVehicleSignal.next({ load: true, type: type });
  }

  /*
  * @name checkLoadingVehicleList
  * @param none
  * @description check signal for vehicle loading
  * @return Observable
  */
  checkLoadingVehicleList(): Observable<any> {
    return this.loadVehicleSignal.asObservable();
  }


  /*
  * @name clearLoadingVehicleList
  * @param none
  * @description clear loading vehicle signal
  * @return Observable
  */
  clearLoadingVehicleList() {
    this.loadVehicleSignal.next();
  }

  /*
  * @name resetVehicleList
  * @param none
  * @description reset vehicle pagination
  * @return Observable
  */
  resetVehicleList() {
    this.resetVehicleListSignal.next({ reset: true });
  }

  /*
  * @name checkLoadingVehicleList
  * @param none
  * @description check reset vehicle list pagination
  * @return Observable
  */
  checkResetVehicleList(): Observable<any> {
    return this.resetVehicleListSignal.asObservable();
  }

  /*
  * @name resetVehicleList
  * @param none
  * @description reset vehicle pagination
  * @return Observable
  */
  resetVehicleFilterList() {
    this.resetVehicleListFilterSignal.next({ reset: true });
  }

  /*
  * @name checkLoadingVehicleList
  * @param none
  * @description check reset vehicle list pagination
  * @return Observable
  */
  checkResetVehicleFilterList(): Observable<any> {
    return this.resetVehicleListSignal.asObservable();
  }


  /*
  * @name clearLoadingVehicleList
  * @param none
  * @description clear vehicle resetting listing
  * @return Observable
  */
  clearResetVehicleFilterList() {
    this.resetVehicleListFilterSignal.next();
  }


  /*
  * @name clearLoadingVehicleList
  * @param none
  * @description clear vehicle resetting listing
  * @return Observable
  */
  clearResetVehicleList() {
    this.resetVehicleListSignal.next();
  }


  /*
  * @name reloadFilterList
  * @param none
  * @description reload filter list
  * @return Observable
  */
  reloadFilterList() {
    this.reloadFilterListSignal.next({ reset: true });
  }

  /*
  * @name checkReloadFilterList
  * @param none
  * @description check reload filter list
  * @return Observable
  */
  checkReloadFilterList(): Observable<any> {
    return this.reloadFilterListSignal.asObservable();
  }


  /*
  * @name clearReloadFilterList
  * @param none
  * @description clear reload filter list
  * @return Observable
  */
  clearReloadFilterList() {
    this.reloadFilterListSignal.next();
  }


  /*
  * @name reloadRVHistoryListDatiumAssetKey
  * @param none
  * @description reload/load the rv history for give Datium Asset Key
  * @return Observable
  */
  reloadRVHistoryListDatiumAssetKey(DatiumAssetKey) {
    this.reloadRVHistorySignal.next({ DatiumAssetKey: DatiumAssetKey });
  }

  /*
  * @name checkRVHistoryListDatiumAssetKey
  * @param none
  * @description check to reload/load the rv history for give Datium Asset Key
  * @return Observable
  */
  checkRVHistoryListDatiumAssetKey(): Observable<any> {
    return this.reloadRVHistorySignal.asObservable();
  }


  /*
  * @name clearRVHistoryListDatiumAssetKey
  * @param none
  * @description clear reload/load the rv history for give Datium Asset Key
  * @return Observable
  */
  clearRVHistoryListDatiumAssetKey() {
    this.reloadRVHistorySignal.next();
  }

  /*
  * @name resetRVHistoryListDatiumAssetKey
  * @param none
  * @description reset the rv history for give Datium Asset Key
  * @return Observable
  */
  resetRVHistoryListDatiumAssetKey(DatiumAssetKey, reset = true, cancel = false) {
    this.resetRVHistorySignal.next({ DatiumAssetKey: DatiumAssetKey, reset: reset, cancel: cancel });
  }

  /*
  * @name checkResetRVHistoryListDatiumAssetKey
  * @param none
  * @description check to reload/load the rv history for give Datium Asset Key
  * @return Observable
  */
  checkResetRVHistoryListDatiumAssetKey(): Observable<any> {
    return this.resetRVHistorySignal.asObservable();
  }


  /*
  * @name clearVehicleDetailsReload
  * @param none
  * @description clear reload/load the vehicle details for give Datium Asset Key
  * @return Observable
  */
  clearVehicleDetailsReload() {
    this.vehicleDetailsReloadSignal.next();
  }

  /*
  * @name resetVehicleDetailsReload
  * @param none
  * @description reset the vehicle details for give Datium Asset Key
  * @return Observable
  */
  resetVehicleDetailsReload(DatiumAssetKey, reset = true, cancel = false, newScreen = false) {
    this.vehicleDetailsReloadSignal.next({ DatiumAssetKey: DatiumAssetKey, reset: reset, cancel: cancel, newScreen: newScreen });
  }

  /*
  * @name checkVehicleDetailsReload
  * @param none
  * @description check to reload/load the vehicle details for give Datium Asset Key
  * @return Observable
  */
  checkVehicleDetailsReload(): Observable<any> {
    return this.vehicleDetailsReloadSignal.asObservable();
  }

  /*
  * @name clearDataRVResetHistoryListDatiumAssetKey
  * @param none
  * @description clear reload/load the rv history for give Datium Asset Key
  * @return Observable
  */
  clearDataRVResetHistoryListDatiumAssetKey() {
    this.resetRVHistorySignal.next();
  }

  /*
  * @name updateSelectedCarData
  * @param filter:Object
  * @description Update selected data store on selection/de-selection
  * @return none
  */
  setSelectedCarData(data = {}) {
    this.selectedCarData.next(data);
  }

  /*
  * @name getSelectedCarData
  * @param none
  * @description get selected data store from selected/de-selected
  * @return Observable
  */
  getSelectedCarData(): Observable<any> {
    return this.selectedCarData.asObservable();
  }

  /*
  * @name getSelectedCarData
  * @param none
  * @description get selected data store from selected/de-selected
  * @return Observable
  */
  getSelectedCarDataLen() {//: Observable<any> {
    //console.log(this.selectedCarData.)
    //return this.selectedCarData.asObservable();
  }


  /*
  * @name clearNoRecTitle
  * @param none
  * @description clear No record title for vehicle list - Global
  * @return Observable
  */
  clearNoRecTitle() {
    this.noRecTitle.next();
  }

  /*
  * @name updateSelectedCarData
  * @param filter:Object
  * @description Set No record title for vehicle list - Global
  * @return none
  */
  setNoRecTitle(from: string = 'list', title: string = 'No record(s) found') {
    this.noRecTitle.next({ from: from, title: title });
  }

  /*
  * @name getNoRecTitle
  * @param none
  * @description Get No record title for vehicle list - Global
  * @return Observable
  */
  getNoRecTitle(): Observable<any> {
    return this.noRecTitle.asObservable();
  }


  /*
 * @name clearAcceptVehicle
 * @param none
 * @description clear admin accept vehicle
 * @return Observable
 */
  clearAcceptVehicle() {
    this.acceptVehicle.next();
  }
  /*
  * @name checkAcceptVehicle
  * @param filter:Object
  * @description check admin accept vehicle
  * @return none
  */
  setAcceptVehicle(data = {}) {
    this.acceptVehicle.next(data);
  }

  /*
  * @name setAcceptVehicle
  * @param none
  * @description check set admin accept vehicle
  * @return Observable
  */
  checkAcceptVehicle(): Observable<any> {
    return this.acceptVehicle.asObservable();
  }


  /*
  * @name setSelectedNewVehicle
  * @param filter:Object
  * @description Update selected data store on selection/de-selection  for new vehicle screen
  * @return none
  */
  setSelectedNewVehicle(data = {}) {
    this.selectedNewVehicle.next(data);
  }

  /*
  * @name getSelectedNewVehicle
  * @param none
  * @description get selected data store from selected/de-selected for new vehicle screen
  * @return Observable
  */
  getSelectedNewVehicle(): Observable<any> {
    return this.selectedNewVehicle.asObservable();
  }


  /*
  * @name setSelectedNewVehicles
  * @param filter:Object
  * @description Update selected data store on accepting/rejecting for new vehicle screen
  * @return none
  */
  setSelectedNewVehicles(data = {}) {
    this.selectedNewVehicles.next(data);
  }

  /*
  * @name getSelectedNewVehicles
  * @param none
  * @description get selected data store from selected/de-selected for new vehicle screen
  * @return Observable
  */
  getSelectedNewVehicles(): Observable<any> {
    return this.selectedNewVehicles.asObservable();
  }

  /*
  * @name setVehicleRec
  * @param none
  * @description set recom vehicle
  * @return Observable
  */
  setVehicleRec(data = {}) {
    this.selectedRecVehicles.next(data);
  }

  /*
  * @name getVehicleRec
  * @param none
  * @description get action for recom
  * @return Observable
  */
  getVehicleRec(): Observable<any> {
    return this.selectedRecVehicles.asObservable();
  }


  /*
  * @name getVeLoader
  * @param none
  * @description Get ve loader
  * @return Observable
  */
  getVeLoader(): Observable<any> {
    return this.veLoaderSignal.asObservable();
  }


  /*
  * @name clearVeLoader
  * @param none
  * @description clear ve loader
  * @return Observable
  */
  clearVeLoader() {
    this.veLoaderSignal.next();
  }

  /*
  * @name setVeLoader
  * @param none
  * @description set ve loader
  * @return Observable
  */
  setVeLoader(DatiumAssetKey: string = "", toggle: boolean = false, text: string = 'Loading...') {
    this.veLoaderSignal.next({ DatiumAssetKey: DatiumAssetKey, toggle: toggle, text: text });
  }

  /*
  * @name clearCapturedVehicle
  * @param none
  * @description clear DatiumAssetKey for capturing rv index
  * @return Observable
  */
  clearCapturedVehicle() {
    this.capturedVehicle.next();
  }

  /*
  * @name setCapturedVehicle
  * @param DatiumAssetKey:String
  * @description set DatiumAssetKey for capturing rv index
  * @return none
  */
  setCapturedVehicle(DatiumAssetKey: string) {
    this.capturedVehicle.next(DatiumAssetKey);
  }

  /*
  * @name getCapturedVehicle
  * @param none
  * @description get DatiumAssetKey for capturing rv index
  * @return Observable
  */
  getCapturedVehicle(): Observable<any> {
    return this.capturedVehicle.asObservable();
  }
}
