import { Component, OnInit, Input } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { CommonService } from '../../../services/common.service';
import { AddRvValuesComponent } from 'src/app/shared/add-rv-values/add-rv-values.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'vehicle-rv-wrapper',
  templateUrl: './vehicle-rv-wrapper.component.html',
  styleUrls: ['./vehicle-rv-wrapper.component.scss']
})
export class VehicleRvWrapperComponent implements OnInit {

  @Input() isRvValues: string;
  @Input() vehicleId;
  @Input() vehicle;
  @Input() linkedVehicle;

  constructor(
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  addRvValues(vehicle = {}): void {
    const dialogRef = this.dialog.open(AddRvValuesComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        vehicle: vehicle
      }
    });
  }

}
