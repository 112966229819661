import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-quick-search-vehicle-details',
  templateUrl: './quick-search-vehicle-details.component.html',
  styleUrls: ['./quick-search-vehicle-details.component.scss']
})
export class QuickSearchVehicleDetailsComponent implements OnInit {

  @Input() vehicle: any = undefined;
  @Input() elClass: any = '';
  @Input() offset: number = 0;
  //@Input() show:boolean=false;
  constructor(
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
  }

  index = ['12_30000',
    '24_60000',
    '36_90000',
    '48_120000',
    '60_150000']
  pValues = [];
  dValues = [];
  p1Values = [];
  d1Values = [];
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes, 'changes');
    if (changes['vehicle'] && changes['vehicle']['currentValue']) {
      this.pValues = [];
      this.dValues = [];
      this.p1Values = [];
      this.d1Values = [];
      let values = changes['vehicle']['currentValue'];
      if (!this.roleCheckService.isSuperAdmin()) {
        if (values['rvValues'] && values['rvValues']['dollarValue'] && values['rvValues']['percentageValue']) {
          let rvD = values['rvValues']['dollarValue'];
          let rvP = values['rvValues']['percentageValue'];

          this.index.map(el => {
            this.pValues.push(rvD[el]['formatted']);
            this.dValues.push(rvP[el]['formatted']);
          })
        }
      } else {
        if (values['dmValues'] && values['dmValues']['dollarValue'] && values['dmValues']['percentageValue']) {
          let rvD = values['dmValues']['dollarValue'];
          let rvP = values['dmValues']['percentageValue'];
          this.index.map(el => {
            this.p1Values.push(rvD[el]['formatted']);
            this.d1Values.push(rvP[el]['formatted']);
          })
        }
      }
      // console.log(this.commonService.toggleIndex(changes['vehicle'], 'accepted'), 'accept');
      if (values['diValues'] && this.commonService.toggleIndex(changes['vehicle'], 'accepted') && values['diValues']['dollarValue'] && values['diValues']['percentageValue']) {
        let rvD = values['diValues']['dollarValue'];
        let rvP = values['diValues']['percentageValue'];
        this.index.map(el => {
          if (!this.roleCheckService.isSuperAdmin()) {
            this.p1Values.push(rvD[el]['formatted']);
            this.d1Values.push(rvP[el]['formatted']);
          } else {
            this.pValues.push(rvD[el]['formatted']);
            this.dValues.push(rvP[el]['formatted']);
          }
        })
      }
      // console.log(this.pValues, this.dValues);
      // console.log(this.p1Values, this.d1Values);
    } else {

    }
  }
}
