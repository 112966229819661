/*
* @ngdoc Services
* @name app.services.CommonHttp
* @description
* This service is act as a wrapper for making all type http requests.
* */

import { Injectable } from '@angular/core';
import { Http, Response, Headers, URLSearchParams } from '@angular/http';
import { CommonService, TOKEN_NAME, TOKEN_HEADER_KEY, AUTH_PREFIX, DM_PREFIX } from './common.service';
import { Observable } from 'rxjs';

import { HttpInterceptor } from "./http-interceptor";
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  baseUrl: String = '';
  private headers = new Headers({ 'Content-Type': 'application/json' });
  //private headers = new Headers();
  private azureHeader = new Headers({ 'x-ms-blob-type': 'BlockBlob' });
  constructor(
    private commonService: CommonService,
    private http: HttpInterceptor,
    private jwtService: JwtService
  ) {
    this.baseUrl = this.commonService.getApiBaseUrl();
  }

  /**
     * Check whether an object is a number.
     *
     * @param  {Object} object - Object to check numerically on.
     * @return {Boolean} True if number, false otherwise.
     */
  isNumeric = function (object) {
    return !isNaN(parseFloat(object)) && isFinite(object);
  };

  /**
   * Generate options based on arguments passed.
   * If the object passed is :
   *    - An object : Use it directly.
   *    - Something else : Throw an error.
   *
   * @param  {Object} args - Javascript object or string as the name of the resource (singular).
   * @return {Object} Options to pass to $resource.
   */
  sanitizeOptions(resourceUri) {
    var options = {
      url: ''
    };
    if (typeof resourceUri === 'string' && (resourceUri.indexOf("/") >= 0)) {
      options.url = resourceUri;
      return options;
    } else if (resourceUri.indexOf("/") < 0 && typeof resourceUri === 'string') {
      options.url = resourceUri + '/:id/:action';
      return options;
    } else {
      throw new Error('resourceUri is not a valid options');
    }
  };

  /**
   * Transform data after querying the server.
   * If the response contains an object (instead of a query) with the resource namespace in plural :
   *
   * new BaseResource() => Check for the key users
   *
   * than attach to each object the Resource object. This is a particular case
   * mostly used in pagination scenario.
   *
   * @param {Object} data - Data to send.
   * @return {String} Stringify data.
   */
  transformResponse(response) {
    var transformedResponse = {};
    response = response ? response.json() : {};

    if (response.hasOwnProperty('data')) {
      transformedResponse['data'] = response.data;
    } else {
      transformedResponse['data'] = response;
    }
    if (response.hasOwnProperty('meta')) {
      transformedResponse['meta'] = response.meta;
    }
    return transformedResponse;
  };

  reloadHeader(cache: boolean = false) {
    if (this.jwtService.IsAuthUser()) {
      const token = this.jwtService.getAccessToken();
      if (!this.headers.has(TOKEN_HEADER_KEY)) {
        this.headers.append(TOKEN_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
      } else {
        this.headers.set(TOKEN_HEADER_KEY, `${AUTH_PREFIX} ${token}`)
      }
    }
    if (cache == true) {
      this.headers.set('Cache-Control', 'no-cache')
      this.headers.set('Pragma', 'no-cache')
    } else {

    }
  }


  /**
   * get a specific object.
   *
   * @param  {Object|Integer} args - $resource.get arguments, or { id: args } if numeric.
   * @param  {Function} callback - $resource.get callback function if any.
   * @return {Promise} Promise
   */
  get(url, args = {}) {
    let ctrl = this;
    let requestUrl = ctrl.baseUrl + url;
    if (!this.checkObjEmpty(args)) {
      requestUrl += "?" + ctrl.paramsToQuery(args);
    }
    //console.log(requestUrl)
    this.reloadHeader(true)
    //console.log('headers', ctrl.headers)
    return ctrl.http.get(requestUrl, { headers: ctrl.headers });
  };


  /**
   * posting form data.
   * @param  {string} url
   * @param  {Object} form data
   * @return {Object} Observable
   */
  post(url, args) {
    this.reloadHeader()
    return this.http.post(this.baseUrl + url, JSON.stringify(args), { headers: this.headers });
  };

  /**
   * performing patch request
   * @param  {string} url
   * @param  {Object} form data
   * @return {Object} Observable
   */
  put(url, args) {
    this.reloadHeader()
    return this.http.put(this.baseUrl + url, JSON.stringify(args), { headers: this.headers });
  }


  /**
   * performing patch request
   * @param  {string} url
   * @return {Object} Observable
   */
  delete(url) {
    this.reloadHeader()
    return this.http.delete(this.baseUrl + url, { headers: this.headers });
  }

  /**
   * Check object empty
   * @return {Boolean}
   */
  checkObjEmpty(obj = {}) {
    return Object.keys(obj).length === 0
  }

  /**
   * handle error
   *
   * @param  {Object} error.
   * @return {Object} Observable
   */
  handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }

  /**
   * new omitEmpty() => Removing blank values
   * @param {Object} data - Data to send.
   * @return {Object}
   */
  omitEmpty(obj) {

  }

  /**
   * paramsToQuery - Convert object to query string
   * @param {Object} params - Data to send.
   * @return {String}
   */
  paramsToQuery(params) {
    let str = [], p;
    for (p in params) {
      if (params.hasOwnProperty(p)) {
        let k = p, v = params[p];
        str.push(
          (v !== null && typeof v === "object") ? this.paramsToQuery(v) : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  }

  azureObjectUpload(url, file) {
    return this.http.put(url, file, { headers: this.azureHeader });
  }

  /**
   * posting form data.
   * @param  {string} url
   * @param  {Object} form data
   * @return {Object} Observable
   */
  getFile(url: string, args: any = {}) {
    return this.http.post(this.baseUrl + url, JSON.stringify(args), { headers: this.headers, responseType: 3 });
  };

}
