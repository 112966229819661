import { Component, OnInit, Input } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { ChangeLinkingComponent } from 'src/app/shared/change-linking/change-linking.component';
import { MatDialog } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'linking-tree-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {

  @Input() vehicle;
  constructor(public roleCheckService: RoleCheckerService, public dialog: MatDialog, public commonService: CommonService) { }

  ngOnInit() {
  }
  changeLinking(): void {
    const dialogRef = this.dialog.open(ChangeLinkingComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        vehicle: this.vehicle
      }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
