import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';



import { MaterialComponentsModule } from '../material-components.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';

import { RvCalculatorComponent } from '../shared/calculator/rv-calculator/rv-calculator.component';
import { RvValuesComponent } from '../shared/calculator/rv-values/rv-values.component';
import { LinkVehicleComponent } from '../shared/link-vehicle/link-vehicle.component';
import { AddRvValuesComponent } from '../shared/add-rv-values/add-rv-values.component';
import { ManageRecommendationsComponent } from '../shared/manage-recommendations/manage-recommendations.component';
import { VehicleLinkageComponent } from '../shared/vehicle-linkage/vehicle-linkage.component';
import { ChangeLinkingComponent } from '../shared/change-linking/change-linking.component';
import { ConfirmationPopupComponent } from '../shared/confirmation-popup/confirmation-popup.component';

import { ManageUsersComponent } from './manage-users/manage-users.component';
import { SystemConfigurationsComponent } from './system-configurations/system-configurations.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { HelpComponent } from './help/help.component';
import { SingleUserComponent } from './single-user/single-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ApCommonModule } from '../ap-common/ap-common.module';
import { VehicleReportsComponent } from './vehicle-reports/vehicle-reports.component';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    FormsModule,
    SharedModule,
    DashboardModule,
    TextMaskModule,
    ApCommonModule,
    ClipboardModule
  ],
  declarations: [
    ManageUsersComponent,
    SystemConfigurationsComponent,
    SubscriptionsComponent,
    HelpComponent,
    SingleUserComponent,
    InviteUserComponent,
    VehicleReportsComponent
  ],
  exports: [
  ],
  entryComponents: [
    RvCalculatorComponent,
    RvValuesComponent,
    LinkVehicleComponent,
    AddRvValuesComponent,
    ManageRecommendationsComponent,
    VehicleLinkageComponent,
    ChangeLinkingComponent,
    ConfirmationPopupComponent,
    InviteUserComponent
  ],
  providers: [
    DatePipe
  ]
})
export class MyAccountModule { }
