/**
  * @ngdoc Services
  * @name app.services.VehicleService
  * @description
  * The vehicle service is used for handling the request related to vehicles
  * */

import { Injectable } from '@angular/core';

import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";
@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  /*
  * @name getVehicleFilters
  * @param
  * @description get filter list
  * @return Observable
  */
  getVehicleFilters(filter, type = 'get') {
    if (type == 'get') {
      return this.commonHttpService.get(this.apiUrls.vehicle.filter, filter);
    } else {
      return this.commonHttpService.post(this.apiUrls.vehicle.filter, filter);
    }
  }

  /*
  * @name getPriceTrackerFilters
  * @param
  * @description get price tracker filter list
  * @return Observable
  */
  getPriceTrackerFilters(filter, type = 'get') {
    if (type == 'get') {
      return this.commonHttpService.get(`${this.apiUrls.priceTracker.tracker}/${this.apiUrls.priceTracker.filters}`, filter);
    } else {
      return this.commonHttpService.post(`${this.apiUrls.priceTracker.tracker}/${this.apiUrls.priceTracker.filters}`, filter);
    }
  }


  /*
  * @name getPriceTrackerFilters
  * @param
  * @description get price tracker filter list
  * @return Observable
  */
  getPriceTrackerRecords(filter, type = 'get') {
    if (type == 'get') {
      return this.commonHttpService.get(`${this.apiUrls.priceTracker.tracker}/${this.apiUrls.priceTracker.records}`, filter);
    } else {
      return this.commonHttpService.post(`${this.apiUrls.priceTracker.tracker}/${this.apiUrls.priceTracker.records}`, filter);
    }
  }

  /*
  * @name getVehicleListing
  * @param
  * @description get vehicle listing
  * @return Observable
  */
  getVehicleListing(params = {}, type = 'get') {
    if (type == 'get') {
      return this.commonHttpService.get(this.apiUrls.vehicle.list, params)
    } else {
      return this.commonHttpService.post(this.apiUrls.vehicle.list, params)
    }
  }

  /*
  * @name exportVehicleList
  * @param
  * @description get vehicle listing file for export
  * @return Observable
  */
  exportVehicleList(params) {
    return this.commonHttpService.getFile(this.apiUrls.vehicle.list, params)
  }

  /*
 * @name exportNewVehicleList
 * @param
 * @description get new vehicle listing file for export
 * @return Observable
 */
  exportNewVehicleList(params) {
    let url = `${this.apiUrls.vehicle.new_vehicle}/${this.apiUrls.vehicle.new_vehicle_list}`;
    return this.commonHttpService.getFile(url, params)
  }

  /*
  * @name getNewVehicleListing
  * @param
  * @description get new vehicle listing
  * @return Observable
  */
  getNewVehicleListing(params = {}) {
    let url = `${this.apiUrls.vehicle.new_vehicle}/${this.apiUrls.vehicle.new_vehicle_list}`;
    return this.commonHttpService.post(url, params)
  }

  /*
  * @name getRVChangesHistory
  * @param
  * @description Get RV history changes for vehicle by Datium Asset Key
  * @return Observable
  */
  getRVChangesHistory(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.rv_history}`, { datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name getRVChangesHistory
  * @param
  * @description Get RV history changes for vehicle by Datium Asset Key
  * @return Observable
  */
  getDIChangesHistory(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.di_history}`, { datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name getVehicleDetail
  * @param
  * @description Get vehicle details
  * @return Observable
  */
  getVehicleDetail(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}`, { datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name getVehicleDetail
  * @param
  * @description Get vehicle details
  * @return Observable
  */
  captureVehicleRV(datiumAssetKey: string, type: string, vData: any) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.capture_vehicle}`, { datiumAssetKey: datiumAssetKey, type: type, vehicleData: vData })
  }

  /*
  * @name vehicleReport
  * @param
  * @description Get vehicle reports
  * @return Observable
  */
  vehicleReport(query = {}) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.reports}`, query)
  }

  /*
  * @name exportVehicleReport
  * @param
  * @description Export vehicle reports
  * @return Observable
  */
  exportVehicleReport(query = {}) {
    return this.commonHttpService.getFile(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.reports}/${this.apiUrls.vehicle.export}`, query)
  }

  /*
  * @name getNewVehicleLinkDetail
  * @param
  * @description Get new vehicle link details
  * @return Observable
  */
  getNewVehicleLinkDetail(linkKey, datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.new_vehicle_link}`, { linkKey: linkKey, datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name acceptRejectNewLinkDetail
  * @param
  * @description Accept/Reject new link
  * @return Observable
  */
  acceptRejectNewLinkDetail(params) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.new_vehicle_link}`, params)
  }

  /*
  * @name getVehicleChartDetails
  * @param
  * @description Get vehicle chart details
  * @return Observable
  */
  getVehicleChartDetails(datiumAssetKey, params = {}) {
    params['datiumAssetKey'] = datiumAssetKey
    return this.commonHttpService.get(`${this.apiUrls.vehicle.chart_detail}`, params)
  }

  /*
* @name saveDiHistory
* @param
* @description Save Di Admin History
* @return Observable
*/
  saveDiHistory(body = {}, datiumAssetKey) {
    body['datiumAssetKey'] = datiumAssetKey
    return this.commonHttpService.post(`${this.apiUrls.vehicle.di_history}`, body)
  }

  /*
  * @name saveRvHistory
  * @param
  * @description Save RV Admin or User History
  * @return Observable
  */
  saveRvHistory(body = {}, datiumAssetKey) {
    body['datiumAssetKey'] = datiumAssetKey
    return this.commonHttpService.post(`${this.apiUrls.vehicle.rv_history}`, body)
  }

  /*
  * @name getLinkedVehicleIds
  * @param
  * @description Get linked vehicle ids
  * @return Observable
  */
  getLinkedVehicleIds() {
    return this.commonHttpService.get(this.apiUrls.vehicle.linked_vehicle)
  }

  /*
  * @name getBaseData
  * @param
  * @description get Base Data
  * @return Observable
  */
  getBaseData(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.base_data}`, { datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name saveRvDiValues
  * @param
  * @description Save RV Admin or User History
  * @return Observable
  */
  saveRvDiValues(body = {}, datiumAssetKey) {
    body['datiumAssetKey'] = datiumAssetKey
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.addRvDi}`, body)
  }

  /*
  * @name saveRvDiValues
  * @param
  * @description Save RV Admin or User History
  * @return Observable
  */
  getVehicleRecommendations(datiumAssetKey, exId = '') {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.recommendation}`, { exId: exId, datiumAssetKey: datiumAssetKey })
  }

  /*
* @name addVehicleRecommendations
* @param
* @description Add Vehicle Recommendations
* @return Observable
*/
  addVehicleRecommendations(datiumAssetKey, datiumAssetKeyLinkedTo) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.addRecom}`, { datiumAssetKey_LinkedTo: datiumAssetKeyLinkedTo, datiumAssetKey: datiumAssetKey })
  }

  /*
* @name removeVehicleRecommendations
* @param
* @description Remove Vehicle Recommendations
* @return Observable
*/
  removeVehicleRecommendations(datiumAssetKey, datiumAssetKeyLinkedTo) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.deleteRecom}`, { datiumAssetKey: datiumAssetKey, datiumAssetKeyLinkedTo: datiumAssetKeyLinkedTo })
  }

  /*
  * @name changeVehicleLinking
  * @param
  * @description change vehicle linking
  * @return Observable
  */
  changeVehicleLinking(datiumAssetKey, datiumAssetKey_LinkedTo) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.changeLink}`, { datiumAssetKey_LinkedTo: datiumAssetKey_LinkedTo, datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name changeVehicleLinking
  * @param
  * @description change vehicle linking
  * @return Observable
  */
  removeLinking(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.deleteLink}`, { datiumAssetKey: datiumAssetKey })
  }


  /*
  * @name getLinkedTreeVehicleListing
  * @param
  * @description get linking tree vehicle listing
  * @return Observable
  */
  getLinkedTreeVehicleListing(params = {}) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.linkingTree}`, params)
  }

  /*
  * @name getLinkedVehicleDetail
  * @param
  * @description Get linked vehicle details
  * @return Observable   ------ Not used yet -------
  */
  getLinkedVehicleDetail(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${datiumAssetKey}`)
  }

  /*
  * @name getLinkingTreeVehicleDetail
  * @param
  * @description Get linked vehicle details
  * @return Observable
  */
  getLinkingTreeVehicleDetail(datiumAssetKey) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.linkingTreeDetail}`, { datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name getLinkedTreeVehicleDetail
  * @param
  * @description Get linked tree details
  * @return Observable
  */
  getLinkedTreeVehicleDetail(datiumAssetKey, datiumAssetKey_LinkedTo) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.vehicle}/${this.apiUrls.vehicle.lTree}`, { parent: datiumAssetKey_LinkedTo, datiumAssetKey: datiumAssetKey })
  }

  /*
  * @name acceptVehicle
  * @param
  * @description Accept vehicle
  * @return Observable
  */
  acceptVehicle(datiumAssetKey, params = {}) {
    params['datiumAssetKey'] = datiumAssetKey
    return this.commonHttpService.post(`${this.apiUrls.vehicle.new_vehicle}/${this.apiUrls.vehicle.accept_vehicle}`, params)
  }

  /*
  * @name rejectVehicle
  * @param
  * @description Reject vehicle
  * @return Observable
  */
  rejectVehicle(datiumAssetKey, params = {}) {
    params['datiumAssetKey'] = datiumAssetKey
    return this.commonHttpService.post(`${this.apiUrls.vehicle.new_vehicle}/${this.apiUrls.vehicle.reject_vehicle}`, params)
  }


  /*
  * @name acceptRejectVehicles
  * @param
  * @description Accept/Reject multiple vehicles
  * @return Observable
  */
  acceptRejectVehicles(params) {
    return this.commonHttpService.post(`${this.apiUrls.vehicle.new_vehicle}/${this.apiUrls.vehicle.multi_actions}`, params)
  }


  /*
  * @name riskAdjustmentListing
  * @param
  * @description Get risk adjustment listing
  * @return Observable
  */
  riskAdjustmentListing(type) {
    return this.commonHttpService.get(`${this.apiUrls.vehicle.riskAdjustmentListing}`, { type: type })
  }
  /*
  * @name addRiskAdjustment
  * @param
  * @description Add risk adjustment
  * @return Observable
  */
  addRiskAdjustment(params) {
    return this.commonHttpService.post(this.apiUrls.vehicle.addRiskAdjustment, params)
  }
  /*
  * @name updateRiskAdjustment
  * @param
  * @description Update risk adjustment
  * @return Observable
  */
  updateRiskAdjustment(params) {
    return this.commonHttpService.post(this.apiUrls.vehicle.updateRiskAdjustment, params)
  }
  /*
  * @name deleteRiskAdjustment
  * @param
  * @description delete risk adjustment
  * @return Observable
  */
  deleteRiskAdjustment(params) {
    return this.commonHttpService.post(this.apiUrls.vehicle.deleteRiskAdjustment, params)
  }
  /*
  * @name otherVehicleListing
  * @param
  * @description Oher vehicle listing
  * @return Observable
  */
  otherVehicleListing(params, type = 'get') {
    if (type == 'get') {
      return this.commonHttpService.get(this.apiUrls.vehicle.otherVehicleListing, params)
    } else {
      return this.commonHttpService.post(this.apiUrls.vehicle.otherVehicleListing, params)
    }
  }

  exportIndex(params) {
    return this.commonHttpService.getFile(this.apiUrls.vehicle.downloadIndex, params)
  }

  /*
 * @name getQuickSearchFilters
 * @param
 * @description Get quick search vehicle filters
 * @return Observable
 */
  getQuickSearchFilters(params) {
    return this.commonHttpService.get(this.apiUrls.vehicle.quickVehicleFilter, params)
  }

  /*
  * @name getQuickSearchVehicles
  * @param
  * @description Get quick search vehicle
  * @return Observable
  */
  getQuickSearchVehicles(params) {
    return this.commonHttpService.get(this.apiUrls.vehicle.quickVehicles, params)
  }


  /*
  * @name getQuickSearchVehicles
  * @param
  * @description Get quick search vehicle from logs
  * @return Observable
  */
  getVehicleSearches(params) {
    return this.commonHttpService.get(this.apiUrls.vehicle.quickVehicleSearches, params);
  }
}

