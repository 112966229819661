import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpSupportComponent } from './help-support/help-support.component';
import { SharedModule } from '../shared/shared.module';

import { RouterModule } from '@angular/router';

import { AdministrationComponent } from './administration/administration.component';
import { NewVehiclesComponent } from './new-vehicles/new-vehicles.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutopredictNewComponent } from './autopredict-new/autopredict-new.component';
import { RiskAdjustmentsComponent } from './risk-adjustments/risk-adjustments.component';
import { LinkingTreeComponent } from './linking-tree/linking-tree.component';
import { RvCalculatorComponent } from './rv-calculator/rv-calculator.component';
import { FaqComponent } from './faq/faq.component';
import { MaterialComponentsModule } from '../material-components.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    BrowserAnimationsModule,
    MaterialComponentsModule,
    TextMaskModule
  ],
  declarations: [HelpSupportComponent, AdministrationComponent, NewVehiclesComponent, AutopredictNewComponent, RiskAdjustmentsComponent, LinkingTreeComponent, RvCalculatorComponent, FaqComponent, ContactUsComponent]
})
export class HelpModule { }
