import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-sort-icon',
  templateUrl: './vehicle-sort-icon.component.html',
  styleUrls: ['./vehicle-sort-icon.component.scss']
})
export class VehicleSortIconComponent implements OnInit {

  @Input() sortBy: string = ''
  @Input() sort: string = ''
  @Input() sortingFor: string = ''
  constructor() { }

  ngOnInit() {
  }

}
