import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[onOutsideClick]'
})
export class OutsideClickDirective {

  @Output()
  outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    //console.log(this.elementRef.nativeElement, event.target);
    let classList: any = (event.target as HTMLElement).classList;
    //console.log(classList, 'classList', typeof (classList));
    if (classList.length > 0) {
      classList = classList[0];
    } else {
      classList = '';
    }
    //console.log(classList, 'classList', typeof (classList));
    if (!this.elementRef.nativeElement.contains(event.target) && (classList.search('mat-option-text') == -1 && classList.search('mat-calendar') == -1)) {
      this.outsideClick.emit(event);
    }
  }

  constructor(private elementRef: ElementRef) { }

}
