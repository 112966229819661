import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-configurations',
  templateUrl: './system-configurations.component.html',
  styleUrls: ['./system-configurations.component.scss']
})
export class SystemConfigurationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
