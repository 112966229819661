/**
  * @ngdoc Services
  * @name app.services.DashboardService
  * @description
  * The dashboard service is used for handling the request related to vehicles
  * */

import { Injectable } from '@angular/core';

import { CommonHttpService } from "./common-http.service";
import { ApiUrls } from "../configs/api-urls";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private commonHttpService: CommonHttpService, private apiUrls: ApiUrls) { }

  /*
  * @name getRVChangesHistory
  * @param  
  * @description get rv changes list
  * @return Observable
  */
  getRVChangesHistory(params = {}) {
    return this.commonHttpService.get(this.apiUrls.dashboard.rv_history, params);
  }
  /*
  * @name getDIHistory
  * @param  
  * @description get di changes list
  * @return Observable
  */
  getDIHistory(params = {}) {
    return this.commonHttpService.get(this.apiUrls.dashboard.di_history, params);
  }

  /*
  * @name getDashboardActionsCount
  * @param  
  * @description get actions count
  * @return Observable
  */
  getDTilesCount(params = {}) {
    return this.commonHttpService.get(this.apiUrls.dashboard.tiles_count, params);
  }

  /*
  * @name getNewAddedVehiclesChart
  * @param  
  * @description get newly added vehicles chart
  * @return Observable
  */
  getNewAddedVehiclesChart(params = {}) {
    return this.commonHttpService.get(this.apiUrls.dashboard.new_vehicles_chart, params);
  }

  /*
  * @name recenlySearchedVehiclesChart
  * @param  
  * @description get recently searched vehicles chart
  * @return Observable
  */
  recenlySearchedVehiclesChart(params = {}) {
    return this.commonHttpService.get(this.apiUrls.dashboard.recely_searched_vehicles_chart, params);
  }
}
