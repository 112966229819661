import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { CommonService } from '../../../services/common.service';
import { PermissionsService } from '../../../services/permissions.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { DataStoreService } from '../../../services/data-store.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

const uReq = 100000
const uUsers = 10
const startDate = new Date()
const EndDate = new Date(new Date().setDate(startDate.getDate() + 365))

const formConfigData = {
  'AutoPredict': {
    'isChecked': false,
    'Product_Id': null,
    'Makes': [],
    'Permission_Id': [],
    'StartDate': startDate,
    'EndDate': EndDate,
    'CompanySubscription_Id': null,
    'TotalUsers': uUsers
  },
  'InstantValuation': {
    'isChecked': false,
    'Product_Id': null,
    'TotalRequests': uReq,
    'StartDate': startDate,
    'EndDate': EndDate,
    'CompanySubscription_Id': null,
    'TotalUsers': uUsers
  },
  'Rego': {
    'isChecked': false,
    'Product_Id': null,
    'TotalRequests': uReq,
    'StartDate': startDate,
    'EndDate': EndDate,
    'CompanySubscription_Id': null,
    'TotalUsers': uUsers
  },
  'Vin': {
    'isChecked': false,
    'Product_Id': null,
    'TotalRequests': uReq,
    'StartDate': startDate,
    'EndDate': EndDate,
    'CompanySubscription_Id': null,
    'TotalUsers': uUsers
  }
}

@Component({
  selector: 'app-add-product-v2',
  templateUrl: './add-product-v2.component.html',
  styleUrls: ['./add-product-v2.component.scss']
})
export class AddProductV2Component implements OnInit {

  @Input('companyId') companyId: number;
  @Input('offer') offer: any;
  @Input('isEdit') isEdit: boolean = false

  @Output() onCompanySubAdd: EventEmitter<any> = new EventEmitter();
  @Output() subInProgress: EventEmitter<any> = new EventEmitter();

  isProcessing: boolean = false;
  loadingPer: boolean = false
  loadingText: string = 'Loading products...'
  makArray: Array<string> = []
  permArray: Array<any> = []
  productsArray: Array<any> = []
  formError: any = {};
  subData: any = null
  public companyProductForm: FormGroup;

  startDate = new Date()
  endDate = new Date();
  toggleNewSub: Array<Number> = []
  validPermission: Array<any> = []
  format = 'yyyy-MM-dd'
  addSubModel = {
    AutoPredict: {
      isChecked: false,
      Permission_Id: [],
      TotalUsers: 5,
      Make: [],
      start_date: this.startDate,
      end_date: this.endDate
    },
    InstantValuation: {
      isChecked: false,
      TotalUsers: 5,
      TotalRequests: 300000,
      start_date: this.startDate,
      end_date: this.endDate
    },
  }
  openNewSub: boolean = false
  public autoProduct_Id = 1
  public ivProduct_Id = 2
  public AutoPredictName = 'AutoPredict'
  public InstantValuationName = 'InstantValuation'
  public offerDetails = {}
  public offerSubscription = []
  isError = false
  errorMsg = ""
  fromRenew = false
  constructor(
    public companyService: CompanyService,
    public commonService: CommonService,
    public permissionService: PermissionsService,
    public dialog: MatDialog,
    public dp: DatePipe,
    public dataStore: DataStoreService
  ) {
  }

  ngOnInit() {
    this.endDate.setDate(this.endDate.getDate() + 365)
    //this.getPermissions();
    //this.initForm()
    console.log(this.offer)
    if ('offerDetails' in this.offer) {
      if ('Products' in this.offer['offerDetails']) {
        this.makArray = this.offer['makes']
        this.permArray = this.offer['permissions']
        this.offerDetails = this.offer['offerDetails']
        this.offerSubscription = this.offer['offerSubscription']
        this.subData = this.offerSubscription
        if ('fromRenew' in this.offer) {
          this.fromRenew = true
        }
        this.initForm()
      } else {
        this.isError = true
        this.errorMsg = "No product found under this offer.Please choose another offer."
      }
    } else {
      this.isError = true
      this.errorMsg = "Offer is not valid.Please choose a valid offer."
    }
  }

  /*
  * @name initForm
  * @param none
  * @description Initialize forms             
  * @return none
  */
  initForm() {
    ///if (this.isEdit) {
    this.companyProductForm = this.getProductOfferControls()
    console.log(this.companyProductForm, 'companyProductForm')
    //}
  }

  getProductSubscription(Product_Id) {
    let find = []
    this.offerSubscription.forEach(element => {
      if (element['Product_Id'] == Product_Id) {
        if ('ProductSubscription' in element) {
          find = element['ProductSubscription']
          find['isChecked'] = true
          find['StartDate'] = new Date(find['StartDate'])
          find['EndDate'] = new Date(find['EndDate'])
        }
      }
    });
    find['Product_Id'] = Product_Id
    return find
  }

  getProductOfferControls() {
    let productsForm: any = {};
    if (this.offerDetails['Products'] && Array.isArray(this.offerDetails['Products'])) {
      this.offerDetails['Products'].forEach(product => {
        let selVal = this.getProductSubscription(product['Product_Id'])
        console.log(selVal)
        let fControls = this.getProductControls(product['ProductName'], selVal)
        if (fControls != null) {
          productsForm[product['ProductName']] = new FormGroup(fControls)
        }
      })
    }
    let formObj = {
      'Plan_Id': new FormControl(this.offerDetails['Plan_Id'], Validators.required),
      'companyId': new FormControl(this.companyId, Validators.required),
      'Products': new FormGroup(productsForm)
    }
    return new FormGroup(formObj)
  }

  getProductControls(productKey = null, productData = []) {
    let formObj = {}
    let fConfig = formConfigData[productKey]
    //console.log(productData)
    if (fConfig) {
      let disabled = false
      if (productKey == 'AutoPredict') {
        Object.keys(fConfig).forEach((key) => {
          let selVal = this.getFormSelVal(key, productData)
          console.log(selVal, 'selVal')
          let required = true
          if (key == 'CompanySubscription_Id') {
            required = false
          }
          if (key == 'isChecked') {
            disabled = false
          } else {
            if (this.fromRenew == true && (key != 'StartDate' && key != 'EndDate')) {
              disabled = false
            } else {
              if (('isChecked' in productData && productData['isChecked'] == true) || (key == 'StartDate' || key == 'EndDate')) {
                disabled = true
              } else {
                disabled = false
              }
            }
          }
          if (key == 'Makes') {
            formObj[key] = new FormControl({ value: (selVal ? selVal : fConfig[key]), disabled: disabled }, required ? Validators.required : null)
          } else if (key == 'Permission_Id') {
            formObj[key] = new FormArray(this.getPermissionsControls(selVal ? selVal : []), [this.minSelectedCheckbox()])
          }
          else if (key == 'Product_Id') {
            formObj[key] = new FormControl({ value: productData['Product_Id'], disabled: disabled }, required ? Validators.required : null)
          }
          else {
            formObj[key] = new FormControl({ value: (selVal ? selVal : fConfig[key]), disabled: disabled }, required ? Validators.required : null)
          }
        })
      } else {
        Object.keys(fConfig).forEach((key) => {
          let required = true
          if (key == 'CompanySubscription_Id') {
            required = false
          }
          if (key == 'Product_Id') {
            formObj[key] = new FormControl(productData['Product_Id'], required ? Validators.required : null)
          } else {
            let selVal = this.getFormSelVal(key, productData)
            if (key == 'isChecked') {
              disabled = false
            } else {
              if (this.fromRenew == true && (key != 'StartDate' && key != 'EndDate')) {
                disabled = false
              } else {
                if (('isChecked' in productData && productData['isChecked'] == true) || (key == 'StartDate' || key == 'EndDate')) {
                  disabled = true
                } else {
                  disabled = false
                }
              }
            }
            formObj[key] = new FormControl({ value: selVal ? selVal : fConfig[key], disabled: disabled }, required ? Validators.required : null)
          }
        })
      }
      return formObj
    }
    return null;
  }

  getFormSelVal(key, arr) {
    let val = null
    Object.keys(arr).forEach(arr_key => {
      if (key == arr_key) {
        val = arr[key]
      }
    });
    return val
  }

  minSelectedCheckbox(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkbox
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }


  getMakesList() {
    let Makes = this.getControl('Products.AutoPredict.Makes').value
    if (Makes.length > 0) {
      return Makes.join(', ')
    } else {
      return ' N/A'
    }
  }

  saveProducts(pForm) {
    if (pForm.valid) {
      //console.log(this.companyProductForm.getRawValue())
      let formData = JSON.parse(JSON.stringify(this.companyProductForm.getRawValue()))
      if (this.checkAutoInOffers()) {
        let selectedPermissions: Array<Number> = formData['Products']['AutoPredict']['Permission_Id'];
        selectedPermissions = selectedPermissions.map((checked, index) => checked ? this.permArray[index].key : null)
          .filter(value => value !== null);
        if (selectedPermissions.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one permission.")
          return false
        }
        //console.log(selectedPermissions)
        let copyPermissions = selectedPermissions.slice()
        formData['Products']['AutoPredict']['Permission_Id'] = copyPermissions.join(',')

        let Makes: Array<string> = formData['Products']['AutoPredict']['Makes'];
        //console.log(Makes, Makes.length)
        if (Makes.length <= 0) {
          this.commonService.showSnakeBar("Please choose at least one make.")
          return false
        }
        let copyMakes = Makes.slice()
        formData['Products']['AutoPredict']['TotalMakes'] = copyMakes.length
        formData['Products']['AutoPredict']['Makes'] = copyMakes.join(',')
      }
      let copyFormData = JSON.parse(JSON.stringify(formData))
      // console.log(copyFormData)
      let products = []
      Object.keys(copyFormData['Products']).forEach(key => {
        let temp = copyFormData['Products'][key]
        delete temp['isChecked'];

        let pId = temp['Product_Id']
        delete temp['Product_Id'];

        temp['StartDate'] = this.dp.transform(temp['StartDate'], this.format)
        temp['EndDate'] = this.dp.transform(temp['EndDate'], this.format)
        //let subscription = {};
        delete temp['CompanySubscription_Id']
        //subscription = temp
        products.push({ Product_Id: pId, 'characteristic': temp })
      })
      copyFormData['Products'] = products
      //console.log(copyFormData)
      this.addOffer(copyFormData)

    } else {
      this.commonService.showSnakeBar("Please correct the errors.")
    }
  }

  addOffer(formData) {
    this.isProcessing = true;

    this.companyService.addSubscription(formData).subscribe(res => {
      try {
        const response = res.json();
        //console.log(response)
        this.commonService.showSnakeBar(response.message);
        this.onCompanySubAdd.emit(response.data);
        this.closeModal();
      }
      catch (e) {
        //console.log(e)
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      try {
        let response = err.json();
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      } catch (e) {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }



  get AutoPredictChecked() {
    return this.companyProductForm.get('Products.AutoPredict.isChecked') as FormControl;
  }

  get InstantValuationChecked() {
    return this.companyProductForm.get('Products.InstantValuation.isChecked') as FormControl;
  }

  get RegoChecked() {
    return this.companyProductForm.get('Products.Rego.isChecked') as FormControl;
  }

  get VinChecked() {
    return this.companyProductForm.get('Products.Vin.isChecked') as FormControl;
  }

  isProductExists(ProductName = null) {
    let find = false
    if (this.offerDetails['Products'] && Array.isArray(this.offerDetails['Products'])) {
      this.offerDetails['Products'].forEach(product => {
        if (product['ProductName'] == ProductName) {
          find = true
        }
      })
    }
    return find
  }

  checkAuto() {
    let found = false
    this.offerSubscription.forEach(element => {
      if (Number(element['Product_Id']) == this.autoProduct_Id) {
        if ('ProductSubscription' in element) {
          found = true
        }
      }
    });
    return found;
  }

  checkAutoInOffers() {
    let found = false
    this.offerDetails['Products'].forEach(element => {
      if (Number(element['Product_Id']) == this.autoProduct_Id) {
        found = true
      }
    });
    return found;
  }

  getPermissionsControls(selPermArray = [], newSub: boolean = false) {
    //show selected controls if product is AutoPredict
    let isAuto = this.checkAuto()
    //console.log(isAuto, 'isAuto')
    // console.log(selPermArray)
    return this.permArray.map(control => {
      // console.log(control)
      if (isAuto == true && newSub == false) {
        // console.log(selPermArray.indexOf(control['key']), control['key'])
        if (selPermArray.indexOf(control['key']) != -1) {
          return new FormControl({ value: true, disabled: this.fromRenew == true ? false : true })
        }
        return new FormControl({ value: false, disabled: this.fromRenew == true ? false : true })
      } else {
        return new FormControl({ value: false, disabled: false })
      }
    })
  }

  getAUTPPermissions() {
    return this.permArray
  }

  getControlError(path, type: string = 'required') {
    return this.companyProductForm.get(path).hasError(type)
  }

  checkControlDirty(path) {
    return this.companyProductForm.get(path).invalid && (this.companyProductForm.get(path).pristine || this.companyProductForm.get(path).touched)
  }

  checkControlError(path, pForm, errorType: string = 'required') {
    return this.getControlError(path, errorType) && (this.checkControlDirty(path))
  }

  getControl(path) {
    return this.companyProductForm.get(path) as FormControl
  }

  checkSubExists(Product_Id = null) {
    let find = false
    this.offerSubscription.forEach(element => {
      if (element['Product_Id'] == Product_Id) {
        if ('ProductSubscription' in element) {
          find = true
        }
      }
    });
    return find
  }

  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  closeModal() {
    this.dialog.closeAll();
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (key == 'p_ids') {
          this.formError['permission'] = errors[key]
        }
      });
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

}
