/*
* @ngdoc Component
* @name app.shared.RvValuesComponent
* @description
* This component used for RV calculator
* */

import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RvCalculatorComponent } from '../rv-calculator/rv-calculator.component';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { RvCalculatorService } from '../../../services/rv-calculator.service';
import { CommonService } from '../../../services/common.service';
import { Subscription } from 'rxjs';
import { VehicleService } from '../../../services/vehicle.service';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';

// Define the Calculator base value
interface calBase {
  ageCoefficient: number
  kmCoefficient: number
  datiumModelvalue: number
  SeriesAge: number
  NewPrice: number
  per_12: number
  per_24: number
  per_36: number
  per_48: number
  per_60: number
  adjRRP_12: number
  adjRRP_24: number
  adjRRP_36: number
  adjRRP_48: number
  adjRRP_60: number
  slope_12_24: number
  slope_24_36: number
  slope_36_48: number
  slope_48_60: number
  intercept_12_24: number
  intercept_24_36: number
  intercept_36_48: number
  intercept_48_60: number

  di_per_12: number
  di_per_24: number
  di_per_36: number
  di_per_48: number
  di_per_60: number
  di_adjRRP_12: number
  di_adjRRP_24: number
  di_adjRRP_36: number
  di_adjRRP_48: number
  di_adjRRP_60: number
  di_slope_12_24: number
  di_slope_24_36: number
  di_slope_36_48: number
  di_slope_48_60: number
  di_intercept_12_24: number
  di_intercept_24_36: number
  di_intercept_36_48: number
  di_intercept_48_60: number

  gst: number
  Ind_NewVehicle: boolean
}
interface slopeNIntercept {
  Intercept: number,
  Slope: number
}

interface RvCalModel {
  new_price: number,
  //Age user input
  age_12?: number,
  age_24?: number,
  age_36?: number,
  age_48?: number,
  age_60?: number,
  //Km user input
  km_12?: number,
  km_24?: number,
  km_36?: number,
  km_48?: number,
  km_60?: number,

  //RV inc GST dollar values
  rvGST_12?: number,
  rvGST_24?: number,
  rvGST_36?: number,
  rvGST_48?: number,
  rvGST_60?: number,

  di_rvGST_12?: number,
  di_rvGST_24?: number,
  di_rvGST_36?: number,
  di_rvGST_48?: number,
  di_rvGST_60?: number,

  //RV inc GST % values
  rvGST_p12?: number,
  rvGST_p24?: number,
  rvGST_p36?: number,
  rvGST_p48?: number,
  rvGST_p60?: number,

  di_rvGST_p12?: number,
  di_rvGST_p24?: number,
  di_rvGST_p36?: number,
  di_rvGST_p48?: number,
  di_rvGST_p60?: number,

  //Manual Adj
  mAdj_12?: number,
  mAdj_24?: number,
  mAdj_36?: number,
  mAdj_48?: number,
  mAdj_60?: number,
  //Valuation Adj
  rvmAdj_12?: number,
  rvmAdj_24?: number,
  rvmAdj_36?: number,
  rvmAdj_48?: number,
  rvmAdj_60?: number,

  //Ex GST
  rv_12?: number,
  rv_24?: number,
  rv_36?: number,
  rv_48?: number,
  rv_60?: number,

  //New Index
  index_12?: number,
  index_24?: number,
  index_36?: number,
  index_48?: number,
  index_60?: number,

  di_index_12?: number,
  di_index_24?: number,
  di_index_36?: number,
  di_index_48?: number,
  di_index_60?: number,
}
@Component({
  selector: 'app-rv-values',
  templateUrl: './rv-values.component.html',
  styleUrls: ['./rv-values.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RvValuesComponent implements OnInit {

  vehicle: any;
  type: string = 'list';
  error: string = '';
  baseData: calBase;
  isProcessing: boolean = false;
  serviceSub: Subscription;
  RvCalFormModel: RvCalModel = {
    new_price: 0,
    //Age user input
    age_12: 12,
    age_24: 24,
    age_36: 36,
    age_48: 48,
    age_60: 60,
    //Km user input
    km_12: 30000,
    km_24: 60000,
    km_36: 90000,
    km_48: 120000,
    km_60: 150000,

    //RV inc GST dollar value
    rvGST_12: 0,
    rvGST_24: 0,
    rvGST_36: 0,
    rvGST_48: 0,
    rvGST_60: 0,

    //DI and DM(super admin case) inc GST dollar value
    di_rvGST_12: 0,
    di_rvGST_24: 0,
    di_rvGST_36: 0,
    di_rvGST_48: 0,
    di_rvGST_60: 0,

    //RV inc GST % value
    rvGST_p12: 0,
    rvGST_p24: 0,
    rvGST_p36: 0,
    rvGST_p48: 0,
    rvGST_p60: 0,

    di_rvGST_p12: 0,
    di_rvGST_p24: 0,
    di_rvGST_p36: 0,
    di_rvGST_p48: 0,
    di_rvGST_p60: 0,

    //Manual Adj
    mAdj_12: 0,
    mAdj_24: 0,
    mAdj_36: 0,
    mAdj_48: 0,
    mAdj_60: 0,
    //Valuation Adj
    rvmAdj_12: 0,
    rvmAdj_24: 0,
    rvmAdj_36: 0,
    rvmAdj_48: 0,
    rvmAdj_60: 0,

    //Ex GST
    rv_12: 0,
    rv_24: 0,
    rv_36: 0,
    rv_48: 0,
    rv_60: 0,

    index_12: 0,
    index_24: 0,
    index_36: 0,
    index_48: 0,
    index_60: 0,

    di_index_12: 0,
    di_index_24: 0,
    di_index_36: 0,
    di_index_48: 0,
    di_index_60: 0
  };
  terms = [12];
  showBack: boolean = true;
  loaderData = {
    loading: false,
    text: 'loading..'
  };
  age = 12;
  km = 30000;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RvValuesComponent>,
    public roleCheckService: RoleCheckerService,
    private rvCalService: RvCalculatorService,
    private commonService: CommonService,
    private vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data) {
    if (!data.vehicle) {
      this.error = "Invalid Datium Asset Key";
    } else {
      this.error = '';
      this.type = data.type;
      if (data.vehicle) {
        this.vehicle = Object.assign({}, data.vehicle);
        //console.log(this.vehicle, 'this.vehicle')
      }
      if (data) {
        if ('back' in data) {
          this.showBack = data['back'];
        }
      }
    }
  }


  ngOnInit() {
    //this.initFormDefaultValues()
    this.getVehicleBaseData(true);
  }

  /*
  * @name initFormDefaultValues
  * @param None
  * @description Initialize template form
  * @return none
  */
  initFormDefaultValues(isInitialCall: boolean) {
    if (this.baseData.Ind_NewVehicle === false) {
      this.RvCalFormModel.new_price = this.vehicle.vehicleDetails.new_price;

      this.baseData.per_12 = this.commonService.roundToXDigits(this.baseData.per_12 / 100, 4);
      this.baseData.per_24 = this.commonService.roundToXDigits(this.baseData.per_24 / 100, 4);
      this.baseData.per_36 = this.commonService.roundToXDigits(this.baseData.per_36 / 100, 4);
      this.baseData.per_48 = this.commonService.roundToXDigits(this.baseData.per_48 / 100, 4);
      this.baseData.per_60 = this.commonService.roundToXDigits(this.baseData.per_60 / 100, 4);

      this.RvCalFormModel.index_12 = this.baseData.per_12;
      this.RvCalFormModel.index_24 = this.baseData.per_24;
      this.RvCalFormModel.index_36 = this.baseData.per_36;
      this.RvCalFormModel.index_48 = this.baseData.per_48;
      this.RvCalFormModel.index_60 = this.baseData.per_60;

      // Datium Index and Model values
      this.baseData.di_per_12 = this.commonService.roundToXDigits(this.baseData.di_per_12 / 100, 4);
      this.baseData.di_per_24 = this.commonService.roundToXDigits(this.baseData.di_per_24 / 100, 4);
      this.baseData.di_per_36 = this.commonService.roundToXDigits(this.baseData.di_per_36 / 100, 4);
      this.baseData.di_per_48 = this.commonService.roundToXDigits(this.baseData.di_per_48 / 100, 4);
      this.baseData.di_per_60 = this.commonService.roundToXDigits(this.baseData.di_per_60 / 100, 4);

      this.RvCalFormModel.di_index_12 = this.baseData.di_per_12;
      this.RvCalFormModel.di_index_24 = this.baseData.di_per_24;
      this.RvCalFormModel.di_index_36 = this.baseData.di_per_36;
      this.RvCalFormModel.di_index_48 = this.baseData.di_per_48;
      this.RvCalFormModel.di_index_60 = this.baseData.di_per_60;

      //Age user input
      this.RvCalFormModel.age_12 = 12;
      this.RvCalFormModel.age_24 = 24;
      this.RvCalFormModel.age_36 = 36;
      this.RvCalFormModel.age_48 = 48;
      this.RvCalFormModel.age_60 = 60;

      //Km user input
      this.RvCalFormModel.km_12 = 30000;
      this.RvCalFormModel.km_24 = 60000;
      this.RvCalFormModel.km_36 = 90000;
      this.RvCalFormModel.km_48 = 120000;
      this.RvCalFormModel.km_60 = 150000;

      this.changeDollarValues();
      //RV inc GST
      //this.RvCalFormModel.rvGST_12 = this.calculateDollarValue(12, true);
      //this.RvCalFormModel.rvGST_24 = this.calculateDollarValue(24, true);
      //this.RvCalFormModel.rvGST_36 = this.calculateDollarValue(36, true);
      //this.RvCalFormModel.rvGST_48 = this.calculateDollarValue(48, true);
      //this.RvCalFormModel.rvGST_60 = this.calculateDollarValue(60, true);

      //Manual Adj
      this.RvCalFormModel.mAdj_12 = 0;
      this.RvCalFormModel.mAdj_24 = 0;
      this.RvCalFormModel.mAdj_36 = 0;
      this.RvCalFormModel.mAdj_48 = 0;
      this.RvCalFormModel.mAdj_60 = 0;

      //Valuation Adj
      //this.RvCalFormModel.rvmAdj_12 = this.calculateManualADJDollarValue(12);
      //this.RvCalFormModel.rvmAdj_24 = this.calculateManualADJDollarValue(24);
      //this.RvCalFormModel.rvmAdj_36 = this.calculateManualADJDollarValue(36);
      //this.RvCalFormModel.rvmAdj_48 = this.calculateManualADJDollarValue(48);
      //this.RvCalFormModel.rvmAdj_60 = this.calculateManualADJDollarValue(60);

      //Ex GST
      //this.RvCalFormModel.rv_12 = this.calculateDollarValue(12);
      //this.RvCalFormModel.rv_24 = this.calculateDollarValue(24);
      //this.RvCalFormModel.rv_36 = this.calculateDollarValue(36);
      //this.RvCalFormModel.rv_48 = this.calculateDollarValue(48);
      //this.RvCalFormModel.rv_60 = this.calculateDollarValue(60);


    } else {
      if (isInitialCall) {
        this.RvCalFormModel.new_price = this.vehicle.vehicleDetails.new_price;

        this.baseData.per_12 = this.commonService.roundToXDigits((this.baseData.per_12) / 100, 4);
        this.RvCalFormModel.index_12 = this.baseData.per_12;
        // Datium Index and Model values
        this.baseData.di_per_12 = this.commonService.roundToXDigits(this.baseData.di_per_12 / 100, 4);
        this.RvCalFormModel.di_index_12 = this.baseData.di_per_12;
        //Age user input
        this.RvCalFormModel.age_12 = 12;
        //Km user input
        this.RvCalFormModel.km_12 = 30000;
        this.changeDollarValues();
        //Manual Adj
        this.RvCalFormModel.mAdj_12 = 0;
      }
      else {
        this.baseData.per_12 = this.commonService.roundToXDigits((this.baseData.per_12) / 100, 4);
        this.RvCalFormModel.index_12 = this.baseData.per_12;
        // Datium Index and Model values
        this.baseData.di_per_12 = this.commonService.roundToXDigits(this.baseData.di_per_12 / 100, 4);
        this.RvCalFormModel.di_index_12 = this.baseData.di_per_12;

        this.changeDollarValues();
      }
    }
  }

  /*
  * @name getVehicleBaseData
  * @param None
  * @description Get vehicle basic values(Intercept,Slope)
  * @return none
  */
  getVehicleBaseData(isInitialCall: boolean) {
    if (!isInitialCall && this.baseData.Ind_NewVehicle) {
      this.age = this.RvCalFormModel.age_12;
      this.km = this.RvCalFormModel.km_12;
    }
    this.isProcessing = true;
    if (this.serviceSub) {
      this.serviceSub.unsubscribe();
    }
    this.serviceSub = this.rvCalService.getRvCalBaseData(this.vehicle.DatiumAssetKey, this.age, this.km).subscribe(
      (result) => {
        this.isProcessing = false;
        try {
          let response = result.json();
          this.baseData = response.data;
          // console.log(this.baseData)
          this.initFormDefaultValues(isInitialCall);
        } catch (e) {

        }
      },
      (error) => {
        this.isProcessing = false;
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }

      },
      () => {

      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openCalculator(): void {
    const dialogRef = this.dialog.open(RvCalculatorComponent, {
      width: 'auto',
      height: 'auto',
      data: {}
    });
  }

  /*
  * @name changeDollarValues
  * @param per
  * @description Calculate the new dollar value and update the RV inc GST,Valuation Adj and Ex GST values
  * @return none
  */
  changeDollarValues() {
    this.terms.forEach(term => {
      // console.log(term, 'term')
      let rvGSTIndex = `rvGST_${term}`, diRvGSTIndex = `di_rvGST_${term}`, rvmAdjIndex = `rvmAdj_${term}`, rvIndex = `rv_${term}`, perIndex = `index_${term}`, diPerIndex = `di_index_${term}`;

      let perVal = this.RvCalFormModel[perIndex];
      // console.log('perVal', perVal)
      //RV inc GST
      this.RvCalFormModel[rvGSTIndex] = this.newDollarValue(perVal);
      // console.log('rvGST', this.RvCalFormModel[rvGSTIndex])

      let diPerVal = this.RvCalFormModel[diPerIndex];
      // console.log('perVal', perVal)
      //RV inc GST
      this.RvCalFormModel[diRvGSTIndex] = this.newDollarValue(diPerVal);
      // console.log('diRvGSTIndex', this.RvCalFormModel[diRvGSTIndex])

      //Valuation Adj
      this.RvCalFormModel[rvmAdjIndex] = this.calculateManualADJDollarValue(term);
      //console.log('ADJ', this.RvCalFormModel[rvmAdjIndex])

      //Ex GST
      this.RvCalFormModel[rvIndex] = this.newDollarValue(perVal, false);
      //console.log('RV', this.RvCalFormModel[rvIndex])
    });
  }

  /*
  * @name newDollarValue
  * @param per,newPrice-user input new price
  * @description Calculate the dollar value with percentage
  * @return none
  */
  newDollarValue(per: number = 0, isGst: boolean = true) {
    let dollar: number = 0;
    //dollar = (per / 100) * this.RvCalFormModel.new_price
    dollar = (per) * this.RvCalFormModel.new_price;
    // We need to divide by 1.1(coming from backend) to calculate the value without GST
    if (isGst == false) {
      dollar = dollar / this.baseData.gst;
    }
    return this.commonService.roundToXDigits(dollar, 8);
  }

  /*
  * @name calculateDollarValue
  * @param term,includeGst
  * @description Calculate dollar value
  * @return none
  */
  calculateDollarValue(term: number = 12, includeGst: boolean = false, isDi: boolean = false) {
    //console.log(term, includeGst)
    let Intercept = 0;
    let Slope = 0;
    let uTerm = this.getUserInputAge(term);
    let result = this.getSlopeNIntercept(uTerm, isDi);
    let KM = this.getUserInputKM(term);
    //console.log('New Index for ', term)
    //console.log('uTerm', uTerm)
    // console.log('KM', KM)
    // console.log('Slope Intercept', result)

    Intercept = result.Intercept;
    Slope = result.Slope;
    let outPut = ((Intercept + Slope * uTerm) * (this.baseData.datiumModelvalue * Math.exp(this.baseData.ageCoefficient * uTerm)) / Math.exp(this.baseData.kmCoefficient * uTerm * 2500)) * Math.exp(this.baseData.kmCoefficient * KM);
    if (isNaN(outPut)) {
      outPut = 0;
    }
    // console.log(outPut)
    outPut = this.commonService.roundToXDigits(outPut, 9);
    // console.log('No GST Index_' + term, outPut)
    let perIndex = `${isDi ? 'di_' : ''}index_${term}`;
    this.RvCalFormModel[perIndex] = outPut;
    if (includeGst === true) {
      return this.newDollarValue(outPut);
    } else {
      // console.log('GST Index_' + term, outPut)
      return this.newDollarValue(outPut, false);
    }
  }

  /*
  * @name calculateManualADJDollarValue
  * @param term,includeGst
  * @description Calculate manual adjustments
  * @return none
  */
  calculateManualADJDollarValue(term: number = 12) {
    let rvGSTIndex = `rvGST_${term}`;
    let rvDollar = this.RvCalFormModel[rvGSTIndex];
    let mAdjIndex = `mAdj_${term}`;
    let mAdjval = this.RvCalFormModel[mAdjIndex];
    let per = (mAdjval / 100);
    //console.log('adjustments_' + term)
    //console.log('Ajd', mAdjval)
    // console.log('dollar', rvDollar)
    // console.log('Ajd per', per)
    //Valuation Adj = (RV Inc GST/New Price + Manual Adj) x New Price
    //let result = (rvDollar + (rvDollar * per))
    let new_price = this.RvCalFormModel.new_price;
    let result = (rvDollar / new_price + per) * new_price;
    //console.log('Ajd Output', result)
    return result;
    //return this.newDollarValue(this.commonService.roundToXDigits(result))
  }

  /*
  * @name perDollar
  * @param value,type=dollar,per
  * @description Calculate value in percentage/dollar with NewPrice
  * @return Number
  */
  perDollar(value, type = 'dollar') {
    let output = value;
    if (type == 'dollar') {
      output = this.commonService.roundToXDigits((this.RvCalFormModel.new_price / 100) * value);
    } else if (type == 'per') {
      let per = (value / this.RvCalFormModel.new_price) * 100;
      output = this.commonService.roundToXDigits(per);
    }
    return output;
  }

  /*
  * @name calculateNewManualADJ
  * @param val,term
  * @description Calculate manual adjustments by input change
  * @return none
  */
  calculateNewManualADJ(val: number = 0, term: number = 12) {
    let rvmAdjIndex = `rvmAdj_${term}`;
    this.RvCalFormModel[rvmAdjIndex] = this.calculateManualADJDollarValue(term);
  }

  between(x, min, max) {
    return x >= min && x <= max;
  }

  /*
  * @name calculateNewValue
  * @param term
  * @description Calculate new dollar value
  * @return none
  */
  calculateNewValue(val: number = 0, term: number = 12, type: string = 'age') {
    //console.log(val, term, type)
    if (this.baseData.Ind_NewVehicle === false) {
      if (type == 'age') {
        if (!this.between(val, 0, 60)) {
          let rvIndex = `index_${term}`;
          let per = this.RvCalFormModel[rvIndex];

          this.RvCalFormModel['age_' + term] = term;
          this.RvCalFormModel['index_' + term] = this.baseData['per_' + term];

          this.RvCalFormModel['di_index_' + term] = this.baseData['di_per_' + term];

          let rvGSTIndex = `rvGST_${term}`;
          this.RvCalFormModel[rvGSTIndex] = this.calculateDollarValue(term, true); // With GST

          let diRvGSTIndex = `di_rvGST_${term}`;
          this.RvCalFormModel[diRvGSTIndex] = this.calculateDollarValue(term, true, true); // With GST

          let rvmAdjIndex = `rvmAdj_${term}`;
          this.RvCalFormModel[rvmAdjIndex] = this.calculateManualADJDollarValue(term);

          this.commonService.showSnakeBar("Age must be between 0 and 60");
          return false;
        } else {

        }
      }
      let rvGSTIndex = `rvGST_${term}`;
      this.RvCalFormModel[rvGSTIndex] = this.calculateDollarValue(term, true); // With GST

      let diRvGSTIndex = `di_rvGST_${term}`;
      this.RvCalFormModel[diRvGSTIndex] = this.calculateDollarValue(term, true, true); // With GST

      let rvmAdjIndex = `rvmAdj_${term}`;
      this.RvCalFormModel[rvmAdjIndex] = this.calculateManualADJDollarValue(term);

      let rvIndex = `rv_${term}`;
      this.RvCalFormModel[rvIndex] = this.calculateDollarValue(term); // Without GST
      //console.log(this.RvCalFormModel[rvGSTIndex], this.RvCalFormModel[rvmAdjIndex], this.RvCalFormModel[rvIndex])
    }
  }


  /*
  * @name getUserInputKM
  * @param term
  * @description Get user input KM based on term
  * @return none
  */
  getUserInputKM(term: number = 12): number {
    let KM = 0;
    if (term == 12) {
      KM = this.RvCalFormModel.km_12;
    } else if (term == 24) {
      KM = this.RvCalFormModel.km_24;
    } else if (term == 36) {
      KM = this.RvCalFormModel.km_36;
    } else if (term == 48) {
      KM = this.RvCalFormModel.km_48;
    } else if (term == 60) {
      KM = this.RvCalFormModel.km_60;
    }
    return KM;
  }

  /*
  * @name getUserInputAge
  * @param term
  * @description Get user input Age based on term
  * @return none
  */
  getUserInputAge(term: number = 12): number {
    let age = 0;
    if (term == 12) {
      age = this.RvCalFormModel.age_12;
    } else if (term == 24) {
      age = this.RvCalFormModel.age_24;
    } else if (term == 36) {
      age = this.RvCalFormModel.age_36;
    } else if (term == 48) {
      age = this.RvCalFormModel.age_48;
    } else if (term == 60) {
      age = this.RvCalFormModel.age_60;
    }
    return age;
  }

  /*
  * @name getUserInputAGE
  * @param term
  * @description Get user input AGE based on term
  * @return none
  */
  getUserInputAGE(term: number = 12): number {
    let age = 0;
    if (term == 12) {
      age = this.RvCalFormModel.age_12;
    } else if (term == 24) {
      age = this.RvCalFormModel.age_24;
    } else if (term == 36) {
      age = this.RvCalFormModel.age_36;
    } else if (term == 48) {
      age = this.RvCalFormModel.age_48;
    } else if (term == 60) {
      age = this.RvCalFormModel.age_60;
    }
    return age;
  }

  /*
  * @name getSlopeNIntercept
  * @param term
  * @description Get slope and intercept
  * @return none
  */
  getSlopeNIntercept(term: number = 12, isDi: boolean = false): slopeNIntercept {
    let Intercept = null;
    let Slope = null;
    if (term < 12) {
      Intercept = 'intercept_12_24';
      Slope = 'slope_12_24';
    } else if (term >= 12 && term < 24) {
      Intercept = 'intercept_12_24';
      Slope = 'slope_12_24';
    } else if (term >= 24 && term < 36) {
      Intercept = 'intercept_24_36';
      Slope = 'slope_24_36';
    } else if (term >= 36 && term < 48) {
      Intercept = 'intercept_36_48';
      Slope = 'slope_36_48';
    } else if (term >= 48 && term < 60) {
      Intercept = 'intercept_48_60';
      Slope = 'slope_48_60';
    } else if (term >= 60) {
      Intercept = 'intercept_48_60';
      Slope = 'slope_48_60';
    }
    // Check for datium index
    if (isDi === true) {
      Intercept = `di_${Intercept}`;
      Slope = `di_${Slope}`;
    }
    return {
      Intercept: this.baseData[Intercept],
      Slope: this.baseData[Slope]
    };
  }


  getVehicleValues() {
    try {
      //let index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey)
      //if (index != -1) {
      if ('rvValues' in this.vehicle) {
        let rvValues = this.vehicle['rvValues'];
        let reObj = {
          UserNewPrice: this.commonService.roundToXDigits(this.RvCalFormModel.new_price, 0),
          rv_dollar_12: this.commonService.roundToXDigits(this.RvCalFormModel.rvGST_12, 0),
          rv_dollar_24: this.commonService.roundToXDigits(this.RvCalFormModel.rvGST_24, 0),
          rv_dollar_36: this.commonService.roundToXDigits(this.RvCalFormModel.rvGST_36, 0),
          rv_dollar_48: this.commonService.roundToXDigits(this.RvCalFormModel.rvGST_48, 0),
          rv_dollar_60: this.commonService.roundToXDigits(this.RvCalFormModel.rvGST_60, 0),
          rv_per_12: this.commonService.roundToXDigits(this.perDollar(this.RvCalFormModel.rvGST_12, 'per'), 2),
          rv_per_24: this.commonService.roundToXDigits(this.perDollar(this.RvCalFormModel.rvGST_24, 'per'), 2),
          rv_per_36: this.commonService.roundToXDigits(this.perDollar(this.RvCalFormModel.rvGST_36, 'per'), 2),
          rv_per_48: this.commonService.roundToXDigits(this.perDollar(this.RvCalFormModel.rvGST_48, 'per'), 2),
          rv_per_60: this.commonService.roundToXDigits(this.perDollar(this.RvCalFormModel.rvGST_60, 'per'), 2),

          age_12: this.baseData.Ind_NewVehicle === true ? this.age : this.RvCalFormModel.age_12,
          age_24: this.RvCalFormModel.age_24,
          age_36: this.RvCalFormModel.age_36,
          age_48: this.RvCalFormModel.age_48,
          age_60: this.RvCalFormModel.age_60,
          km_12: this.baseData.Ind_NewVehicle === true ? this.km : this.RvCalFormModel.km_12,
          km_24: this.RvCalFormModel.km_24,
          km_36: this.RvCalFormModel.km_36,
          km_48: this.RvCalFormModel.km_48,
          km_60: this.RvCalFormModel.km_60,
          adj_12: this.RvCalFormModel.mAdj_12,
          adj_24: this.RvCalFormModel.mAdj_24,
          adj_36: this.RvCalFormModel.mAdj_36,
          adj_48: this.RvCalFormModel.mAdj_48,
          adj_60: this.RvCalFormModel.mAdj_60,
        };
        let checkEmpt = {
          rv_per_12: reObj.rv_per_12,
          //rv_per_24: reObj.rv_per_24,
          // rv_per_36: reObj.rv_per_36,
          // rv_per_48: reObj.rv_per_48,
          // rv_per_60: reObj.rv_per_60,
        };
        if (this.commonService.checkAllEmpty(checkEmpt)) {
          const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
            width: 'auto',
            height: 'auto',
            data: {
              title: 'All index are zero.Are you sure you want to capture values?'
            }
          });
          confirmDialogRef.afterClosed().subscribe(result => {
            if (result == 'yes') {
              return reObj;
            }
          });
        } else {
          return reObj;
        }
      }
      //}
    } catch (error) {
      //console.log(error)
      this.commonService.showSnakeBar('An error occurred while capturing RV. Please try again later');
    }
    return false;
  }

  captureCalculatorVal(DatiumAssetKey) {
    let vData = this.getVehicleValues();
    if (vData == false) {
      return false;
    }
    //console.log(vData, 'vData')
    this.loaderData.loading = true;
    this.loaderData.text = 'Capturing RV...';
    this.vehicleService.captureVehicleRV(DatiumAssetKey, 'calculator', vData).subscribe(
      (result) => {
        this.loaderData.loading = false;
        this.loaderData.text = '';
        this.commonService.showSnakeBar('RV has been captured successfully.');
        try {

        } catch (e) {

        }
      },
      (error) => {
        this.loaderData.loading = false;
        this.loaderData.text = '';
        try {
          let response = error.json();
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        } catch (e) {

        }
      },
      () => {
        //this.commonService.showSnakeBar('RV has been captured successfully.');
      }
    );
  }

  calculateValue() {
    if (!this.between(this.RvCalFormModel.age_12, 1, 60)) {
      this.commonService.showSnakeBar("Age must be between 1 and 60");
      return false;
    }
    else if (!this.between(this.RvCalFormModel.km_12, 0, 1000000)) {
      this.commonService.showSnakeBar("Age must be between 0 and 1000000");
      return false;
    } else {
      this.getVehicleBaseData(false);
    }
  }
}
