import { Component, OnInit, Input } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'vehicle-rv-values',
  templateUrl: './vehicle-rv-values.component.html',
  styleUrls: ['./vehicle-rv-values.component.scss']
})

export class VehicleRvValuesComponent implements OnInit {

  @Input() isRvValues: boolean;
  @Input() vehicleId;
  @Input() vehicle;

  public vehicleValueModel: any = this.getVehicleModel();
  public isProcessing: boolean = false;

  constructor(
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
    public vehicleService: VehicleService,
    private dataStore: DataStoreService
  ) { }

  ngOnInit() {

  }

  getVehicleModel() {
    return {
      term: undefined,
      comment: undefined
    }
  }

  getDisableStatus() {
    if (this.isRvValues && this.roleCheckService.isWriteAccess() && !this.isProcessing) {
      return false;
    }
    else {
      return true;
    }
  }

  validateFormAndSave(form) {
    if (this.vehicleValueModel.comment) {
      this.isProcessing = true;
      if (this.vehicleValueModel.term) {
        const value = parseInt(this.vehicleValueModel.term);
        if (!Number.isInteger(value) || value < -100 || value > 100) {
          this.vehicleValueModel.term = undefined;
          this.commonService.showSnakeBar('Invalid Inputs');
          this.isProcessing = false;
        }
        else {
          this.vehicleValueModel.term = value;
          this.saveValues(form.form);
        }
      }
      else {
        this.commonService.showSnakeBar('Please add adjustment.');
        this.isProcessing = false;
      }
    }
    else {
      this.commonService.showSnakeBar('Please add reason for change.');
      this.isProcessing = false;
    }
  }

  saveValues(form) {
    const reqModel = {
      comments: this.vehicleValueModel.comment,
      term_12: this.vehicleValueModel.term,
      term_24: this.vehicleValueModel.term,
      term_36: this.vehicleValueModel.term,
      term_48: this.vehicleValueModel.term,
      term_60: this.vehicleValueModel.term
    }
    const vId = this.vehicleId;
    this.dataStore.setVeLoader(vId, true, "Saving adjustments...");
    if (this.roleCheckService.isSuperAdmin()) {
      this.vehicleService.saveDiHistory(reqModel, this.vehicleId).subscribe(res => {
        try {
          this.dataStore.setVeLoader(vId, true, "Adjustments has been made successfully.");
          form.reset();
          this.dataStore.resetVehicleDetailsReload(vId, true, true);
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        this.isProcessing = false;
        this.dataStore.setVeLoader(vId, false, "");
        const response = err.json();
        if (response.errors) {
          this.formatErrors(response.errors);
        }
        else {
          this.commonService.commonSnakeBar();
        }
      })
    }
    else {
      this.vehicleService.saveRvHistory(reqModel, this.vehicleId).subscribe(res => {
        try {
          this.dataStore.setVeLoader(vId, true, "Adjustments has been made successfully.");
          form.reset();
          this.dataStore.resetVehicleDetailsReload(this.vehicleId, true, true);
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        this.isProcessing = false;
        this.dataStore.setVeLoader(vId, false, "");
        const response = err.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
          this.formatErrors(response.errors);
        } else {
          this.commonService.commonSnakeBar();
        }

      })
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  getBtnText() {
    if (this.isProcessing) {
      return 'Saving...'
    } else {
      if (!this.commonService.toggleIndex(this.vehicle, 'toggle')) { //Vehicle is not accepted yet
        return 'Accept & Save Changes'
      } else {
        return 'Save Changes'
      }
    }
  }
}

