import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicles-recently-searched',
  templateUrl: './vehicles-recently-searched.component.html',
  styleUrls: ['./vehicles-recently-searched.component.scss']
})
export class VehiclesRecentlySearchedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
