import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { AppHeadings } from '../../configs/app-headings';
import { CommonService } from '../../services/common.service';
import { VehicleService } from '../../services/vehicle.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-price-tracker-wrapper',
  templateUrl: './price-tracker-wrapper.component.html',
  styleUrls: ['./price-tracker-wrapper.component.scss']
})
export class PriceTrackerWrapperComponent implements OnInit, OnDestroy {

  Highcharts = Highcharts;
  pageTitle: string = '';
  searchForm: FormGroup;
  otherSearchForm: FormGroup;
  formError = {};
  allFilterList: Array<any> = [];
  apiParams: any = {
    filter: 'VFacts_Class',
    VFacts_Class: '',
    VFacts_Segment: '',
    Make: '',
    Family: '',
    Variant_Desc: '',
    Body_Type_Desc: '',
    Fuel_Type_Desc: '',
    Transmission: '',
    Cylinders: '',
    Region: '',
    State: '',
    StartDate: '',
    EndDate: '',
    Km_Col: 'Price_20000'
  }
  priceFilterControls: Array<any> = [
    {
      'title': 'VFacts Class',
      'name': 'VFacts_Class'
    },
    {
      'title': 'VFacts Segment',
      'name': 'VFacts_Segment'
    },
    {
      'title': 'Make',
      'name': 'Make'
    },
    {
      'title': 'Family',
      'name': 'Family'
    },
    {
      'title': 'Variant Desc',
      'name': 'Variant_Desc'
    },
    {
      'title': 'Body Type Desc',
      'name': 'Body_Type_Desc'
    },
    {
      'title': 'Fuel Type Desc',
      'name': 'Fuel_Type_Desc'
    },
    {
      'title': 'Transmission',
      'name': 'Transmission'
    },
    {
      'title': 'Cylinders',
      'name': 'Cylinders'
    }
  ];
  priceFilterOtherControls: Array<any> = [
    {
      'name': 'Km_Col',
      'title': 'Annual KM'
    },
    {
      'name': 'StartDate',
      'title': 'Start Sold Date'
    },
    {
      'name': 'EndDate',
      'title': 'End Sold Date'
    },
    {
      'name': 'Year_Group',
      'title': 'Year Group'
    }
  ]
  priceFilterEtcControls: Array<any> = [

    {
      'title': 'Region',
      'name': 'Region'
    },
    /*{
      'title': 'State',
      'name': 'State'
    }*/
  ]
  priceFilterOrder: Array<any> = ['VFacts_Class', 'VFacts_Segment', 'Make', 'Family', 'Variant_Desc', 'Body_Type_Desc', 'Fuel_Type_Desc', 'Transmission', 'Cylinders'];
  filterCount = [0, 1, 2, 3, 4]
  isFilterLoad: boolean = false
  isLoading: boolean = false
  isAgeChartImgLoad: boolean = false
  isTimeChartImgLoad: boolean = false
  OtherFilterList: Array<any> = [];
  isFirstTime = true;
  StartDate = null
  EndDate = null

  initStartDate = null
  initEndDate = null

  priceFilterOtherModel = {
    Km_Col: 'Price_20000',
    StartDate: this.initStartDate,
    EndDate: this.initEndDate,
    Year_Group: []
  }
  filterSubCall: Subscription
  filterRecordSubCall: Subscription
  ageChartLoader: boolean = false
  timeChartLoader: boolean = false
  ageChartHtml = ""
  timeChartHtml = "";
  regressionPoints = [];
  regressionPrice = 0;
  ageList = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120]
  constructor(
    private appHeading: AppHeadings,
    private vehicleService: VehicleService,
    private fb: FormBuilder,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    let heading: string = this.appHeading.vehicle.p_title.priceTracker
    this.pageTitle = heading;
    this.commonService.setTitle(this.pageTitle);
    this.setUpPriceFilterForm();
    this.getAllFilterList();
  }


  /*
  * @name setUpPriceFilterForm
  * @param None
  * @description Setup filter form
  * @return none
  */
  setUpPriceFilterForm() {
    this.searchForm = this.fb.group(
      {
        VFacts_Class: new FormControl(''),
        VFacts_Class_check: new FormControl(false),
        VFacts_Segment: new FormControl([]),
        VFacts_Segment_check: new FormControl(false),
        Make: new FormControl([]),
        Make_check: new FormControl(false),
        Family: new FormControl([]),
        Family_check: new FormControl(false),
        Variant_Desc: new FormControl([]),
        Variant_Desc_check: new FormControl(false),
        Body_Type_Desc: new FormControl([]),
        Body_Type_Desc_check: new FormControl(false),
        Fuel_Type_Desc: new FormControl([]),
        Fuel_Type_Desc_check: new FormControl(false),
        Transmission: new FormControl([]),
        Transmission_check: new FormControl(false),
        Cylinders: new FormControl([]),
        Cylinders_check: new FormControl(false),
      }
    )
    this.otherSearchForm = this.fb.group({

      Region: new FormControl([]),
      Region_check: new FormControl(false),
      //State: new FormControl([]),
      //State_check: new FormControl(false)
    })

    /*otherFilters: this.fb.group({
      StartDate: new FormControl(null),
      EndDate: new FormControl(null),
      Year_Group: new FormControl(null),
      Km_Col: new FormControl(null)
    })*/
  }


  imgPreLoader(type = 'age') {
    if (type == 'age') {
      this.isAgeChartImgLoad = false
    }
    else if (type == 'time') {
      this.isTimeChartImgLoad = false
    }
  }

  imgLoadingError(type = 'age') {
    if (type == 'age') {
      this.commonService.showSnakeBar('An error occurred while creating Price by Age (Months) chart.Please try again later.');
    }
    else if (type == 'time') {
      this.commonService.showSnakeBar('An error occurred while creating Price over Time chart.Please try again later.');
    }
  }

  /*
  * @name getAllFilterList
  * @param toggle:boolean
  * @description Get all filter list based on selection
  * @return none
  */
  getAllFilterList(toggle = false) {
    ///console.log(toggle, 'toggle')
    //this.chartLoader(toggle == false ? true : false)
    this.toggleLoader(toggle ? 'isLoading' : 'isFilterLoad', true)
    //console.log(this.apiParams, '------this is the api params');
    if (this.filterSubCall) {
      this.filterSubCall.unsubscribe()
    }
    this.apiParams['ignoreFilter'] = toggle
    this.filterSubCall = this.vehicleService.getPriceTrackerFilters(this.apiParams, 'post').subscribe(
      (result) => {
        try {
          this.toggleLoader(toggle ? 'isLoading' : 'isFilterLoad', false)
          let response = result.json();
          this.allFilterList = response.data.filters;
          // Only update other filter on initial load
          if (toggle == false) {
            this.OtherFilterList = response.data.otherFilters;
            if (this.OtherFilterList['StartDate']) {
              this.StartDate = new Date(this.OtherFilterList['StartDate'])
              this.initStartDate = this.StartDate
            }
            if (this.OtherFilterList['EndDate']) {
              this.EndDate = new Date(this.OtherFilterList['EndDate'])
              this.initEndDate = this.EndDate
            }
            this.priceFilterOtherModel.EndDate = this.initEndDate
            this.priceFilterOtherModel.StartDate = this.initStartDate
            //console.log(this.StartDate, this.EndDate)
            //this.getPriceRecords()
          }
        } catch (e) {
          console.log(e)
        }
      },
      (error) => {
        if (toggle == false) {
          //this.chartLoader(false)
          this.imgPreLoader('age')
          this.imgPreLoader('time')
        }
        this.toggleLoader(toggle ? 'isLoading' : 'isFilterLoad', false)
        //console.log(error)
        let response = error.json();
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    )
  }


  /*
  * @name sendFilterSelect
  * @param event:EventEmitter
  * @description Get the selection data from filter component
  * @return none
  */
  detectFilterSelection(event) {
    //console.log(this.searchForm, this.allFilterList, event)
    this.apiParams = this.commonService.getFilterApiParams(this.searchForm, this.allFilterList, event)
    //console.log(this.apiParams, 'this.apiParams')
    //this.isFirstTime = false
    try {
      this.getAllFilterList(true)
      //this.getPriceRecords()
    } catch (e) {

    }
  }

  detectOtherFilterSelection() {
    this.getPriceRecords()
  }

  /*
  * @name checkDatePickerDisable
  * @param disable/enable Sold Start/End Date
  * @description toggle the search loader
  * @return none
  */
  checkDatePickerDisable(name) {
    let res = false
    if (name == 'StartDate') {
      res = !this.StartDate ? true : false
    } else if (name == 'EndDate') {
      res = !this.EndDate ? true : false
    }
    return res
  }

  /*
  * @name toggleLoader
  * @param loader(toggle variable name),toggle:boolean
  * @description toggle the search loader
  * @return none
  */
  toggleLoader(loader = 'isLoading', toggle = false) {
    // console.log(toggle, loader, '---------------------toggle')
    this[loader] = toggle
    for (var control in this.searchForm.controls) {
      if (toggle == true) {
        this.searchForm.controls[control].disable();
      } else {
        this.searchForm.controls[control].enable();
      }
    }
    if (toggle == true) {
      //this.commonService.showSnakeBar('Fetching filters..')
    }
  }

  /*
  * @name getFormResetIniObj
  * @param Get for filter reset objects
  * @return Object
  */
  getFormResetIniObj(formData = null) {
    if (formData) {
      //selectedFilters, origData
      let selectedFilters = formData.selectedFilters
      let origData = formData.origData
      let resetObj =
      {
        VFacts_Class: selectedFilters['VFacts_Class'] ? selectedFilters['VFacts_Class'] : '',
        VFacts_Segment: selectedFilters['VFacts_Segment'] ? selectedFilters['VFacts_Segment'] : '',
        Make: selectedFilters['Make'] ? selectedFilters['Make'] : '',
        Family: selectedFilters['Family'] ? selectedFilters['Family'] : '',
        Variant_Desc: selectedFilters['Variant_Desc'] ? selectedFilters['Variant_Desc'] : '',
        Body_Type_Desc: selectedFilters['Body_Type_Desc'] ? selectedFilters['Body_Type_Desc'] : '',
        Fuel_Type_Desc: selectedFilters['Fuel_Type_Desc'] ? selectedFilters['Fuel_Type_Desc'] : '',
        Transmission: selectedFilters['Transmission'] ? selectedFilters['Transmission'] : '',
        Cylinders: selectedFilters['Cylinders'] ? selectedFilters['Cylinders'] : '',
      }
      let resetOtherObj = {

        Region: selectedFilters['Region'] ? selectedFilters['Region'] : '',
        //State: selectedFilters['State'] ? selectedFilters['State'] : ''
      }
      let resetOriObj =
      {
        VFacts_Class: origData['VFacts_Class'] ? origData['VFacts_Class'] : '',
        VFacts_Class_check: origData['VFacts_Class_check'] ? origData['VFacts_Class_check'] : false,
        VFacts_Segment: origData['VFacts_Segment'] ? origData['VFacts_Segment'] : '',
        VFacts_Segment_check: origData['VFacts_Segment_check'] ? origData['VFacts_Segment_check'] : false,
        Make: origData['Make'] ? origData['Make'] : [],
        Make_check: origData['Make_check'] ? origData['Make_check'] : false,
        Family: origData['Family'] ? origData['Family'] : [],
        Family_check: origData['Family_check'] ? origData['Family_check'] : false,
        Variant_Desc: origData['Variant_Desc'] ? origData['Variant_Desc'] : [],
        Variant_Desc_check: origData['Variant_Desc_check'] ? origData['Variant_Desc_check'] : false,
        Body_Type_Desc: origData['Body_Type_Desc'] ? origData['Body_Type_Desc'] : [],
        Body_Type_Desc_check: origData['Body_Type_Desc_check'] ? origData['Body_Type_Desc_check'] : false,
        Fuel_Type_Desc: origData['Fuel_Type_Desc'] ? origData['Fuel_Type_Desc'] : [],
        Fuel_Type_Desc_check: origData['Fuel_Type_Desc_check'] ? origData['Fuel_Type_Desc_check'] : false,
        Transmission: origData['Transmission'] ? origData['Transmission'] : [],
        Transmission_check: origData['Transmission_check'] ? origData['Transmission_check'] : false,
        Cylinders: origData['Cylinders'] ? origData['Cylinders'] : [],
        Cylinders_check: origData['Cylinders_check'] ? origData['Cylinders_check'] : false,
      }
      let resetOriOtherObj = {
        Region: origData['Region'] ? origData['Region'] : [],
        Region_check: origData['Region_check'] ? origData['Region_check'] : false,
        //State: origData['State'] ? origData['State'] : [],
        //State_check: origData['State_check'] ? origData['State_check'] : false
      }
      return {
        resetObj, resetOriObj, resetOtherObj, resetOriOtherObj
      }
    } else {
      let resetObj =
      {
        VFacts_Class: null,
        VFacts_Segment: '',
        Make: '',
        Family: '',
        Variant_Desc: '',
        Body_Type_Desc: '',
        Fuel_Type_Desc: '',
        Transmission: '',
        Cylinders: '',
      }
      let resetOtherObj = {

        Region: '',
        //State: ''
      }
      let resetOriObj =
      {
        VFacts_Class: '',
        VFacts_Class_check: false,
        VFacts_Segment: [],
        VFacts_Segment_check: false,
        Make: [],
        Make_check: false,
        Family: [],
        Family_check: false,
        Variant_Desc: [],
        Variant_Desc_check: false,
        Body_Type_Desc: [],
        Body_Type_Desc_check: false,
        Fuel_Type_Desc: [],
        Fuel_Type_Desc_check: false,
        Transmission: [],
        Transmission_check: false,
        Cylinders: [],
        Cylinders_check: false,
      }
      let resetOriOtherObj = {
        Region: [],
        Region_check: false,
        //State: [],
        //State_check: false
      }
      return {
        resetObj, resetOriObj, resetOtherObj, resetOriOtherObj
      }
    }
  }


  /*
  * @name getPriceRecords
  * @param Get PriceTracker records
  * @return none
  */
  getPriceRecords() {
    this.chartLoader(true)
    //console.log(this.apiParams, '------this is the api params');
    let formData = this.getFormFilterValues(this.searchForm)
    //console.log(formData, 'formData')
    if (this.filterRecordSubCall) {
      this.filterRecordSubCall.unsubscribe()
    }
    this.filterRecordSubCall = this.vehicleService.getPriceTrackerRecords(formData, 'post').subscribe(
      (result) => {
        try {
          this.chartLoader(false)
          let response = result.json();
          let apiData = response.data;
          if (apiData['age']['image']) {
            this.ageChartHtml = apiData.age.image
            this.isAgeChartImgLoad = true
          } else {
            this.commonService.showSnakeBar('An error occurred while creating Price by Age (Months) chart.Please try again later.');
          }
          if (apiData['age']['points']) {
            this.regressionPoints = apiData['age']['points'];
            this.regressionPoints.slice(1, this.regressionPoints.length);
            this.reCalculateRegressionPoint(this.regressionPrice);
          }
          if (apiData['time']['image']) {
            this.isTimeChartImgLoad = true
            this.timeChartHtml = apiData.time.image
          } else {
            this.commonService.showSnakeBar('An error occurred while creating Price over Time chart.Please try again later.');
          }
        } catch (e) {

        }
      },
      (error) => {
        this.chartLoader(false)
        this.imgPreLoader('age')
        this.imgPreLoader('time')
        //console.log(error)
        let response = error.json();
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    )
  }

  calRegressionValue = [];
  reCalculateRegressionPoint(value) {
    if (value > 0) {
      this.calRegressionValue = [];
      this.regressionPoints.map(el => {
        this.calRegressionValue.push((el * value / 100));
      })
    } else {
      this.calRegressionValue = [];
    }
  }


  /*
  * @name chartLoader
  * @param toggle:boolean
  * @description show/hide chart loader
  * @return None
  */
  chartLoader(toggle = false) {
    this.ageChartLoader = toggle
    this.timeChartLoader = toggle
  }


  emitFilterSelection(data, controlName) {
    if (!this.isFirstTime) {
      this.getPriceRecords()
    }
  }


  getFormFilterValues(searchFormObj) {
    let formData = this.commonService.getInitialFilterApiParams(searchFormObj.value, this.allFilterList)
    if (formData) {
      let finalFormData = Object.assign(formData, this.priceFilterOtherModel)
      if (finalFormData.Year_Group.length > 0) {
        finalFormData.Year_Group = finalFormData.Year_Group.join(',')
      } else {
        finalFormData.Year_Group = ''
      }
      if (finalFormData.StartDate) {
        finalFormData.StartDate = this.commonService.transFormDate(finalFormData.StartDate, 'yyyy-MM-dd')
      }
      if (finalFormData.EndDate) {
        finalFormData.EndDate = this.commonService.transFormDate(finalFormData.EndDate, 'yyyy-MM-dd')
      }
      // Merging otherSearchForm
      let otherFormData = this.commonService.getInitialFilterApiParams(this.otherSearchForm.value, this.OtherFilterList)
      //console.log(this.otherSearchForm.value, otherFormData, this.OtherFilterList)
      finalFormData = Object.assign(finalFormData, otherFormData)
      //console.log(finalFormData)
      return finalFormData
    }
    return formData
  }


  /*
  * @name handleSearchForm
  * @param searchFormObj:FormGroup
  * @description handle the search form
  * @return none
  */
  handleSearchForm(searchFormObj: FormGroup) {
    if (searchFormObj.valid) {
      if (!this.checkObjEmpty(searchFormObj.value)) {
        this.isFirstTime = false;
        this.getFormFilterValues(searchFormObj)
        this.getPriceRecords()
      } else {
        this.isFirstTime = true
      }
    } else {
      this.formValidate(searchFormObj);
    }
  }

  /*
  * @name resetFilters
  * @param Clear search form
  * @return none
  */
  resetFilters() {
    this.apiParams = {
      filter: 'VFacts_Class',
      VFacts_Class: '',
      VFacts_Segment: '',
      Make: '',
      Family: '',
      Variant_Desc: '',
      Body_Type_Desc: '',
      Fuel_Type_Desc: '',
      Transmission: '',
      Cylinders: '',
      Region: '',
      // State: '',
      StartDate: this.initStartDate,
      EndDate: this.initEndDate,
      Km_Col: 'Price_20000'
    }
    let resetObj = this.getFormResetIniObj()

    let resetOriObj = resetObj.resetOriObj

    this.searchForm.setValue(resetOriObj)
    this.otherSearchForm.setValue(resetObj.resetOriOtherObj)
    this.formError = {}
    this.priceFilterOtherModel.StartDate = this.initStartDate
    this.priceFilterOtherModel.EndDate = this.initEndDate
    this.priceFilterOtherModel.Year_Group = []

    this.getAllFilterList(true);
    this.isFirstTime = true
    //this.getPriceRecords()
  }


  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        //console.log(key, this.searchForm.contains(key))
        if (this.searchForm.contains(key)) {
          let control = this.searchForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
          //console.log(this.formError, control)
        }
      });
    }
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form
  * @return none
  */
  formValidate(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });
  }

  ngOnDestroy() {
    if (this.filterSubCall) {
      this.filterSubCall.unsubscribe()
    }
    if (this.filterRecordSubCall) {
      this.filterRecordSubCall.unsubscribe()
    }
  }


  checkObjEmpty(obj) {
    for (var key in obj) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          return false;
        }
      } else if (obj[key] !== null && obj[key] != "") {
        return false;
      }
    }
    return true;
  }
}
