import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { ChartType, ChartOptions } from 'chart.js';
import { Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

@Component({
  selector: 'app-graph-widget',
  templateUrl: './graph-widget.component.html',
  styleUrls: ['./graph-widget.component.scss']
})
export class GraphWidgetComponent implements OnInit {

  @Input() widgetType: string;

  public isNewVehiclesLoading: boolean = false;
  public newVehiclesData: any = [];

  public isRecentSearchLoading: boolean = false;
  public recentSeachesData: any = [];
  public barChartHtml: string = "";
  public vehicleSearchType: string = "week";
  public hasNewVehicleListAccess: boolean = false;
  public hasQuickSearchAccess: boolean = false;

  public pieChartLabels: Label[] = [];
  public pieChartData: Array<number> = [];
  public chartType: ChartType = 'pie';
  colors: Array<string> = ['#E60000', '#008000', '#2ca9e1', '#FFCE1E', '#FFC0CB', '#4EE567', '#FF4168', '#FD7E14', '#17A2B8', '#EA62CD'];
  public chartColors: Color[] = [
    {
      backgroundColor: this.colors
    }
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: { display: false },
  };

  constructor(
    private dashboardService: DashboardService,
    private roleCheckService: RoleCheckerService,
    private jwtService: JwtService,
    private commonService: CommonService
  ) {
    this.hasNewVehicleListAccess = (this.roleCheckService.isSuperAdmin() || (this.roleCheckService.isCompanyOwner() && this.roleCheckService.checkAutoAccept() == false ? true : false));
    let permissions: Array<any> = this.jwtService.getUserPermissions();
    if (permissions.includes('quick-search')) {
      this.hasQuickSearchAccess = true;
    }
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    if (this.widgetType == "vehicleSearched") {
      if (this.hasQuickSearchAccess)
        this.getVehiclesSearched();
    } else {
      if (this.hasNewVehicleListAccess)
        this.getNewVehiclesAdded();
    }
  }

  getVehiclesSearched(type: string = 'week') {
    this.vehicleSearchType = type;
    let params1 = { searchType: type };
    this.isRecentSearchLoading = true;
    this.barChartHtml = "";
    this.dashboardService.recenlySearchedVehiclesChart(params1).subscribe(
      (result) => {
        let response = result.json();
        if (response.status == 200) {
          let data = response.data;
          this.recentSeachesData = data.searchResults
          if (this.recentSeachesData.length) {
            this.barChartHtml = data.barChartResponse;
          }
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isRecentSearchLoading = false;
      },
      (error) => {
        this.isRecentSearchLoading = false;
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      });
  }

  getNewVehiclesAdded() {
    this.isNewVehiclesLoading = true;
    this.dashboardService.getNewAddedVehiclesChart().subscribe(
      (result) => {
        let response = result.json();
        if (response.status == 200) {
          this.newVehiclesData = response.data;
          if (this.newVehiclesData.length) {
            this.pieChartLabels = [];
            this.pieChartData = [];
            for (let i = 0; i < this.newVehiclesData.length; i++) {
              this.pieChartLabels.push(this.newVehiclesData[i].y);
              this.pieChartData.push(this.newVehiclesData[i].x);
            }
          }
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isNewVehiclesLoading = false;

      },
      (error) => {
        this.isNewVehiclesLoading = false;
        let response = error.json();
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      });
  }

  calculateBrandPercentage(brandCount: number) {
    let perc = 0;
    let itemsTotal: number = 0;
    for (let i = 0; i < this.pieChartData.length; i++) {
      itemsTotal += this.pieChartData[i];
    }
    perc = ((brandCount / itemsTotal) * 100);
    return Math.round(perc);
  }

}
