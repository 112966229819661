import { Component, OnInit, Input } from '@angular/core';
import { DataStoreService } from '../../services/data-store.service';
import { AppHeadings } from '../../configs/app-headings';
import { CommonService } from '../../services/common.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'app-bi-filter-wrapper',
  templateUrl: './bi-filter-wrapper.component.html',
  styleUrls: ['./bi-filter-wrapper.component.scss']
})
export class BiFilterWrapperComponent implements OnInit {

  @Input() routeName: string;
  pageTitle: string = '';
  serverError = {}
  filterState = {
    submit: false,
    reset: false,
    initialState: false,
    data: {},
    error: false,
    loader: true,
    reqType: 'search',
    exportCsvPageNo: null
  }
  public totalRecords: number = 0;
  public exportDataOptions: Array<{
    key: string,
    value: number
  }> = [];
  public exportRecordsLimit: number = 200;
  public isSuperAdmin: boolean = false;
  public isExporting: boolean = false;
  public isSearchFinished: boolean = true;
  public heading: string = '';
  public selectedExportPage: string = '';
  public type: string = ""
  constructor(
    private appHeading: AppHeadings,
    private commonService: CommonService,
    private roleCheckService: RoleCheckerService,
  ) {
    /*if (this.routeName == '/vehicle-list-used') {
      this.type = 'old'
    } else if (this.routeName == '/vehicle-list-new') {
      this.type = 'new'
    }*/
    if (location.pathname.includes('vehicle-search-new') || location.pathname.includes('vehicle-list-new')) {
      this.type = 'new'
    } else if (location.pathname.includes('vehicle-search-used') || location.pathname.includes('vehicle-list-used')) {
      this.type = 'old'
    }
  }

  ngOnInit() {

    if (this.type == 'old') {
      this.heading = this.appHeading.vehicle.heading.used_vehicle
    } else if (this.type == 'new') {
      this.heading = this.appHeading.vehicle.heading.new_vehicle
    }
    this.commonService.setTitle(this.heading)
    this.pageTitle = this.heading;
    this.commonService.setTitle(this.pageTitle);
    if (this.roleCheckService.isSuperAdmin()) {
      this.isSuperAdmin = true;
    }
  }

  searchResponse(response) {
    this.isSearchFinished = true;
    this.filterState = response;
    //if filters cleared then reset total records
    if (response.clearFilter && response.clearFilter === true) {
      this.totalRecords = 0;
      this.exportDataOptions = [];
      this.pageTitle = this.heading;
      this.selectedExportPage = '';
    }
  }


  vehicleListState(event) {
    //console.log(event)
  }

  totalRecordsReturned(records: number) {
    this.totalRecords = records;
    this.pageTitle = this.heading + ` (${this.totalRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`;
    this.selectedExportPage = "";
    this.exportDataOptions = [];
    if (this.totalRecords > this.exportRecordsLimit) {
      const division = Math.ceil((this.totalRecords / this.exportRecordsLimit));
      for (let i = 1; i <= division; i++) {
        this.exportDataOptions.push({
          value: i,
          key: i < division ? `${((i - 1) * this.exportRecordsLimit) + 1} to ${i * this.exportRecordsLimit}` : `${((i - 1) * this.exportRecordsLimit) + 1} to ${this.totalRecords}`
        })
      }
    }

  }

  exportRecords() {
    if (this.exportDataOptions.length > 0 && this.selectedExportPage === '') {
      return false;
    }
    this.isExporting = true;
    if (this.totalRecords > this.exportRecordsLimit) {
      this.filterState.exportCsvPageNo = this.selectedExportPage;
    } else {
      this.filterState.exportCsvPageNo = 1;
    }
    this.filterState = { ...this.filterState, reqType: 'download' };
  }

  exportFinishEvent(event: string) {
    if (event === 'done') {
      this.isExporting = false;
    }
  }

  searchFinshedEvent(event: string) {
    if (event === 'done') {
      this.isSearchFinished = false;
    }
  }
}
