import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'risk-adjustments',
  templateUrl: './risk-adjustments.component.html',
  styleUrls: ['./risk-adjustments.component.scss']
})
export class RiskAdjustmentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
