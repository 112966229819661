import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';
import { RvCalculatorComponent } from '../../shared/calculator/rv-calculator/rv-calculator.component';
import { RoleCheckerService } from '../../services/role-checker.service';
import { RvValuesComponent } from '../../shared/calculator/rv-values/rv-values.component';
import { GaEventsService } from '../../services/ga-events.service';
import { environment } from 'src/environments/environment';
import { QuickSearchModalComponent } from 'src/app/shared/quick-search-modal/quick-search-modal.component';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public innerWidth: any;
  public showMobileMenu: boolean = false;
  public userName: string = "";
  public userEmail: string = "";

  portalUrl: string = ""
  isQuickSearch: boolean = false;
  hasBasketAccess: boolean = false;
  constructor(
    public dialog: MatDialog,
    public roleCheckService: RoleCheckerService,
    private gaEvent: GaEventsService,
    private jwtService: JwtService
  ) {
    let permissions: Array<any> = this.jwtService.getUserPermissions();
    if (permissions.includes('quick-search')) {
      this.isQuickSearch = true;
    }
    if (permissions.includes('basket')) {
      this.hasBasketAccess = true;
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.portalUrl = environment.portalUrl
    this.userName = this.jwtService.getUserName();
    this.userEmail = this.jwtService.getUserEmail();
  }


  openCalculator(): void {

    /*let vehicle = { "datiumAssetKey": "DI0401CMAFAAAA", "vehicleDetails": { "make": "Daihatsu", "model": "Mira", "description": "L201 Hatchback 3dr Man 4sp 850", "units": 0, "new_price": 8989, "year_group": 1991 }, "diValues": { "dollarValue": { "12_30000": { "formatted": "4,984" }, "24_60000": { "formatted": "4,019" }, "36_90000": { "formatted": "3,374" }, "48_120000": { "formatted": "2,845" }, "60_150000": { "formatted": "2,413" } }, "percentageValue": { "12_30000": { "formatted": 55.44 }, "24_60000": { "formatted": 44.71 }, "36_90000": { "formatted": 37.53 }, "48_120000": { "formatted": 31.65 }, "60_150000": { "formatted": 26.84 } } }, "dmValues": { "dollarValue": { "12_30000": { "formatted": "4,534" }, "24_60000": { "formatted": "3,570" }, "36_90000": { "formatted": "2,924" }, "48_120000": { "formatted": "2,396" }, "60_150000": { "formatted": "1,963" } }, "percentageValue": { "12_30000": { "formatted": 50.44 }, "24_60000": { "formatted": 39.71 }, "36_90000": { "formatted": 32.53 }, "48_120000": { "formatted": 26.65 }, "60_150000": { "formatted": 21.84 } } } }

    const dialogRef = this.dialog.open(RvValuesComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        vehicle: vehicle
      }
    });*/
    if (!this.roleCheckService.isSuperAdmin()) {
      let gaData = {
        'event': 'rvCalculator'
      }
      this.gaEvent.sendGaTagConfigData(gaData)
    }
    const dialogRef = this.dialog.open(RvCalculatorComponent, {
      width: 'auto',
      height: 'auto',
      data: {}
    });
  }

  openQuickSearch(): void {
    const dialogRef = this.dialog.open(QuickSearchModalComponent, {
      width: 'auto',
      height: 'auto',
      panelClass: 'full-screen-modal',
      data: {}
    });
  }

  // Check device width
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  handlePortalUrl(url) {
    window.location.href = url;
  }

  public chkStatus(status): void {
    this.showMobileMenu = status;
  }

}
