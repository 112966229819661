import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonService } from 'src/app/services/common.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'vehicle-linked',
  templateUrl: './vehicle-linked.component.html',
  styleUrls: ['./vehicle-linked.component.scss']
})
export class VehicleLinkedComponent implements OnInit {

  @Input() vehicle;
  constructor(public dialog: MatDialog, public roleCheckService: RoleCheckerService, public commonService: CommonService) { }

  ngOnInit() {
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
