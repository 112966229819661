import { Component, OnInit, Input } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { AppHeadings } from 'src/app/configs/app-headings';
@Component({
  selector: 'app-bi-filter-search',
  templateUrl: './bi-filter-search.component.html',
  styleUrls: ['./bi-filter-search.component.scss']
})
export class BiFilterSearchComponent implements OnInit {

  @Input() routeName: string;
  pageHeading: string = '';
  type: string = ''
  
  constructor(
    private commonService: CommonService,
    private appHeading: AppHeadings
    ) {
  }

  ngOnInit() {
    let heading: string = ''
    if (this.routeName === '/vehicle-list-used') {
      heading = this.appHeading.vehicle.heading.used_vehicle;
      this.type = 'old';
    } else if (this.routeName === '/vehicle-list-new') {
      heading = this.appHeading.vehicle.heading.new_vehicle;
      this.type = 'new';
    }
    this.pageHeading = heading;
    this.commonService.setTitle(heading);
  }

  searchResponse(response) {
    if (response.submit == true) {
      this.commonService.setLocalStorage('vehicle_filters', JSON.stringify(response))
      this.commonService.sendUserTo(this.routeName)
    }
  }
}
