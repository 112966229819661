import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { JwtService } from '../../services/jwt.service';
import { RoleCheckerService } from '../../services/role-checker.service';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { GaEventsService } from '../../services/ga-events.service';
import { environment } from 'src/environments/environment';
import { httpStatusCodes } from 'src/app/configs/http-status-codes';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {

  nameAbbreviation: string = "";
  @Input() userName: string = "";
  @Input() userEmail: string = "";

  constructor(
    public jwtService: JwtService,
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
    private router: Router,
    public userService: UserService,
    private gaEvent: GaEventsService
  ) { }

  ngOnInit() {
    if (this.userName !== '') {
      let matches: any = this.userName.match(/\b(\w)/g);
      this.nameAbbreviation = matches.join('');
    }
  }

  editProfile() {
    window.location.href = environment.portalUrl + "my-account";
  }

  logout() {
    let gaData = {
      'event': 'userLogout',
      'value': new Date()
    };
    this.gaEvent.sendGaTagConfigData(gaData)
    this.userService.logout().subscribe(res => {
      if(res.status === httpStatusCodes.Success) {
        this.jwtService.deleteLocalStorage();
      }
    }, (err) => {
      let response = err.json();
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    });
    
  }

  reRoutePage(isAdmin) {
    if (isAdmin) {
      this.router.navigate(["/companies"])
    }
    else {
      this.router.navigate(["/dashboard"])
    }
  }

}
