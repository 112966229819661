/**
* @ngdoc Services
* @name app.services.ga-events
* @description
* The service is used for tracking the google analytics events
* */

import { Injectable } from '@angular/core';
import { GaConfig } from '../configs/ga-configs';
///declare var ga: Function;
declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class GaEventsService {

  constructor(private gaConfig: GaConfig) { }

  sendGaTagData(gaData) {
    try {
      dataLayer.push(gaData);
    } catch (err) {

    }
  }

  sendGaTagConfigData(gaData) {
    try {
      gaData['event'] = this.getObjectWithDot(gaData['event'])
      dataLayer.push(gaData);
      //console.log(dataLayer, 'dataLayer')
    } catch (err) {
      console.log(err)
    }
  }

  getObjectWithDot(ObjToFind: string) {
    var configsObj = this.gaConfig.events
    var findSt = ObjToFind;
    let value = findSt.split('.').reduce((a, b) => a[b], configsObj);
    return value
  }
}
