import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rv-calculator',
  templateUrl: './rv-calculator.component.html',
  styleUrls: ['./rv-calculator.component.scss']
})
export class RvCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
